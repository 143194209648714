import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Modal,
  Button,
  ModalBody,
  Row,
  Table,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
// import Components
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../Components/Common/DeleteModal";
import Loader from "../../../Components/Common/Loader";
import { get_cookie } from "../../../helpers/get_cookie";

export const SystemUsers = () => {
  document.title = "System Users | Get Rostered";

  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  
  const [roles, setRoles] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  // revoke modal
  const [revokeModal, setRevokeModal] = useState(false);

  const [deleteItemId, setDeleteItemId] = useState(null);
  const [authUserId, setAuthUserId] = useState();
  const [role_id, setRoleid] = useState();
  //const [business_id, setbusinessid] = useState();
  const [availableroles, setavailableroles] = useState();
  const [availableuser, setavailableuser] = useState();

  const [isLoading, setisLoading] = useState(false);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  document.title = "System Roles | Getrostered";

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res.id, "helllllooooooo");
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const loadTable = (id) => {
    console.log(id, "idddd");
    setisLoading(true);

    var datavalue = {
      user_id: id,
      business_id: business_id,
    };
    axios.post("/get-systemUser-data", datavalue).then((res) => {
      console.log(res);
      setRoles(res.data);
      setavailableuser(res.availableUsers);
      setavailableroles(res.availableRoles);
      setisLoading(false);
    });
  };

  const addTableRows = () => {
    setRowsData([...rowsData, { grouproles: "", groupRate: "" }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "category_name") {
      const updatedProductCategory = [...roles];
      updatedProductCategory[index].name = value;
      setRoles(updatedProductCategory);
    } else if (name === "grouproles") {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].grouproles = value;
      setRowsData(updatedRowsData);
    }
  };

  const handleNewChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "system_roles") {
      const updatedProductCategory = [...roles];
      updatedProductCategory[index].description = value;
      setRoles(updatedProductCategory);
    } else if (name === "groupRate") {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].groupRate = value;
      setRowsData(updatedRowsData);
    }
  };
  console.log(rowsData, "rowsdata");
  console.log(roles, "roles");

  // update role_id
  const handleRoleId = (index, event) => {
    const { name, value } = event.target;
    setRoles((prevState) => {
      const newData = [...prevState];
      newData[index][name] = value; // Assuming your select input name is "system_role_id"
      return newData;
    });
  };

  //Edit
  const handleEdit = (index) => {
    const updatedProductCategory = [...roles];
    updatedProductCategory[index].isEditing = true;
    setRoles(updatedProductCategory);
  };
  //cancel button for edit
  const handleCancel = (index) => {
    const updatedProductCategory = [...roles];
    updatedProductCategory[index].isEditing = false;
    setRoles(updatedProductCategory);
  };

  const [deleteId, setDeleteId] = useState("");
  const [inviteRoleId, setInviteRoleId] = useState("");
  const [inviteUserId, setInviteUserId] = useState("");
  const [deleteSystemUserId, setDeleteSystemUserId] = useState("");
  //Delete(Update)
  const handleDelete = (index) => {
    const item = roles[index];
    console.log(item);
    var user_id = item.user_id;
    setDeleteSystemUserId(user_id);
    setDeleteModal(true);
  };
  // Invite
  const handleInvite = async (index) => {
    const item = rowsData[index];

    var system_user_id = item.grouproles;
    var role_id = item.groupRate;

    if (system_user_id == "") {
      toast.error("Select User Name", { theme: "light" });
      return;
    }
    if (role_id == "") {
      toast.error("Select System Role.", { theme: "light" });
      return;
    }

    setInviteRoleId(role_id);
    setInviteUserId(system_user_id);
    setInviteModal(true);
  };

  const handleDeletePermission = () => {
    var deleteval = {
      user_id: user_id,
      system_user_id: deleteSystemUserId,
      business_id: business_id,
    };
    axios
      .post("/delete-systemUser-data", deleteval)
      .then((res) => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  // update system_roles for users
  const UpdateRole = (index) => {
    const data = roles[index];

    var datavalue = {
      user_id: data.user_id,
      system_role_id: data.system_role_id,
      business_user_id: authUserId,
      business_id: business_id,
    };

    axios
      .post("/update-system-user-role", datavalue)
      .then((res) => {
        if (res.error) {
          toast.error(res.message);
        } else {
          toast.success(res.message, { theme: "light" });
          const updatedRoles = [...roles];
          updatedRoles[index].isEditing = false; // Set isEditing to false to close the select input
          setRoles(updatedRoles); // Update the state with the modified roles array
          loadTable(authUserId);
        }
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  //SAVE(new row)
  const Invite = (index) => {
    setIsButtonClicked(true);
    // const data = rowsData[index];
    // console.log('Saving:', data.groupName);
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      system_user_id: inviteUserId,
      role_id: inviteRoleId,
    };
    console.log(datavalue, "datavalue123");

    axios
      .post("/invite-system-user", datavalue)
      .then((res) => {
        console.log(res.success);
        if (res.status == 500) {
          toast.error(res.message, { theme: "light" });
          setIsButtonClicked(false);
        } else {
          toast.success(res.message, { theme: "light" });
          const updatedRows = [...rowsData];
          updatedRows.splice(index, 1);
          setRowsData([...updatedRows]);
          setInviteRoleId("");
          setInviteUserId("");
          setInviteModal(false);
          loadTable(authUserId);
          setIsButtonClicked(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
        toast.error("Something went wrong", { theme: "light" });
      });
  };

  // Revoke Button
  const [inviteUserIds, setInviteUserIds] = useState("");

  const handleRevoke = (index) => {
    const item = roles[index];
    var system_user_id = item.user_id;
    setInviteUserIds(system_user_id);
    setRevokeModal(true);
  };

  // "Yes, Revoke" Button
  const Revoke = (index) => {
    setIsButtonClicked(true);
    var datavalue = {
      system_user_id: inviteUserIds,
      business_user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("/revoke-invitation", datavalue)
      .then((res) => {
        console.log(res.success);
        if (res.status == 500) {
          toast.error(res.message, { theme: "light" });
          setIsButtonClicked(false);
        } else {
          toast.success(res.message, { theme: "light" });
          setRevokeModal(false);
          setIsButtonClicked(false);
          loadTable(authUserId);
        }
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
        toast.error("Something went wrong", { theme: "light" });
      });
  };

  // Revoke button

  //cancel(new row)
  const deleteTableRows = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData.splice(index, 1);
    setRowsData(updatedRowsData);
  };
  const [permission, setPermission] = useState("");
  let rolename = ""; // Declare 'a' as a 'let' variable

  //   useEffect(() => {
  //     axios.get("/api/RolePermission")
  //       .then((res) => {
  //         console.log(res, "rolepermission12324");
  //         setPermission(res);
  //       });
  //   }, []);

  const filteredMenuItemsGeneral = !permission.includes("roles_permissions");

  return (
    <React.Fragment>
      {/* <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePermission}
        onCloseClick={() => setDeleteModal(false)}
      /> */}
      <Modal
        isOpen={deleteModal}
        toggle={() => setDeleteModal(false)}
        centered={true}
      >
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#405189,secondary:#f06548"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-4 mb-0 fs-15">
                Do you want to remove the system access to this team member?
              </p>
              <p className="text-info mx-4 mb-0 fs-12">
                (This action will not delete the team member)
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            <button
              type="button"
              className="btn w-sm btn-dark"
              data-bs-dismiss="modal"
              onClick={() => setDeleteModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-danger "
              id="delete-record"
              onClick={handleDeletePermission}
            >
              Yes, Delete It!
            </button>
          </div>
        </ModalBody>
      </Modal>

      {/* Invite Modal */}
      <Modal isOpen={inviteModal} centered>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            {/* <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#121331,secondary:#e8308c"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon> */}
            <lord-icon
              src="https://cdn.lordicon.com/aycieyht.json"
              trigger="loop"
              delay="1000"
              colors="primary:#865ce2,secondary:#e8308c,tertiary:#ee66aa,quaternary:#3a3347"
              style={{ width: "200px", height: "200px" }}
            ></lord-icon>
            <div className="mt-0 pt-0 fs-15 mx-2 mx-sm-3">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-2 mb-0 fs-15">
                Please confirm if you wish to grant this team member access to
                the portal.
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            {/* <button
              type="button"
              className="btn w-sm cancelButton"
              data-bs-dismiss="modal"
              onClick={() => setInviteModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm confirmButton"
              // id="invite-meber"
              onClick={Invite}
            >
              Yes, Invite!
            </button> */}
            <Button
              className="w-sm cancelButton fs-6"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => setInviteModal(false)}
            >
              Cancel
            </Button>
            <Button
              className={`w-lg confirmButton fs-6 text-white`}
              color="primary"
              // style={styles.confirmButton}
              onClick={Invite}
              disabled = {isButtonClicked ? true : false}
            >
              {isButtonClicked ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                </Spinner>
              ) : null}
              Yes, Invite!
            </Button>
          </div>
        </ModalBody>
      </Modal>

      {/* Revoke Modal */}
      <Modal isOpen={revokeModal} centered>
        <ModalBody className="py-3 px-5">
          <div className="mt-2 text-center">
            {/* <lord-icon
              src="https://cdn.lordicon.com/gsqxdxog.json"
              trigger="loop"
              colors="primary:#121331,secondary:#e8308c"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon> */}
            <lord-icon
              src="https://cdn.lordicon.com/aycieyht.json"
              trigger="loop"
              delay="1000"
              colors="primary:#865ce2,secondary:#e8308c,tertiary:#ee66aa,quaternary:#3a3347"
              style={{ width: "200px", height: "200px" }}
            ></lord-icon>
            <div className="mt-0 pt-0 fs-15 mx-2 mx-sm-3">
              <h4>Are you sure ?</h4>
              <p className="text-muted mx-2 mb-0 fs-15">
                Please confirm that you want to revoke the invitation sent to
                this team member.
              </p>
            </div>
          </div>
          <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
            {/* <button
              type="button"
              className="btn w-sm btn-dark"
              data-bs-dismiss="modal"
              onClick={() => setRevokeModal(false)}
            >
              Close
            </button>
            <button
              type="button"
              className="btn w-sm btn-success "
              id="invite-meber"
              onClick={Revoke}
            >
              Yes, Revoke!
            </button> */}
            <Button
              className="w-sm cancelButton fs-6"
              color="primary"
              data-bs-dismiss="modal"
              onClick={() => setRevokeModal(false)}
            >
              Cancel
            </Button>
            <Button
              className={`w-lg confirmButton fs-6 text-white`}
              color="danger"
              // style={styles.confirmButton}
              id="invite-meber"
              onClick={Revoke}
              disabled={isButtonClicked ? true : false}
            >
              {isButtonClicked ? (
                <Spinner size="sm" className="me-2">
                  {" "}
                </Spinner>
              ) : null}
              Yes, Revoke!
            </Button>
          </div>
        </ModalBody>
      </Modal>

      <ToastContainer closeButton={false} limit={1} theme="light" />

      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">System User</h5>
        </CardHeader>
        <Row>
          <Col xl={12}>
            <CardBody>
              {filteredMenuItemsGeneral && (
                <Table className="table table-bordered   align-middle mb-3">
                  <thead className="bg-light">
                    <tr>
                      <th className="listTypeHead" style={{ width: "35%" }}>
                        User Name
                      </th>
                      <th style={{ width: "40%" }}>System Role</th>
                      <th style={{ width: "145px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody className="mb-3">
                    {roles &&
                      roles.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="fw-medium"
                            style={{
                              maxWidth: "230px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.isEditing ? (
                              `${item.first_name} ${item.last_name}`
                            ) : item.is_business_user_active == 0 ? (
                              <span className="text-muted">
                                {`${item.first_name} ${item.last_name}`}
                              </span>
                            ) : (
                              `${item.first_name} ${item.last_name}`
                            )}
                          </td>
                          <td
                            className="fw-medium"
                            style={{
                              maxWidth: "800px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.isEditing ? (
                              <>
                                <select
                                  value={item.system_role_id}
                                  onChange={(event) =>
                                    handleRoleId(index, event)
                                  }
                                  name="system_role_id"
                                  className="form-control"
                                >
                                  <option value="">Select role</option>
                                  {availableroles?.map((role) => (
                                    <option key={role.id} value={role.id}>
                                      {role.name}
                                    </option>
                                  ))}
                                </select>
                              </>
                            ) : item.is_business_user_active == 0 ? (
                              <span className="text-muted">
                                {item.system_role}
                              </span>
                            ) : (
                              item.system_role
                            )}
                          </td>
                          {item.editable == 1 && (
                            <td
                              className="fw-medium"
                              style={{
                                maxWidth: "145px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              <div className="hstack gap-3 flex-wrap">
                                {item.isEditing ? (
                                  <div className="hstack gap-2">
                                    {/* <button
                                      className="btn btn-sm btn-success"
                                      onClick={() => UpdateRole(index)}
                                    >
                                      Update
                                    </button>
                                    <button
                                      className="btn btn-sm btn-dark"
                                      onClick={() => handleCancel(index)}
                                    >
                                      Cancel
                                    </button> */}

                                    <Button
                                      className="w-xs confirmButton fs-6 pt-2 pb-2"
                                      color="primary"
                                      // style={styles.confirmButton}
                                      id="invite-meber"
                                      onClick={() => UpdateRole(index)}
                                    >
                                      Update
                                    </Button>
                                    <Button
                                      className="w-xs cancelButton fs-6 pt-2 pb-2"
                                      color="primary"
                                      data-bs-dismiss="modal"
                                      onClick={() => handleCancel(index)}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                ) : item.is_business_owner === 0 &&
                                  (item.is_business_user_active === 1 ||
                                    item.is_business_user_active === 0) ? (
                                  <>
                                    <button
                                      className="btn  btn-soft-info  fs-11"
                                      onClick={() => handleEdit(index)}
                                    >
                                      Edit
                                      {/* <i className="bx bxs-pencil fs-12 pt-1"></i> */}
                                    </button>
                                    <button
                                      className="btn btn-soft-danger fs-11"
                                      onClick={() => handleRevoke(index)}
                                    >
                                      Revoke Invite
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-sm btn-soft-danger  "
                                      onClick={() => handleDelete(index)}
                                    >
                                      <i className="ri-delete-bin-5-fill fs-12 pt-1"></i>
                                    </button>
                                  </>
                                )}
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    {/* ///Adding for new rows  */}
                    {rowsData &&
                      rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            {/* Dropdown for site_name */}
                            <select
                              value={data.grouproles}
                              onChange={(event) => handleChange(index, event)}
                              name="grouproles"
                              className="form-control"
                            >
                              <option value="">Select User</option>
                              {availableuser?.map((site) => (
                                <option key={site.id} value={site.id}>
                                  {site.first_name} {site.last_name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <select
                              value={data.groupRate}
                              onChange={(event) =>
                                handleNewChange(index, event)
                              }
                              name="groupRate"
                              className="form-control"
                            >
                              <option value="">Select role</option>
                              {availableroles?.map((site) => (
                                <option key={site.id} value={site.id}>
                                  {site.name}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td>
                            <div className="hstack gap-2">
                              {/* <button
                                className="btn btn-success"
                                onClick={() => handleInvite(index)}
                              >
                                Invite
                              </button>
                              <button
                                className="btn btn-dark"
                                onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </button> */}
                              <Button
                                className="w-sm confirmButton fs-6"
                                color="primary"
                                // style={styles.confirmButton}
                                onClick={() => handleInvite(index)}
                              >
                                Invite
                              </Button>
                              <Button
                                className="w-sm cancelButton fs-6"
                                color="primary"
                                data-bs-dismiss="modal"
                                onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {/* <TableInput rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}
                  </tbody>

                  <button
                    className="btn btn-primary mt-3"
                    onClick={addTableRows}
                  >
                    +
                  </button>
                </Table>
              )}
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </CardBody>
          </Col>
        </Row>
      </Card>
    </React.Fragment>
  );
};
