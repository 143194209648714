import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Offcanvas,
  OffcanvasBody,
  Dropdown,
  DropdownToggle,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import Mug from "../../assets/images/coffee_mug.jpg";
import dummyImage from "../../assets/images/users/user-dummy-img.jpg";
import avatar10 from "../../assets/images/small/clock.png";
import SimpleBar from "simplebar-react";
import cal from "../../assets/images/logosinglepurple.png";
import { attachements } from "../../common/data/chat";

const formatTime = (time) => {
  if (!time) return "--";

  try {
    const [hours, minutes, seconds] = time.split(":");
    const date = new Date(0, 0, 0, hours, minutes, seconds);

    console.log("Formatted Date Object:", date);

    const formattedTime = date.toLocaleTimeString([], {
      hour: "numeric",
      minute: "2-digit",
    });
    return formattedTime;
  } catch (error) {
    console.error("Error formatting time:", error);
    return "--";
  }
};

const PersonalInfo = ({
  show,
  onCloseClick,
  currentuser,
  cuurentiseImg,
  email,
  mobile,
  location,
  availability,
}) => {
  const [menu1, setMenu1] = useState(false);
  const [menu2, setMenu2] = useState(false);

  return (
    <Offcanvas
      isOpen={show}
      direction="end"
      className="offcanvas-end border-0"
      toggle={onCloseClick}
    >
      <OffcanvasBody className="offcanvas-body profile-offcanvas p-0">
        <div className="team-cover">
          <img src={Mug} alt="" className="img-fluid" />
        </div>
        <div className="p-1 pb-4 pt-0">
          <div className="team-settings">
            <div className="row g-0">
              <div className="col">
                <div className="btn nav-btn">
                  <Button
                    onClick={onCloseClick}
                    color=""
                    className="btn-close btn-close-white"
                  ></Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-3 text-center">
          {cuurentiseImg ? (
            <img
              src={cuurentiseImg}
              alt=""
              className="avatar-lg img-thumbnail rounded-circle mx-auto profile-img"
            />
          ) : (
            <img
              src={dummyImage}
              alt=""
              className="avatar-lg img-thumbnail rounded-circle mx-auto profile-img"
            />
          )}
          <div className="mt-2">
            <h5 className="fs-16 mb-1">
              <Link to="#" className="link-primary username">
                {currentuser}
              </Link>
            </h5>
          </div>
        </div>
        <div className="border-top border-top-dashed p-3">
          <div className="mb-3">
            <p className="text-muted hstack gap-1  fw-medium fs-13 mb-1">
              <i className="bx bx-phone-call text-success " /> Number
            </p>
            {mobile ? (
              <span className="badge badge-soft-success  fs-12">{mobile}</span>
            ) : (
              <h6 className="text-muted">--</h6>
            )}
          </div>
          <div className="mb-3">
            <p className="text-muted hstack gap-1  fw-medium fs-13 mb-1">
              <i className=" bx bx-mail-send text-info"></i> Email
            </p>
            <span className="badge badge-soft-info fs-12">{email}</span>
          </div>
          <div>
            <p className="text-muted hstack gap-1  fw-medium fs-13 mb-1">
              <i className=" bx bx-map text-danger"></i> Location
            </p>
            {location ? (
              <span className="badge badge-soft-primary mb-0  fs-12">
                {location}
              </span>
            ) : (
              <h6 className="mb-0 text-muted">--</h6>
            )}
          </div>
        </div>

        <div className="mt-1 ms-3">
          <p className="text-muted hstack gap-1 fw-medium fs-13 mb-1">
            <i className="ri-store-2-line text-warning"></i>
            Availability
          </p>
          <div className="">
            <table className="table overflow-hidden">
              <tbody>
                {/* Map through preferred availability */}
                {availability &&
                  availability.length > 0 &&
                  availability?.map((availability, index) => (
                    <tr key={index}>
                      <td className="pe-0">
                        <img
                          src={cal}
                          alt=""
                          className="avatar-xxs d-inline-block"
                          style={{
                            height: "1.5ream",
                            width: "1.5rem",
                          }}
                        />
                      </td>
                      <td>
                        <span className="badge badge-soft-warning">
                          {availability.day_of_week}
                        </span>
                      </td>
                      {availability.end_time ? (
                        <>
                          <td>
                            {availability && availability.start_time ? (
                              formatTime(availability.start_time)
                            ) : (
                              <span className="badge badge-soft-warning">
                                --
                              </span>
                            )}
                          </td>
                          <td>
                            {availability.end_time ? (
                              formatTime(availability.end_time)
                            ) : (
                              <span className="badge badge-soft-warning">
                                --
                              </span>
                            )}
                          </td>
                        </>
                      ) : (
                        <>
                          <td className="">
                            <span
                              className={
                                +(availability.start_time === "unavailable")
                                  ? `badge badge-soft-danger`
                                  : `badge badge-soft-success`
                              }
                            >
                              {availability.start_time}
                            </span>
                          </td>
                          <td></td>
                        </>
                      )}
                    </tr>
                  ))}
                <span className="text-muted ms-3">
                  {availability?.length <= 0 ? "Availability not found." : ""}
                </span>
              </tbody>
            </table>
          </div>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default PersonalInfo;
