import React from "react";
import { Card } from "reactstrap";
import { SettingsTabPages } from "../Constants/SettingsConstants";
import { General } from "../TabPages/General";
import { Zone } from "../TabPages/Zone";
import {AssignedPeople}  from "../TabPages/AssignedPeople";
import { Holidays } from "../TabPages/Holidays";
export default function SettingsTabContent({ activeTab }) {
  const TabOutlet = () => {
    switch (activeTab) {
      case SettingsTabPages.General:
        return <General />;
      case SettingsTabPages.Zone:
        return <Zone />;
      case SettingsTabPages.AssignedPeople:
        return <AssignedPeople />;
      case SettingsTabPages.Holidays:
        return <Holidays />;
      default:
        return <General />;
    }
  };

  return (
    //Fragment just in case
    <>{TabOutlet()}</>
  );
}
