import React, { useEffect, useState } from "react";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import FeatherIcon from 'feather-icons-react';
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
} from "reactstrap";
import axios from "axios";

import Select from "react-select";
import classnames from "classnames";
import { orderSummary } from "../../common/data/ecommerce";
import { Link, useParams } from "react-router-dom";
import { api } from "../../config";

const Checkout = () => {
  const params = useParams();
  const encrypt = params.encrypt;
  const status = params.status;
  const session_param = new URLSearchParams(window.location.search);
  const sessionId = session_param.get('session_id');
  console.log(sessionId);
  const [selectedCountry, setselectedCountry] = useState(null);
  const [selectedState, setselectedState] = useState(null);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Stripe');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [pickup_address, setPickupAddress] = useState('');
  const [dropoff_address, setDropoffAddress] = useState('');
  const [payment_amount, setPaymentAmount] = useState('');
  const [company_logo, setCompanyLogo] = useState('');
  const [logo_url, setCompanyLogoURL] = useState('');
  const [jobNumber, setJobNumber] = useState('');

  useEffect(() => {
    console.log(status);
    if(status != "success"){
    axios.get("api/pay-now-inv/"+encrypt).then((res)=>{
        setName(res.lead.name);
        setJobNumber(res.job.job_number)
        setPhone(res.mobile);
        setEmail(res.email);
        setPickupAddress(res.job.pickup_address);
        setDropoffAddress(res.job.drop_off_address);
        setPaymentAmount(parseFloat(res.deposit_required)+parseFloat(res.processing_fee));
        setCompanyLogo(res.company_logo_exists);
        setCompanyLogoURL(res.company.logo);
    });
    }else{
        setactiveTab(2);
        axios.get("api/pay-now-inv/"+encrypt).then((res)=>{
        var postData = {
            "job_id": res.job.job_id,
            "sys_job_type": res.sys_job_type,
            "invoice_id": res.invoice_id,
            "booking_fee": res.booking_fee,
            "tenant_id": res.lead.tenant_id,
            "deposit_required": res.deposit_required,
            "processing_fee": res.processing_fee,
            'session_id': sessionId
        }
        axios.post("api/paymentCharge", postData).then((res)=>{
            console.log(res);
        })
        });
    }

  }, [encrypt, status])

  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectCountry(selectedCountry) {
    setselectedCountry(selectedCountry);
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

  return (
    <React.Fragment>
      <div className="auth-page-content">
      <div style={{width:"fit-content", margin:"10px auto", border:"1px solid #ddd"}}>
        {!company_logo && <img className="text-center" src={api.WEB_URL+"user-uploads/company-logo/yQ2ok6cLzGp8VTn6i5juWFT0HYjWuikA56PhwCLB.png"} />}
        </div>
        <Container fluid>
          {/* <BreadCrumb title="Checkout" pageTitle="Ecommerce" /> */}

          <Row>
            <Col xl="12">
              <Card>
                <CardBody className="checkout-tab">
                  <Form action="#">
                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                      <Nav
                        className="nav-pills nav-justified custom-nav"
                        role="tablist"
                      >
                        <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 1, done: activeTab <= 4 && activeTab > 0 }, "fs-15 p-3")}
                          >
                            <i className="ri-bank-card-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Payment Info
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink href="#"
                            className={classnames({ active: activeTab === 2, done: activeTab <= 4 && activeTab > 1 }, "fs-15 p-3")}
                          >
                            <i className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Finish
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1}>
                        <div>
                          <h5 className="mb-1">Thank you for choosing Get Roster</h5>
                          <p className="text-muted mb-4">
                            Please review the details below
                          </p>
                        </div>

                        <div className="collapse show" id="paymentmethodCollapse">
                          <Card className="p-4 border shadow-none mb-0 mt-4">
                            <Row className="gy-3">
                            <Col md={12}>
                                <Label htmlFor="job_number" className="form-label">
                                    <h4>Job Number:  <Label>{jobNumber}</Label></h4>
                                </Label>

                            </Col>
                            <Col md={4}>
                            <Label htmlFor="Name" className="form-label">
                            Name
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="Name"
                                placeholder="Enter name"
                                value={name}
                                disabled
                            />
                            </Col>
                            <Col md={4}>
                            <Label htmlFor="mobile-no" className="form-label">
                            Mobile Number
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="mobile-no"
                                value={phone}
                                disabled
                                placeholder="Enter mobile number"
                            />
                            </Col>
                            <Col md={4}>
                            <Label htmlFor="email" className="form-label">
                            Email
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="email"
                                value={email}
                                disabled
                                placeholder="Enter email"
                            />
                            </Col>
                            <Col md={6}>
                            <Label htmlFor="pickup-address" className="form-label">
                            Pickup Address: 
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="pickup-address"
                                value={pickup_address}
                                placeholder="Pickup address"
                            />
                            </Col>
                            <Col md={6}>
                            <Label htmlFor="dropoff-address" className="form-label">
                                Drop off Address: 
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="dropoff-address"
                                value={dropoff_address}
                                placeholder="Drop off address"
                            />
                            </Col>
                            </Row>
                          </Card>
                          <Card className="p-4 border shadow-none mb-0 mt-4">
                            <Row className="gy-3">
                            <Col md={6}>
                            <Label htmlFor="amount" className="form-label">
                            Payment Amount:
                            </Label>
                            <Input
                                type="number"
                                className="form-control"
                                id="amount"
                                placeholder="Enter amount"
                                value={payment_amount}
                                disabled
                            />
                            </Col>
                            <Col md={6}>
                            <Label htmlFor="confirm-email" className="form-label">
                            Email address for confirmation of this payment
                            </Label>
                            <Input
                                type="text"
                                className="form-control"
                                id="confirm-email"
                                disabled
                                placeholder="Enter email"
                                value={email}
                            />
                            </Col>
                            </Row>
                          </Card>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-primary btn-label right ms-auto nexttab"
                            onClick={() => {
                              axios.get("/api/pay-now-checkout/"+encrypt).then((res)=>{
                                if(res.error == 0){
                                    window.location.href = res.url;
                                }
                              })
                            }}
                          >
                            <i className="ri-arrow-right-circle-line label-icon align-middle fs-18 ms-2"></i>
                            Pay Now
                          </button>
                        </div>
                      </TabPane>

                      <TabPane tabId={2} id="pills-finish">
                        <div className="text-center py-5">
                          <div className="mb-4">
                            <lord-icon
                              src="https://cdn.lordicon.com/lupuorrc.json"
                              trigger="loop"
                              colors="primary:#0ab39c,secondary:#405189"
                              style={{ width: "120px", height: "120px" }}
                            ></lord-icon>
                          </div>
                          <h5>Thank you ! Your Payment is Completed !</h5>
                          <p className="text-muted">
                            You will receive a payment confirmation email with
                            details of your invoice.
                          </p>
                        </div>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
            </Col>

            {/* <Col xl={4}>
              <Card>
                <CardHeader>
                  <div className="d-flex">
                    <div className="flex-grow-1">
                      <h5 className="card-title mb-0">Order Summary</h5>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="table-responsive table-card">
                    <table className="table table-borderless align-middle mb-0">
                      <thead className="table-light text-muted">
                        <tr>
                          <th style={{ width: "90px" }} scope="col">
                            Product
                          </th>
                          <th scope="col">Product Info</th>
                          <th scope="col" className="text-end">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderSummary.map((product, key) => (
                          <React.Fragment key={key}>
                            <tr>
                              <td>
                                <div className="avatar-md bg-light rounded p-1">
                                  <img
                                    src={product.img}
                                    alt=""
                                    className="img-fluid d-block"
                                  />
                                </div>
                              </td>
                              <td>
                                <h5 className="fs-14">
                                  <Link
                                    to="/apps-ecommerce-product-details"
                                    className="text-dark"
                                  >
                                    {product.name}
                                  </Link>
                                </h5>
                                <p className="text-muted mb-0">
                                  $ {product.price} x {product.quantity}
                                </p>
                              </td>
                              <td className="text-end">$ {product.total}</td>
                            </tr>
                          </React.Fragment>
                        ))}

                        <tr>
                          <td className="fw-semibold" colSpan="2">
                            Sub Total :
                          </td>
                          <td className="fw-semibold text-end">$ 359.96</td>
                        </tr>
                        <tr>
                          <td colSpan="2">
                            Discount{" "}
                            <span className="text-muted">(VELZON15)</span>:{" "}
                          </td>
                          <td className="text-end">- $ 50.00</td>
                        </tr>
                        <tr>
                          <td colSpan="2">Shipping Charge :</td>
                          <td className="text-end">$ 24.99</td>
                        </tr>
                        <tr>
                          <td colSpan="2">Estimated Tax (12%): </td>
                          <td className="text-end">$ 18.20</td>
                        </tr>
                        <tr className="table-active">
                          <th colSpan="2">Total (USD) :</th>
                          <td className="text-end">
                            <span className="fw-semibold">$353.15</span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </Col> */}
          </Row>
        </Container>
        <div className="text-center mb-4">&#169; 2023 Get Rostered. All rights reserved</div>
      </div>
    </React.Fragment>
  );
};

export default Checkout;
