import { Card, CardBody, Col, Row, Table } from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
  Fragment,
} from "react";
import img13 from "../../../assets/images/small/img-13.png";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import Select from "react-select";

import "../../../assets/scss/pages/_settings.scss";

import { CardHeader, Label, Input } from "reactstrap";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
//toast
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// axios
import axios from "axios";
import Loader from "../../../Components/Common/Loader";
import { get_cookie } from "../../../helpers/get_cookie";
import { Link } from "react-router-dom";

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
export const Permissions = () => {
  document.title = "Permissions | Get Rostered";

  const User_id = JSON.parse(get_cookie("authUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  console.log(User_id);

  const [isLoading, setisLoading] = useState(true);
  const [groupedPermissions, setGroupedPermissions] = useState();
  const [allRoles, setAllRoles] = useState();
  const [givenPermissions, setGivenPermissions] = useState([]);
  const [checked, setChecked] = useState(false);

  const getData = () => {
    const formData = {
      user_id: User_id,
      business_id: business_id,
    };
    axios
      .post("/get-permissions-data", formData)
      .then((res) => {
        console.log(res[0]);
        setGroupedPermissions(res[0].groupedPermissions);
        setAllRoles(res[0].roles);
        setGivenPermissions(res[0].givenPermissions);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  // const isCheckboxChecked = givenPermissions.some(
  //   (gp) =>
  //     gp.role_id === 1 &&
  //     gp.permission_id === 30
  // );

  // console.log('Is Checkbox Checked:', isCheckboxChecked);

  const handleCheckboxChange = (e, role_id, permission_id) => {
    const isChecked = e.target.checked;

    console.log(isChecked);
    // Check if the permission already exists for the given role, permission, and business
    const existingPermissionIndex = givenPermissions.findIndex(
      (gp) => gp.role_id === role_id && gp.permission_id === permission_id
    );

    if (isChecked && existingPermissionIndex === -1) {
      // Checkbox is checked, insert the record into the state
      setGivenPermissions([...givenPermissions, { role_id, permission_id }]);
    } else if (!isChecked && existingPermissionIndex !== -1) {
      // Checkbox is unchecked, remove the record from the state
      const updatedPermissions = [...givenPermissions];
      updatedPermissions.splice(existingPermissionIndex, 1);
      setGivenPermissions(updatedPermissions);
    }

    // Implement the logic to send role_id and permission_id to the backend
    console.log("Role ID:", role_id);
    console.log("Permission ID:", permission_id);
    // console.log('Is Checkbox Checked:', isCheckboxChecked);

    const formData = {
      user_id: User_id,
      business_id: business_id,
      role_id: role_id,
      permission_id: permission_id,
      checked: isChecked,
    };

    console.log(formData);

    // Update Permission:
    axios
      .post("/update-role-permissions", formData)
      .then((response) => {
        console.log(response);
        getData();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  // console.log(allRoles, "allRoles");
  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Permissions</h5>
        </CardHeader>
        <CardBody>
          {isLoading ? (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          ) : (
            <div className="table-responsive">
              <Table className="table-bordered align-middle mb-0">
                <thead className="table-light">
                  <tr>
                    <th scope="col" style={{ minWidth: "15rem" }}>
                      Permissions
                    </th>
                    {allRoles &&
                      allRoles?.map((role, index) => {
                        return (
                          <>
                            <th
                              key={role.role_id}
                              scope="col"
                              className="text-center"
                              style={{ minWidth: "10rem" }}
                            >
                              {role.role_name}
                            </th>
                          </>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  {groupedPermissions &&
                    groupedPermissions.map((item, index) => {
                      return (
                        <Fragment key={index}>
                          <tr key={item.module_id} className=" table-dark">
                            <td className="fw-semibold text-white">
                              {item.module_name}
                            </td>
                            {allRoles &&
                              allRoles.map((role) => (
                                <td key={role.role_id}></td>
                              ))}
                          </tr>
                          {item?.permissions?.map((permission) => (
                            <tr key={permission.permission_id}>
                              <td className="fw-medium">
                                {permission.permission_name}
                              </td>
                              {allRoles &&
                                allRoles.map((role) => (
                                  <td key={role.role_id} className="">
                                    <div className="form-check form-check-inline w-100 d-flex justify-content-center align-center">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={givenPermissions.some((gp) => {
                                          return (
                                            gp.role_id === role.role_id &&
                                            gp.permission_id ===
                                              permission.permission_id
                                          );
                                        })}
                                        id={`checkbox_${role.role_name}`}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            e,
                                            role.role_id,
                                            permission.permission_id
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                ))}
                            </tr>
                          ))}
                        </Fragment>
                      );
                    })}
                </tbody>
              </Table>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
