import React, { useEffect } from 'react';
import "react-image-crop/dist/ReactCrop.css";
import './assets/scss/themes.scss';
import './assets/scss/custom.scss';
import { initializeInterceptors } from './helpers/api_helper';
import { ModalProvider, useModal } from './ModalContext';
import Route from './Routes';
import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useLocation } from 'react-router-dom';

fakeBackend();

const AppInitializer = () => {
  const { showModal, disableRestrictionCheck, enableRestrictionCheck } = useModal();
  const location = useLocation();

  // const pathsToDisableRestriction = ['/searchworkforce', '/job-applications','/job-postings', '/talent-pool','/job-Vacancies','/activity','/chat','/business_settings','/email-inbox','/business-profile','/login','/logout','/roster_louge','/work-order','/manage-team','/team-availability','/approve_timesheets','/export_timesheets'];


  const pathsToDisableRestriction = ['/login','/logout','/billing'];

  useEffect(() => {
    initializeInterceptors(showModal);

    if (pathsToDisableRestriction.includes(location.pathname)) {
      disableRestrictionCheck();
    } else {
      enableRestrictionCheck();
    }
  }, [showModal, disableRestrictionCheck, enableRestrictionCheck, location.pathname]);

  return null;
};

function App() {
  return (
    <ModalProvider>
      <AppInitializer />
      <Route />
    </ModalProvider>
  );
}

export default App;
