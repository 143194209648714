import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import FeatherIcon from "feather-icons-react";
import { ClipLoader } from "react-spinners";
import logoPurple from "../../assets/images/logopurple.png";
import {
  Container,
  Form,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

import Select from "react-select";
import { jsx } from "@emotion/react";
import classnames from "classnames";
import { orderSummary } from "../../common/data/ecommerce";
import {
  Link,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import GoogleAutocomplete from "react-google-autocomplete";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { RegistrationState } from "@pusher/push-notifications-web";
import { get_cookie } from "../../helpers/get_cookie";
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;
const divider = jsx`
  border-left: 1px solid
`;
const AddSite = ({}) => {

  const [searchParams] = useSearchParams();
  const encodedParam = searchParams.get("param");
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [isLoading, setisLoading] = useState(false);
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [modal, setModal] = useState(false);
  const [deletemodal, setDeleteModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  // Assign People
  const [nameOne, setnameOne] = useState("");
  const [nameTwo, setnameTwo] = useState("");
  const [nameThree, setnameThree] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  // Zones
  const [fieldSetsZone, setFieldSetsZone] = useState(1); // Initialize fieldSetsZone
  const [zones, setZones] = useState(
    [...Array(fieldSetsZone)].map(() => ({
      zone_info: {
        zones: [
          { zone_name: "", zone_description: "" },
          { zone_name: "", zone_description: "" },
          { zone_name: "", zone_description: "" },
        ],
      },
    }))
  );
  //site
  const [site_name, setSiteName] = useState("");
  const [address, setAddress] = useState("");
  const [week_starts, setWeekStarts] = useState({
    label: "Monday",
    value: "Mon",
  });
  const [time_zone, setTimeZone] = useState({
    label: "Australia/Melbourne",
    value: "Australia/Melbourne",
  });
  const [time_option, setTimeOption] = useState("");
  const [talent_pool, setTalentPool] = useState("");
  const [addressError, setAddressError] = useState("");
  const [siteNameError, setSiteNameError] = useState("");
  // Assign People
  const toggledeletemodal = () => {
    setDeleteModal(!deletemodal);
  };
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [selectedStateCode, setselectedStateCode] = useState();
  const [country, setCountry] = useState([]);
  const [authUserId, setAuthUserId] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [registeredSiteData, setRegisteredSiteData] = useState("");
  const [shouldClearFields, setShouldClearFields] = useState(false);
  const [businessAddress, setBusinessAddress] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [fullName, setFullName] = useState("");
  const [workforceId, setWorkforceId] = useState("");

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadSite(res.id);
        setBusinessName(res.name);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);
const [people, setPeople] = useState([
    {
      name_one: { value: workforceId, label: fullName },
      name_two: "",
      name_three: "",
    },
  ]);
useEffect(() => {
    if (workforceId && fullName) {
      setPeople((prevPeople) => [
        {
          ...prevPeople[0],
          name_one: { value: workforceId, label: fullName },
        },
      ]);
    }
  }, [workforceId, fullName]);

  // Function to clear fields
  const clearFields = () => {
    setSiteName("");
    setAddress("");
    setLongitude("");
    setLatitude("");
    setselectedPostcode("");
    setSelectedcity("");
    setselectedStateCode("");
    setselectedSuburb("");
    setCountry("");
    // setShouldClearFields(true);
  };

  useEffect(() => {
    if (encodedParam) {
      try {
        const decodedParam = atob(encodedParam); // Decode from Base64
        console.log(decodedParam, "decodedParam");
        if (decodedParam === "register") {
          // Execute your logic here if the decoded param is 'register'
          setSiteName(registeredSiteData.site_name);
          setAddress(
            (registeredSiteData.address
              ? registeredSiteData.address + ", "
              : "") +
              (registeredSiteData.suburb
                ? registeredSiteData.suburb + " "
                : "") +
              (registeredSiteData.postcode
                ? registeredSiteData.postcode + ", "
                : "") +
              (registeredSiteData.country_name
                ? registeredSiteData.country_name
                : "")
          );

          setCountry(registeredSiteData.country_name);
          setLongitude(registeredSiteData.longitude);
          setLatitude(registeredSiteData.latitude);
          setselectedPostcode(registeredSiteData.postcode);
          setSelectedcity(registeredSiteData.address);
          setselectedStateCode(registeredSiteData.state_code);
          setselectedSuburb(registeredSiteData.suburb);
        }
      } catch (error) {
        console.error("Error decoding Base64 parameter:", error);
      }
    }
  }, [encodedParam, registeredSiteData]);

  useEffect(() => {
    setCountry(registeredSiteData?.country_name);
    setLongitude(registeredSiteData?.longitude);
    setLatitude(registeredSiteData?.latitude);
    setselectedPostcode(registeredSiteData?.postcode);
    setSelectedcity(registeredSiteData?.address);
    setselectedStateCode(registeredSiteData?.state_code);
    setselectedSuburb(registeredSiteData?.suburb);
  }, [registeredSiteData]);

  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    const { lat, lng } = place.geometry.location;
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    // state_id updated
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedStateCode(selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };
  const loadSite = (userId) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    setisLoading(true);
    axios
      .post("/get-dropdown-and-timezones", formData)
      .then((response) => {
        setRegisteredSiteData(response.siteData);
        setTalentPool(response.dropdownOptions);
        setBusinessAddress(response.busines_address);
        setWorkforceId(response.workforce_profile.id);
        setFullName(response.full_name);
        if (Array.isArray(response.timezones)) {
          setTimeOption(response.timezones);
          console.log(response.timezones);
        }
        // if (response.dropdownOptions && response.dropdownOptions.length > 0) {
        //   setPeople((prevPeople) => [
        //     {
        //       ...prevPeople[0],
        //       name_one: { value: response.dropdownOptions[0].id, label: response.dropdownOptions[0].full_name },
        //       name_two: { value: response.dropdownOptions[1].id, label: response.dropdownOptions[1].full_name },
        //       name_three: { value: response.dropdownOptions[2].id, label: response.dropdownOptions[2].full_name },
        //     },
        //   ]);
        // }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  //*backend response*

  const togglemodal = () => {
    setModal(!modal);
  };

  function handleSelectDay(selectedDay) {
    setWeekStarts(selectedDay);
  }
  const timeZoneOptions = Array.isArray(time_option)
    ? time_option.map((timeZone) => ({ value: timeZone, label: timeZone }))
    : [];
  const handleSelectTimeZone = (selectedOption) => {
    if (selectedOption) {
      setTimeZone({ value: selectedOption.value, label: selectedOption.label });
    } else {
      // Handle the case when no option is selected, e.g., setTimeZone to some default value or clear it.
      setTimeZone(null); // Assuming time_zone is initially set to null
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];

      if (tab >= 1 && tab <= 4) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }

 const handleNameChangeOne = (selectedOption, index) => {
    updatePeople(index, "name_one", selectedOption);
  };

  const handleNameChangeTwo = (selectedOption, index) => {
    updatePeople(index, "name_two", selectedOption);
  };

  const handleNameChangeThree = (selectedOption, index) => {
    updatePeople(index, "name_three", selectedOption);
  };

  const [fieldSets, setFieldSets] = useState(1);

  //Assign People
  const addMoreFields = () => {
    setPeople((prevPeople) => [
      ...prevPeople,
      {
        name_one: "",
        name_two: "",
        name_three: "",
      },
    ]);
    setFieldSets(fieldSets + 1);
  };
  const updatePeople = (index, field, selectedOption) => {
    setPeople((prevPeople) => {
      const updatedPeople = [...prevPeople];
      updatedPeople[index][field] = selectedOption;
      return updatedPeople;
    });
  };
  const removeFields = (index) => {
    setPeople((prevPeople) => {
      const updatedPeople = [...prevPeople];
      updatedPeople.splice(index, 1);
      return updatedPeople;
    });
    setFieldSets(fieldSets - 1);
  };

  const getFilteredOptions = (selectedOptions) => {
    return talent_pool
      ? talent_pool
          .filter((item) => !selectedOptions.includes(item.full_name))
          .map((item) => ({ value: item.id, label: item.full_name }))
      : [];
  };

  const getSelectedOptions = () => {
    return people.reduce((acc, person) => {
      if (person.name_one) acc.push(person.name_one.label);
      if (person.name_two) acc.push(person.name_two.label);
      if (person.name_three) acc.push(person.name_three.label);
      return acc;
    }, []);
  };

  const selectedOptions = getSelectedOptions();
  //zones
  const updateZone = (index, subIndex, field, value) => {
    setZones((prevZones) => {
      const updatedZones = [...prevZones];
      updatedZones[index].zone_info.zones[subIndex][field] = value;
      return updatedZones;
    });
  };

  const addMoreZone = () => {
    // Check if all existing fields are filled
    const areExistingFieldsFilled = zones.every((zoneSet) =>
      zoneSet.zone_info.zones.every(
        (zone) =>
          zone.zone_name.trim() !== "" && zone.zone_description.trim() !== ""
      )
    );
    if (!areExistingFieldsFilled) {
      toast.error("Please fill all existing fields before adding more", {
        autoClose: 2000,
      });
      return;
    }
    // Proceed with adding more fields
    setZones((prevZones) => [
      ...prevZones,
      {
        zone_info: {
          zones: [
            { zone_name: "", zone_description: "" },
            { zone_name: "", zone_description: "" },
            { zone_name: "", zone_description: "" },
          ],
        },
      },
    ]);
    setFieldSetsZone((prev) => prev + 1); // Update fieldSetsZone
  };

  const removeZone = (index) => {
    setZones((prevZones) => {
      const updatedZones = [...prevZones];
      updatedZones.splice(index, 1);
      return updatedZones;
    });
    setFieldSetsZone((prev) => prev - 1); // Update fieldSetsZone
  };

  const productCountry = [
    {
      options: [
        { label: "Select Day...", value: "Select Day" },
        { label: "Monday", value: "Mon" },
        { label: "Tuesday", value: "Tue" },
        { label: "Wednesday", value: "Wed" },
        { label: "Thursday", value: "Thu" },
        { label: "Friday", value: "Fri" },
        { label: "Saturday", value: "Sat" },
        { label: "Sunday", value: "Sun" },
      ],
    },
  ];

  // save site
  const handleSubmit = (event) => {
    //event.preventDefault();
    // setisLoading(true);
    /* validation */
    /*if (site_name === "") {
      setSiteNameError("Please enter site name.");
      setSiteName("");
    } else {
      setSiteNameError(""); // Clear the error message if the input is not empty.
    }*/

    // if (address === "") {

    // } else {
    //   setAddressError(""); // Clear the error message if the input is not empty.
    // }
    // Check if all zones are empty
    const allZonesEmpty = zones.every((zoneSet) =>
      zoneSet.zone_info.zones.every(
        (zone) => zone.zone_name === "" && zone.zone_description === ""
      )
    );

    // If all zones are empty, set default value for the first zone
    if (allZonesEmpty) {
      const updatedZones = [...zones];
      updatedZones[0].zone_info.zones[0] = {
        zone_name: "zone 1",
        zone_description: "Zone Description",
      };
      setZones(updatedZones);
    }

    const areAllFieldsEmpty = people.every(
      (person) => !person.name_one && !person.name_two && !person.name_three
    );
    let def_people;
    if (areAllFieldsEmpty) {
      def_people = [
        {
          name_one: { value: authUserId, label: businessName },
          name_two: "",
          name_three: "",
        },
      ];
      setPeople([
        {
          name_one: { value: authUserId, label: businessName },
          name_two: "",
          name_three: "",
        },
      ]);
      console.log("allPeopleEmpty if part", people, def_people);
    }
    // var formData = {
    //   if(registeredSiteData){
    //   site_id:  registeredSiteData.id
    //   },
    //   user_id: authUserId,
    //   site_name: site_name,
    //   site_address: selectedCity,
    //   suburb: selectedSuburb,
    //   state_code: selectedStateCode,
    //   post_code: selectedPostcode,
    //   country_name: country,
    //   latitude: latitude,
    //   longitude: longitude,
    //   week_starts: week_starts.value,
    //   time_zone: time_zone.value,
    //   zones: zones,
    //   people: people,
    // };
    const decodedParam = atob(encodedParam); // Decode from Base64

    const formData = {
      ...(decodedParam === "register" && { site_id: registeredSiteData.id }),
      user_id: authUserId,
      business_id: business_id,
      site_name: site_name,
      site_address: selectedCity,
      suburb: selectedSuburb,
      state_code: selectedStateCode,
      post_code: selectedPostcode,
      country_name: country,
      latitude: latitude,
      longitude: longitude,
      week_starts: week_starts.value,
      time_zone: time_zone.value,
      zones: zones,
      people: areAllFieldsEmpty ? def_people : people,
    };

    //  console.log(formData,"formData1234");
    //   axios.post('/add-site', formData)
    //     .then(response => {
    //       toast.success(response.success, { theme: "light" }, { autoClose: 2000 });
    //       window.location.href = '/sites';
    //     })
    //     .catch(error => {
    //       console.error(error);
    //       toast.error('Something went wrong...', { autoClose: 2000 });
    //     })
    //     .finally(() => {
    //       setisLoading(false); //reloading the page
    //     });

    console.log(formData, "formData1234");
    axios
      .post("/add-site", formData)
      .then((response) => {
        if(response.error === 0){
        clearFields();
        toast.success(
          response.success,
          { theme: "light" },
          { autoClose: 2000 }
        );
        if (response.sites_count == 0) {
          navigate("/job-Vacancies");
        } else {
          navigate("/business_settings/sites");
        }
      }else{
        toast.error(response.success,{ autoClose: 2000 });
      }

        // window.location.href = response.redirect_url;
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...", { autoClose: 2000 });
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const handlePreviousPage = () => {
    navigate("/business_settings/sites");
  };
  document.title = "New Site | Get Rostered";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="New Site" pageTitle="New Site" />

          <Row>
            <Col xl="12">
              <Card className="mt-0">
                <CardBody className="checkout-tab">
                  <Form action="#">
                    <div className="step-arrow-nav mt-n3 mx-n3 mb-3">
                      <Nav
                        className="nav-pills nav-justified custom-nav"
                        role="tablist"
                      >
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 1,
                                done: activeTab <= 4 && activeTab >= 0,
                              },
                              "fs-15 p-3"
                            )}
                            onClick={() => {
                              toggleTab(1);
                            }}
                          >
                            <i className="ri-user-2-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Add New Site
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 2,
                                done: activeTab <= 4 && activeTab > 1,
                              },
                              "fs-15 p-3"
                            )}
                            onClick={() => {
                              toggleTab(2);
                            }}
                          >
                            <i className="ri-truck-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Add Zones
                          </NavLink>
                        </NavItem>
                        <NavItem role="presentation">
                          <NavLink
                            href="#"
                            className={classnames(
                              {
                                active: activeTab === 3,
                                done: activeTab <= 4 && activeTab > 2,
                              },
                              "fs-15 p-3"
                            )}
                            onClick={() => {
                              toggleTab(3);
                            }}
                          >
                            <i className="ri-checkbox-circle-line fs-16 p-2 bg-soft-primary text-primary rounded-circle align-middle me-2"></i>
                            Assign People
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={1} id="pills-bill-info">
                        <div>
                          <p className="text-muted mb-4">
                            A Site is a physical location, event, or temporary
                            job site where your workforce is stationed.
                          </p>
                        </div>

                        <div>
                          <Row>
                            <Col md={6}>
                              <Row>
                                <Col md={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="billinginfo-firstName"
                                      className="form-label"
                                    >
                                      Site Name
                                    </Label>
                                    <Input
                                      type="text"
                                      value={site_name}
                                      onChange={(e) => {
                                        setSiteName(e.target.value);
                                        setSiteNameError("");
                                      }}
                                      className={`form-control ${
                                        siteNameError ? "is-invalid" : ""
                                      }`}
                                      id="billinginfo-firstName"
                                      placeholder="Enter site name"
                                      style={{ width: "80%" }} // Adjust width here
                                    />
                                    {siteNameError && (
                                      <div className="invalid-feedback">
                                        {siteNameError}
                                      </div>
                                    )}
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col md={12}>
                                  <FormGroup className="mb-3">
                                    <Label
                                      htmlFor="billinginfo-lastName"
                                      className="form-label"
                                    >
                                      Site Address
                                    </Label>
                                    <GoogleAutocomplete
                                      className={`form-control ${
                                        addressError ? "is-invalid" : ""
                                      }`}
                                      apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                                      value={address}
                                      onChange={(e) => {
                                        setAddress(e.target.value);
                                        setAddressError("");
                                      }}
                                      onPlaceSelected={(place) => {
                                        console.log(place, "place console");
                                        residentialplace(place);
                                      }}
                                      options={{
                                        types: ["geocode"],
                                        componentRestrictions: {
                                          country: "au",
                                        },
                                      }}
                                      style={{
                                        width: "80%",
                                        border: "1px solid #ccc",
                                        padding: "10px",
                                        borderRadius: "5px",
                                      }}
                                    />
                                    {addressError && (
                                      <div className="invalid-feedback">
                                        {addressError}
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <div className="mb-3">
                                    <Label htmlFor="day" className="form-label">
                                      At this site, the week starts on
                                    </Label>
                                    <div style={{ width: "80%" }}>
                                      <Select
                                        value={week_starts}
                                        onChange={(selectedOption) => {
                                          handleSelectDay(selectedOption); // Pass the selected value to the function
                                        }}
                                        options={productCountry} // Extract the options array from the nested structure
                                        id="day"
                                      ></Select>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={12}>
                                  <div className="mb-3">
                                    <Label
                                      htmlFor="state"
                                      className="form-label"
                                    >
                                      TimeZone
                                    </Label>
                                    <div style={{ width: "80%" }}>
                                      <Select
                                        id="state"
                                        value={time_zone}
                                        onChange={(selectedOption) => {
                                          handleSelectTimeZone(selectedOption);
                                        }}
                                        options={timeZoneOptions}
                                      ></Select>
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                            </Col>

                            <Col md={6}>
                              <div>
                                <Card
                                  className="mb-3"
                                  style={{
                                    backgroundColor: "#f0f0f0",
                                    padding: "2px",
                                    marginTop: "2px",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <CardBody>
                                    <p
                                      style={{
                                        color: "#865ce2",
                                        marginBottom: "2px",
                                      }}
                                    >
                                      <strong>Business</strong> : In Get
                                      Rostered, "Business" represents your
                                      company's name and stands at the pinnacle
                                      of the hierarchy setup. For instance,
                                      "Global Gourmet Business Group" could be
                                      the umbrella name for your enterprise
                                      which operates a series of coffee shops
                                      and fast-food outlets across various
                                      locations.
                                    </p>
                                  </CardBody>
                                </Card>

                                <Card
                                  className="mb-3"
                                  style={{
                                    backgroundColor: "#f0f0f0",
                                    padding: "2px",
                                    marginTop: "2px",
                                    marginBottom: "2px",
                                  }}
                                >
                                  <CardBody>
                                    <p
                                      style={{
                                        color: "#865ce2",
                                        marginBottom: "2px",
                                      }}
                                    >
                                      <strong> Sites</strong> : Below the
                                      business level is "sites" within the Get
                                      Rostered platform. A site refers to a
                                      specific physical location, event, or
                                      temporary job site where your workforce is
                                      stationed. This could range from a
                                      permanent storefront to a one-time event
                                      location. Workforce members can be
                                      associated with multiple sites, allowing
                                      for flexible job assignments and
                                      scheduling.
                                    </p>
                                  </CardBody>
                                </Card>
                              </div>
                            </Col>
                            <div className="d-flex align-items-center justify-content-end right gap-3 mt-3">
                              <button
                                type="button"
                                className="btn btn-success"
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                Skip
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary btn-label right  nexttab"
                                onClick={() => {
                                  toggleTab(activeTab + 1);
                                }}
                              >
                                <i className="ri-bank-card-line label-icon align-middle fs-16 ms-2"></i>
                                Next
                              </button>
                            </div>
                          </Row>
                        </div>
                      </TabPane>
                      <TabPane tabId={2}>
                        <div>
                          {/*<h5 className="mb-1">Shipping Information</h5>*/}
                          <p className="text-muted mb-4">
                            Each Site is subdivided into one or more Zones,
                            which are distinct areas designated for particular
                            roles or activities.
                          </p>
                        </div>
                        <Row>
                          <Col md={6}>
                            <div>
                              <div className="d-flex align-items-center mb-4">
                                <div className="flex-grow-1">
                                  <h5 className="fs-14 mb-0">Zones Of Work</h5>
                                </div>
                              </div>
                              <Row className="gy-3">
                                <Col sm={3}>
                                  <Label
                                    htmlFor="billinginfo-firstName"
                                    className="form-label"
                                  >
                                    Zone Name
                                  </Label>
                                </Col>
                                <Col sm={6}>
                                  <Label
                                    htmlFor="billinginfo-firstName"
                                    className="form-label"
                                  >
                                    Zone Description
                                  </Label>
                                </Col>
                              </Row>
                              {[...Array(fieldSetsZone)].map((_, index) => (
                                <div key={index} className="mt-12">
                                  <Row className="gy-3 mt-12">
                                    {zones[index].zone_info.zones.map(
                                      (zone, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                          <Col sm={3}>
                                            <div className="mb-3">
                                              <Input
                                                value={zone.zone_name}
                                                onChange={(e) =>
                                                  updateZone(
                                                    index,
                                                    subIndex,
                                                    "zone_name",
                                                    e.target.value
                                                  )
                                                }
                                                type="text"
                                                className="form-control"
                                                id={`billinginfo-zone-name-${index}-${subIndex}`}
                                                placeholder={`Zone Name ${
                                                  subIndex + 1
                                                }`}
                                              />
                                            </div>
                                          </Col>
                                          <Col sm={8}>
                                            <div className="mb-3">
                                              <Input
                                                value={zone.zone_description}
                                                onChange={(e) =>
                                                  updateZone(
                                                    index,
                                                    subIndex,
                                                    "zone_description",
                                                    e.target.value
                                                  )
                                                }
                                                type="text"
                                                className="form-control"
                                                id={`billinginfo-zone-description-${index}-${subIndex}`}
                                                placeholder={`Zone Description ${
                                                  subIndex + 1
                                                }`}
                                              />
                                            </div>
                                          </Col>
                                        </React.Fragment>
                                      )
                                    )}
                                    <Col sm={1}>
                                      <div>
                                        <i
                                          className="ri-close-line text-primary"
                                          onClick={() => removeZone(index)}
                                        ></i>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              ))}
                              <div className="flex-shrink-0">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-info mb-3"
                                  onClick={addMoreZone}
                                >
                                  Add More
                                </button>
                              </div>
                            </div>
                          </Col>
                          <Col md={6}>
                            <div>
                              <Card
                                className="mb-3"
                                style={{
                                  backgroundColor: "#f0f0f0",
                                  padding: "3px",
                                }}
                              >
                                <CardBody>
                                  <p style={{ color: "#865ce2" }}>
                                    <strong>Zones :</strong> The final tier of
                                    the hierarchy is made up of "Zones." Each
                                    Site is subdivided into one or more Zones,
                                    distinct areas designated for particular
                                    roles or activities. These Zones help
                                    organise the workspace according to the
                                    specific functions. For example:
                                  </p>

                                  <table
                                    style={{
                                      border: "1px solid #865ce2",
                                      borderCollapse: "collapse",
                                      width: "100%",
                                    }}
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Heading Sites
                                        </th>
                                        <th
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Heading Zones
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Coffee shop
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Barista, kitchen hand, waitress,
                                          manager.
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Retail shop
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Checkout, Stock Room, Sales Floor
                                        </td>
                                      </tr>
                                      <tr>
                                        <td
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Medical center
                                        </td>
                                        <td
                                          style={{
                                            border: "1px solid #865ce2",
                                            padding: "10px",
                                            color: "#865ce2",
                                          }}
                                        >
                                          Doctors, Waiting, Nurses
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>

                                  <p
                                    style={{
                                      color: "#865ce2",
                                      marginTop: "10px",
                                    }}
                                  >
                                    By structuring your workforce in this
                                    manner, Get Rostered enables clear
                                    delineation of tasks and responsibilities,
                                    ensuring that each member of your team knows
                                    their specific work area and duties. This
                                    organisation facilitates efficient
                                    operations and enhances the management of
                                    various roles within your Business.
                                  </p>
                                </CardBody>
                              </Card>
                            </div>
                          </Col>
                        </Row>
                        <div className="d-flex align-items-start justify-content-between gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Back
                          </button>
                          <div className="hstack gap-2">
                            <button
                              type="button"
                              className="btn btn-success "
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              Skip
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary btn-label nexttab"
                              onClick={() => {
                                toggleTab(activeTab + 1);
                              }}
                            >
                              <i className="ri-bank-card-line label-icon align-middle fs-16 "></i>
                              Next
                            </button>
                          </div>
                        </div>
                      </TabPane>

                      <TabPane tabId={3} id="pills-finish">
                        <div>
                          {/*<h5 className="mb-1">Payment Selection</h5>*/}
                          <p className="text-muted mb-4">
                            Team members from your Team can be assigned to the
                            sites
                          </p>
                        </div>

                        {/*<Row className="g-4">
                          
                        </Row>*/}
                        <Row className="gy-3">
                          <Col sm={6}>
                            <Label
                              htmlFor="billinginfo-firstName"
                              className="form-label"
                            >
                              Name
                            </Label>
                          </Col>
                        </Row>
                        {/* {[...Array(fieldSets)].map((_, index) => (
                          <div key={index}>
                            <Row className="gy-3 mb-4">
                              <Col sm={6}>
                                <div>
                                  <Select
                                    value={people[index].name_one}
                                    onChange={(selectedOption) => handleNameChangeOne(selectedOption, index)}
                                    options={talent_pool ? talent_pool.map(item => ({ value: item.id, label: item.full_name })) : []}
                                    isSearchable={true}
                                  />
                                </div>
                              </Col>
                              <Col sm={1}>
                                <div>
                                  <i className="ri-close-line text-primary" onClick={removeFields}></i>
                                </div>
                              </Col>
                            </Row>
                            <Row className="gy-3 mb-4">
                              <Col sm={6}>
                                <div>
                                  <Select
                                    value={people[index].name_two}
                                    onChange={(selectedOption) => handleNameChangeTwo(selectedOption, index)}
                                    options={talent_pool ? talent_pool.map(item => ({ value: item.id, label: item.full_name })) : []}
                                    isSearchable={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="gy-3 mb-4">
                              <Col sm={6}>
                                <div>
                                  <Select
                                    value={people[index].name_three}
                                    onChange={(selectedOption) => handleNameChangeThree(selectedOption, index)}
                                    options={talent_pool ? talent_pool.map(item => ({ value: item.id, label: item.full_name })) : []}
                                    isSearchable={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))} */}
                        {[...Array(fieldSets)].map((_, index) => (
                          <div key={index}>
                            <Row className="gy-3 mb-4">
                              <Col sm={6}>
                                <div>
                                  <Select
                                    value={people[index].name_one}
                                    onChange={(selectedOption) =>
                                      handleNameChangeOne(selectedOption, index)
                                    }
                                    options={getFilteredOptions(
                                      selectedOptions
                                    )}
                                    isSearchable={true}
                                  />
                                </div>
                              </Col>
                              <Col sm={1}>
                                <div>
                                  <i
                                    className="ri-close-line text-primary"
                                    onClick={() => removeFields(index)}
                                  ></i>
                                </div>
                              </Col>
                            </Row>
                            <Row className="gy-3 mb-4">
                              <Col sm={6}>
                                <div>
                                  <Select
                                    value={people[index].name_two}
                                    onChange={(selectedOption) =>
                                      handleNameChangeTwo(selectedOption, index)
                                    }
                                    options={getFilteredOptions(
                                      selectedOptions
                                    )}
                                    isSearchable={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="gy-3 mb-4">
                              <Col sm={6}>
                                <div>
                                  <Select
                                    value={people[index].name_three}
                                    onChange={(selectedOption) =>
                                      handleNameChangeThree(
                                        selectedOption,
                                        index
                                      )
                                    }
                                    options={getFilteredOptions(
                                      selectedOptions
                                    )}
                                    isSearchable={true}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        ))}
                        <div className="flex-shrink-0">
                          <button
                            type="button"
                            className="btn btn-sm btn-info mb-3"
                            onClick={addMoreFields}
                          >
                            Add More
                          </button>
                        </div>

                        <div className="d-flex align-items-start gap-3 mt-4">
                          <button
                            type="button"
                            className="btn btn-light btn-label previestab"
                            onClick={() => {
                              toggleTab(activeTab - 1);
                            }}
                          >
                            <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>
                            Back
                          </button>
                          {/* reloading the page */}
                          {isUpdating && (
                            <div className="spinner-overlay">
                              <ClipLoader
                                css={override}
                                size={35}
                                color={"#123abc"}
                                loading={true}
                              />
                            </div>
                          )}
                          <button
                            type="button"
                            className="btn btn-primary btn-label right ms-auto nexttab"
                            onClick={handleSubmit}
                            disabled={isUpdating}
                          >
                            <i className="ri-shopping-basket-line label-icon align-middle fs-16 ms-2"></i>
                            Add Site
                          </button>
                        </div>
                      </TabPane>
                    </TabContent>
                  </Form>
                </CardBody>
              </Card>
              <div className="d-flex mt-0 mb-0 justify-content-between align-items-center">
                <button
                  className="btn btn-soft-ghost d-flex ps-0 gap-2 align-items-center"
                  onClick={() => handlePreviousPage()}
                >
                  <i className="ri-arrow-left-line text-primary fs-18"></i>{" "}
                  <h5
                    className="mb-0  text-primary"
                    style={{ textDecoration: "underline" }}
                  >
                    {" "}
                    Back to Sites
                  </h5>
                </button>
              </div>
            </Col>
          </Row>
          <ToastContainer closeButton={false} limit={1} theme="light" />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddSite;
