import React, { useEffect, useState, useCallback, useMemo } from "react";
//Import Flatepicker
import Flatpickr from "react-flatpickr";
//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import UiContent from "../../Components/Common/UiContent";
import {
  CardBody,
  CardHeader,
  Container,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Card,
  Row,
  Col,
  Button,
  Input,
  ModalHeader,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
  Form,
} from "reactstrap";
import classnames from "classnames";
import { Link, useParams, useNavigate, Navigate } from "react-router-dom";
import { isEmpty } from "lodash";
import Select from "react-select";
//redux
import { useSelector, useDispatch } from "react-redux";
//Import actions
import { getSellers as onGetSellers } from "../../store/ecommerce/action";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import img13 from "../../assets/images/small/roster.svg";
import Loader from "../../Components/Common/Loader";
import moment from "moment";
import { get_cookie } from "../../helpers/get_cookie";
const RosterLounge = () => {
  const all_permissions = JSON.parse(get_cookie("permissions"));
  console.log(all_permissions?.includes("create_rosters"));
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const dispatch = useDispatch();
  const [sellerList, setSellerList] = useState([]);
  const [modal, setModal] = useState(false);
  const [companyType, setcompanyType] = useState(null);
  const [customHoverTab, setcustomHoverTab] = useState("1");
  const [isLoading, setisLoading] = useState(false);
  const [active_data, setActiveData] = useState([]);
  const [selected_active_data, setSelectedActiveData] = useState([]);
  const [archived_data, setArchivedData] = useState([]);
  const [selected_archived_data, setSelectedArchivedData] = useState([]);
  const [site_dropdown, setSiteDropdown] = useState("");
  const [active_site_id, setActiveSiteId] = useState("");
  const [archived_site_id, setArchivedSiteId] = useState("");
  const [active_count, setActiveCount] = useState("");
  const [archived_count, setArchivedCount] = useState("");
  const [site_id, setSiteId] = useState("");
  const [roster_name, setRosterName] = useState("");
  const [start_date, setStartDate] = useState([]);
  const [end_date, setEndDate] = useState([]);
  const [is_end_date, setIsEndDate] = useState(false);
  const [start_date_error, setStartDateError] = useState("");
  const [end_date_error, setEndDateError] = useState("");
  const [roster_name_error, setRosterNameError] = useState("");
  const [rosterEndDate, setRosterEndDate] = useState([]);
  const [site_id_error, setSiteIdError] = useState("");
  const [modalEdit, setModalEdit] = useState(false);
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [roster_id, setRosterId] = useState("");
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);

  // console.log(all_permissions)
  const { userId } = useParams();
  // console.log(userId);
  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
    }
  };
  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  const { sellers } = useSelector((state) => ({
    sellers: state.Ecommerce.sellers,
  }));
  const [authUserId, setAuthUserId] = useState();
  const navigate = useNavigate(); // Create a useNavigate instance
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get("/get-user-details");
        console.log(res);
        setAuthUserId(res.id);
        loadRosterLounge(res.id);
      } catch (err) {
        console.error(err);
      }
    };

    fetchData();
  }, []);

  const defaultdate = () => {
    let d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    let h = d.getHours() % 12 || 12;
    let ampm = d.getHours() < 12 ? "AM" : "PM";
    return (
      d.getDate() +
      " " +
      months[d.getMonth()] +
      ", " +
      d.getFullYear() +
      ", " +
      h +
      ":" +
      d.getMinutes() +
      " " +
      ampm
    ).toString();
  };
  const [date, setDate] = useState(defaultdate());
  const loadRosterLounge = (userId) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    setisLoading(true);
    axios
      .post("/filter-listactive-roster", formData)
      .then((res) => {
        setActiveData(res?.active_rosters.data);
        setSelectedActiveData(res?.active_rosters.data);
        setArchivedData(res?.archived_rosters.data);
        setSelectedArchivedData(res?.archived_rosters.data);
        console.log(res);
        setSiteDropdown(res?.sites);
        setActiveCount(res.active_rosters.count);
        setArchivedCount(res.archived_rosters.count);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  // Onchange for the site select field.
  const loadRosterLounges = (userId, isActiveTab) => {
    const formData = {
        user_id: userId,
        business_id: business_id,
    };
    setisLoading(true);
    axios
        .post("/filter-listactive-roster", formData)
        .then((res) => {
            if (isActiveTab) {
                // Load data for active tab only
                setActiveData(res?.active_rosters.data);
                setSelectedActiveData(res?.active_rosters.data);
                setActiveCount(res.active_rosters.count);
            } else {
                // Load data for archived tab only
                setArchivedData(res?.archived_rosters.data);
                setSelectedArchivedData(res?.archived_rosters.data);
                setArchivedCount(res.archived_rosters.count);
            }
            setSiteDropdown(res?.sites);
        })
        .catch((error) => {
            console.error(error);
        })
        .finally(() => {
            setisLoading(false);
        });
  };
  
  useEffect(() => {
    if (!isEmpty(active_data)) setActiveData(active_data);
  }, [active_data]);

  document.addEventListener("DOMContentLoaded", function () {
    const searchInput = document.getElementById("searchActiveFieldforSite");
    searchInput.addEventListener("input", function (e) {
      searchActiveList(e.target.value);
    });
  });
  const searchActiveList = (inputVal) => {
    inputVal = inputVal.toLowerCase().trim();
    if (!inputVal || inputVal.length === 0) {
      document.getElementById("noactiveresult").style.display = "none";
      document.getElementById("activeteamlist").style.display = "";
      loadRosterLounge(authUserId);
    } else {
      const filterItems = (arr, query) => {
        return arr.filter((el) => {
          const lowerCaseValues = Object.values(el).map((value) =>
            String(value).toLowerCase()
          );
          return lowerCaseValues.some(
            (value) => value.indexOf(query.toLowerCase()) !== -1
          );
        });
      };
      let filterData = inputVal
        ? filterItems(active_data, inputVal)
        : active_data;
      setActiveData(filterData);
      if (filterData.length === 0) {
        document.getElementById("noactiveresult").style.display = "";
        document.getElementById("activeteamlist").style.display = "none";
      } else {
        document.getElementById("noactiveresult").style.display = "none";
        document.getElementById("activeteamlist").style.display = "";
      }
    }
  };

  document.addEventListener("DOMContentLoaded", function () {
    const searchInput = document.getElementById("searchArchivedFieldforSite");
    searchInput.addEventListener("input", function (e) {
      searchArchivedList(e.target.value);
    });
  });
  const searchArchivedList = (inputVal) => {
    inputVal = inputVal.toLowerCase().trim();
    if (!inputVal || inputVal.length === 0) {
      loadRosterLounge(authUserId);
      document.getElementById("noarchivedresult").style.display = "none";
      document.getElementById("archivedteamlist").style.display = "";
    } else {
      const filterItems = (arr, query) => {
        return arr.filter((el) => {
          const lowerCaseValues = Object.values(el).map((value) =>
            String(value).toLowerCase()
          );
          return lowerCaseValues.some(
            (value) => value.indexOf(query.toLowerCase()) !== -1
          );
        });
      };
      let filterData = inputVal
        ? filterItems(archived_data, inputVal)
        : archived_data;
      setArchivedData(filterData);
      if (filterData.length === 0) {
        document.getElementById("noarchivedresult").style.display = "";
        document.getElementById("archivedteamlist").style.display = "none";
      } else {
        document.getElementById("noarchivedresult").style.display = "none";
        document.getElementById("archivedteamlist").style.display = "";
      }
    }
  };
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const openModal = () => {
    setSiteId("");
    setRosterName("");
    setStartDate("");
    setIsEndDate("");
    setEndDate("");
    setModal(true);
  };
  const archiveModal = (roster_id, status) => {
    setButtonClicked(status);
    setRosterId(roster_id);
    setSiteId("");
    setRosterName("");
    setStartDate("");
    setIsEndDate("");
    setEndDate("");
    setmodal_assignModal(true);
    setIsModalLoading(false);
  };
  const activeModal = (roster_id, authUserId, site_id) => {
    setRosterId(roster_id);
    setSiteId("");
    setRosterName("");
    setStartDate("");
    setIsEndDate("");
    setEndDate("");
    setmodal_assignModal(true);

    axios
      .post("/load-roster-date", {
        rosterId: roster_id,
        userId: authUserId,
        business_id: business_id,
        siteId: site_id,
      })
      .then((response) => {
        setRosterEndDate(response.date_to_load);
        console.log("Response from backend:", response.data);
      })
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
      });
  };

  //Tab
  const [activeTab, setActiveTab] = useState("1");
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const category = (e) => {
    if (e === "All") {
      var filter = active_data.filter((item) => item.category !== e);
    } else {
      filter = active_data.filter((item) => item.category === e);
    }
    setActiveData(filter);
  };
  function handleActiveMulti(selectedOption) {
    setisLoading(true);
    if (
      !selectedOption ||
      selectedOption.length == 0 ||
      selectedOption.value == "all"
    ) {
      setActiveSiteId("");
      loadRosterLounges(authUserId,true);
    } else {
      setActiveSiteId(selectedOption);
      const selectedValues = selectedOption;
      console.log(selectedOption, "selectedValues");
      const formData = {
        user_id: authUserId,
        business_id: business_id,
        site_id: selectedValues,
      };
      axios
        .post("/filter-data", formData)
        .then((res) => {
          console.log(res.data);
          const siteList = res.active_rosters;
          if (siteList) {
            setActiveData(siteList);
          } else {
            toast.warning("No Storage Data Found", { theme: "light" });
          }
        })
        .catch((error) => {
          // Handle any errors
        })
        .finally(() => {
          setisLoading(false);
        });
    }
  }
  function handleArchivedMulti(selectedOption) {
    setisLoading(true);
    if (!selectedOption || selectedOption.length == 0 || selectedOption.value == "all") {
      setArchivedSiteId("");
      loadRosterLounges(authUserId, false);
    } else {
      setArchivedSiteId(selectedOption);
      const selectedValues = selectedOption;
      const formData = {
        user_id: authUserId,
        business_id: business_id,
        site_id: selectedValues,
      };
      axios
        .post("/filter-data", formData)
        .then((res) => {
          console.log(res.data);
          const siteList = res.archived_rosters;
          if (siteList) {
            setArchivedData(siteList);
          } else {
            toast.warning("No Storage Data Found", { theme: "light" });
          }
        })
        .catch((error) => {
          // Handle any errors
        })
        .finally(()=>{
          setisLoading(false);
        })
    }
  }
  function handleOptionChange(selectedOption) {
    setSiteId({ value: selectedOption.value, label: selectedOption.label });
  }
  //save Roster
  const saveRoster = (event) => {
    event.preventDefault();
    if (site_id === "") {
      setSiteIdError("Please select site name.");
    } else {
      setSiteIdError("");
    }
    if (roster_name === "") {
      setRosterNameError("Please enter roster name.");
    } else {
      setRosterNameError("");
    }
    if (start_date.length === 0) {
      setStartDateError("Please select start date.");
    } else {
      setStartDateError("");
    }
    if (is_end_date === true && end_date.length === 0) {
      setEndDateError("Please select end date.");
    } else {
      setEndDateError("");
    }
    if (
      roster_name === "" ||
      start_date.length === 0 ||
      (is_end_date === true && end_date.length === 0)
    ) {
      return;
    }
    const from_date = moment(start_date, "DD MMM YYYY").format("DD/MM/YYYY");
    let to_date = "";

    if (is_end_date === true && end_date !== "") {
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (startDateObj > endDateObj) {
        toast.error("The start date should not be greater than the end date");
        return;
      }

      to_date = moment(end_date, "DD MMM YYYY").format("DD/MM/YYYY");
    }
    //setisLoading(true);
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      site_id: site_id.value,
      roster_name: roster_name,
      start_date: from_date,
      is_end_date: is_end_date,
      end_date: to_date,
    };
    axios
      .post("/add-new-roster", formData)
      .then((response) => {
        if (response.error == 1) {
          toast.success(response.message, { theme: "light" });
          loadRosterLounge(authUserId);
          setModal(false);
          setSiteId("");
          setRosterName("");
          setStartDate("");
          setIsEndDate("");
          setEndDate("");
        } else {
          toast.error(response.message, { theme: "light" });
          setModal(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };
  const UpdateRoster = (event) => {
    event.preventDefault();
    if (site_id === "") {
      setSiteIdError("Please select site name.");
    } else {
      setSiteIdError("");
    }
    if (roster_name === "") {
      setRosterNameError("Please enter roster name.");
    } else {
      setRosterNameError("");
    }
    if (start_date.length === 0) {
      setStartDateError("Please select start date.");
    } else {
      setStartDateError("");
    }
    if (is_end_date === true && end_date.length === 0) {
      setEndDateError("Please select end date.");
    } else {
      setEndDateError("");
    }
    if (
      roster_name === "" ||
      start_date.length === 0 ||
      (is_end_date === true && end_date.length === 0)
    ) {
      return;
    }
    const from_date = moment(start_date, "DD MMM YYYY").format("DD/MM/YYYY");
    let to_date = "";

    if (is_end_date === true && end_date !== "") {
      const startDateObj = new Date(start_date);
      const endDateObj = new Date(end_date);

      if (startDateObj > endDateObj) {
        toast.error("The start date should not be greater than the end date");
        return;
      }

      to_date = moment(end_date, "DD MMM YYYY").format("DD/MM/YYYY");
    }
    //setisLoading(true);
    var formData = {
      roster_id: roster_id,
      user_id: authUserId,
      business_id: business_id,
      site_id: site_id.value,
      roster_name: roster_name,
      start_date: from_date,
      is_end_date: is_end_date,
      end_date: to_date,
    };
    axios
      .post("/edit-roster", formData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          loadRosterLounge(authUserId);
          setModalEdit(false);
          setSiteId("");
          setRosterName("");
          setStartDate("");
          setIsEndDate("");
          setEndDate("");
        } else {
          toast.error(response.message, { theme: "light" });
          setModalEdit(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };
  const handleCheckboxChange = (e, day) => {
    const isOpen = e.target.checked;
    setIsEndDate(isOpen);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    var formData = {
      roster_id: roster_id,
      user_id: authUserId,
      business_id: business_id,
      type: buttonClicked,
    };
    // archiveRoster
    axios
      .post("/archive-roster", formData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          loadRosterLounge(authUserId);
          setmodal_assignModal(false);
        } else {
          toast.error(response.message);
          setmodal_assignModal(true);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong...");
      });
  };
  document.title = "Roster Lounge | Get Rostered";
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "#7F4BDA" : "#fff",
      boxShadow: "0 0 0 1px #7F4BDA",
      minHeight: "50px",
      border: "0px !important",
      borderRadius: "40px",
      padding: "10px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA",
      "&:hover": {
        color: "#7527CE",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };

  const activeWithoutDuplicates = [
    ...new Map(selected_active_data.map(item => [item.site_name, item])).values()
  ];
  const archieveWithoutDuplicates = [
    ...new Map(selected_archived_data.map(item => [item.site_name, item])).values()
  ];
 
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Roster Lounge" />
          <Row className="mt-3">
            <Col
              xxl={12}
              style={{
                backgroundColor: "#f3f6f9",
                boxShadow: "none",
              }}
            >
              {/* <Card
                style={{
                  backgroundColor: "#f3f6f9",
                  boxShadow: "none",
                }}
              > */}
              <Row>
                <Col lg={4}>
                  <div className="mb-3">
                    <Nav pills className="nav nav-pills custom-hover-nav-tabs">
                      <NavItem
                        style={{
                          border: "1px solid #865ce2",
                          borderTopLeftRadius: "7px",
                          borderBottomLeftRadius: "7px",
                        }}
                      >
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customHoverTab === "1",
                          })}
                          onClick={() => {
                            customHovertoggle("1");
                          }}
                        >
                          <i className="bx bx-bolt-circle nav-icon nav-tab-position"></i>
                          <h5 className="nav-titl nav-tab-position m-0">
                            Active ({active_count})
                          </h5>
                        </NavLink>
                      </NavItem>
                      <NavItem
                        style={{
                          border: "1px solid #865ce2",
                          borderTopRightRadius: "7px",
                          borderBottomRightRadius: "7px",
                          borderLeft: "none",
                        }}
                      >
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: customHoverTab === "2",
                          })}
                          onClick={() => {
                            customHovertoggle("2");
                          }}
                        >
                          <i className="bx bx-archive nav-icon nav-tab-position m-0"></i>
                          <h5 className="nav-titl nav-tab-position">
                            Archived ({archived_count})
                          </h5>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </Col>
                <Col lg={4}>
                  {customHoverTab == 1 ? (
                    // <select
                    //   className="form-select rounded-pill"
                    //   value={active_site_id || "default"}
                    //   onChange={(event) => {
                    //     const selectedOption = event.target.value;
                    //     handleActiveMulti(selectedOption);
                    //   }}
                    //   style={{height:"50px", border:"none", float: "center"}}
                    // >
                    //   <option value="default" disabled>
                    //     All Sites
                    //   </option>
                    //   {site_dropdown &&
                    //     Array.isArray(site_dropdown) &&
                    //     site_dropdown.map((option) => (
                    //       <option key={option.id} value={option.id}>
                    //         {option.site_name}
                    //       </option>
                    //     ))}
                    // </select>
                    // <Select
                    //   value={active_site_id}
                    //   onChange={(selectedOption) => {
                    //     handleActiveMulti(selectedOption);
                    //   }}
                    //   options={[
                    //     // { value: "all", label: "All sites", isDisabled: true }, // Add the "All sites" option
                    //     ...(site_dropdown && Array.isArray(site_dropdown)
                    //       ? site_dropdown.map((item) => ({
                    //           value: item.id,
                    //           label: item.site_name,
                    //         }))
                    //       : []),
                    //   ]}
                    //   styles={customStyles}
                    // />
                    <Select
                    value={active_site_id}
                    onChange={(selectedOption) => {
                      handleActiveMulti(selectedOption);
                    }}
                    options={
                      Array.isArray(activeWithoutDuplicates) && activeWithoutDuplicates.length > 0
                        ? [
                            { value: "all", label: "All sites" }, // Show "All sites" only if there are sites available
                            ...activeWithoutDuplicates.map((item) => ({
                              value: item.site_id,
                              label: item.site_name,
                            })),
                          ]
                        : []
                    }
                    noOptionsMessage={() =>
                      Array.isArray(activeWithoutDuplicates) && activeWithoutDuplicates.length === 0
                        ? "No sites found"
                        : null
                    }
                    styles={customStyles}
                  />
                  

                  ) : customHoverTab == 2 ? (
                    <Select
                    value={archived_site_id}
                    onChange={(selectedOption) => {
                      handleArchivedMulti(selectedOption);
                    }}
                    options={
                      Array.isArray(archieveWithoutDuplicates) && archieveWithoutDuplicates.length > 0
                        ? [
                            { value: "all", label: "All sites" }, // Show "All sites" only if there are sites available
                            ...archieveWithoutDuplicates.map((item) => ({
                              value: item.site_id,
                              label: item.site_name,
                            })),
                          ]
                        : []
                    }
                    noOptionsMessage={() =>
                      Array.isArray(archieveWithoutDuplicates) && archieveWithoutDuplicates.length === 0
                        ? "No sites found"
                        : null
                    }
                    styles={customStyles}
                  />
                  ) : (
                    // <select
                    //   className="form-select rounded-pill"
                    //   value={archived_site_id || "default"}
                    //   onChange={(event) => {
                    //     const selectedOption = event.target.value;
                    //     handleArchivedMulti(selectedOption);
                    //   }}
                    //   style={{ height: "50px", border: "none" }}
                    // >
                    //   <option value="default" disabled>
                    //     All Sites
                    //   </option>
                    //   {site_dropdown &&
                    //     Array.isArray(site_dropdown) &&
                    //     site_dropdown.map((option) => (
                    //       <option key={option.id} value={option.id}>
                    //         {option.site_name}
                    //       </option>
                    //     ))}
                    // </select>
                    <select className="form-select rounded-pill">
                      <option value="default" disabled>
                        All Sites
                      </option>
                    </select>
                  )}
                </Col>

                <Col lg={4}>
                  {customHoverTab == 1 &&
                  all_permissions?.includes("create_rosters") ? (
                    // <div>
                    <button
                      className="btn ms-2 mt-1"
                      style={{
                        backgroundColor: "#865ce2",
                        color: "white",
                        float: "right",
                        padding: "10px",
                      }}
                      onClick={openModal}
                    >
                      <i className="ri-add-circle-fill me-1 align-bottom"></i>{" "}
                      Add New
                    </button>
                  ) : // </div>
                  null}
                </Col>
              </Row>

              {/* <CardBody> */}
              <TabContent
                activeTab={customHoverTab}
                className="text-muted mt-0"
              >
                {customHoverTab === "1" ? (
                  active_data.length > 0 ? (
                    <TabPane tabId="1" id="custom-hover-customere">
                      <Row className="align-items-center">
                        <Col
                          lg={12}
                          className="d-flex justify-content-between"
                        ></Col>
                      </Row>

                      <Row
                        className="mt-4"
                        id="activeteamlist"
                        style={{ fontSize: "12px" }}
                      >
                        {active_data.map((active, key) => (
                          <React.Fragment key={key}>
                            <Col
                              key={key}
                              lg={4}
                              xl={3}
                              md={6}
                              className="mb-4"
                            >
                              <Card
                                className="p-3"
                                style={{ borderRadius: "15px" }}
                              >
                                <div className="d-flex justify-content-between mb-2 align-items-center">
                                  <div className="hstack gap-1">
                                    <div className="pe-1">
                                      {all_permissions?.includes(
                                        "view_shifts"
                                      ) ? (
                                        <Link
                                          to={"/calendar"}
                                          state={{
                                            siteId: active.site_id,
                                            userId: authUserId,
                                            headerId: active.roster_id,
                                            restrictDate: active.end_date,
                                          }}
                                        >
                                          <img
                                            src={img13}
                                            alt=""
                                            className="avatar-sm"
                                          />
                                        </Link>
                                      ) : (
                                        <img
                                          src={img13}
                                          alt=""
                                          className="avatar-sm"
                                        />
                                      )}
                                    </div>
                                    <h5 className="mb-1">{active.site_name}</h5>
                                  </div>

                                  <div>
                                    {all_permissions?.includes(
                                      "edit_rosters"
                                    ) && (
                                      <button
                                        className="btn p-0"
                                        style={{
                                          fontSize: "18px",
                                          color: "#865ce2",
                                          width: "20px",
                                        }}
                                        onClick={() => {
                                          setModalEdit(true);
                                          setisLoading(true);
                                          setRosterId("");
                                          var formData = {
                                            user_id: authUserId,
                                            business_id: business_id,
                                            roster_id: active.roster_id,
                                          };
                                          axios
                                            .post(
                                              "/get-roster-edit-data",
                                              formData
                                            )
                                            .then((response) => {
                                              console.log(response);
                                              setRosterId(
                                                response.roster_lounge_data.id
                                              );

                                              if (
                                                site_dropdown &&
                                                response.roster_lounge_data
                                              ) {
                                                const selectedWareOption =
                                                  site_dropdown.find(
                                                    (option) =>
                                                      option.id ===
                                                      response
                                                        .roster_lounge_data
                                                        .site_id
                                                  );
                                                if (selectedWareOption) {
                                                  setSiteId({
                                                    value:
                                                      response
                                                        .roster_lounge_data
                                                        .site_id,
                                                    label:
                                                      selectedWareOption.site_name,
                                                  });
                                                }
                                              }
                                              console.log(site_dropdown);
                                              setRosterName(
                                                response.roster_lounge_data
                                                  .roster_name
                                              );
                                              setStartDate(
                                                moment(
                                                  response.roster_lounge_data
                                                    .start_date
                                                ).format("DD MMM, YYYY")
                                              );
                                              setEndDate(
                                                moment(
                                                  response.roster_lounge_data
                                                    .end_date
                                                ).format("DD MMM, YYYY")
                                              );
                                              if (
                                                response &&
                                                response.roster_lounge_data &&
                                                response.roster_lounge_data
                                                  .end_date !== "" &&
                                                response.roster_lounge_data
                                                  .end_date !== null
                                              ) {
                                                setIsEndDate(true);
                                              } else {
                                                setIsEndDate(false);
                                              }
                                            })
                                            .catch((error) => {
                                              console.error(error);
                                            })
                                            .finally(() => {
                                              setisLoading(false);
                                            });
                                        }}
                                      >
                                        <i className="ri-pencil-fill me-0 align-bottom"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                                <Row className="align-items-center">
                                  <Col xs={6} lg={12}>
                                    <p className="mb-0 text-black">
                                      {active.roster_name}
                                    </p>
                                    <p className="card-text text-muted  mb-2">
                                      {" "}
                                      {active.date_range}
                                    </p>
                                  </Col>
                                </Row>
                                <Row className="align-items-center">
                                  <Col xs={6} lg={5}>
                                    <b
                                      className="mb-2"
                                      style={{ color: "black" }}
                                    >
                                      Zones
                                    </b>{" "}
                                  </Col>
                                  <Col xs={6} lg={7} className="d-flex ">
                                    <p className="card-text text-muted mb-2">
                                      {active.zones_count}
                                    </p>
                                  </Col>
                                </Row>
                                <Row className="align-items-center">
                                  <Col xs={6} lg={5}>
                                    <b
                                      className="mb-2"
                                      style={{ color: "black" }}
                                    >
                                      Assigned
                                    </b>
                                  </Col>
                                  <Col xs={6} lg={7} className="d-flex ">
                                    <p className="card-text text-muted mb-2">
                                      {active.assigned_count}
                                    </p>
                                  </Col>
                                </Row>
                                <Row className="align-items-center">
                                  <Col xs={6} lg={5}>
                                    <b
                                      className="mb-2"
                                      style={{ color: "black" }}
                                    >
                                      Open Shifts
                                    </b>
                                  </Col>
                                  <Col xs={6} lg={7} className="d-flex ">
                                    <p className="card-text text-muted mb-2">
                                      {active.open_shifts_count}
                                    </p>
                                  </Col>
                                </Row>

                                <hr></hr>

                                <Row>
                                  <Col
                                    lg={6}
                                    className=" pe-lg-0 pe-xl-0  mb-2"
                                  >
                                    {all_permissions?.includes(
                                      "delete_rosters"
                                    ) && (
                                      <Button
                                        className="w-100  accessButton"
                                        color="primary"
                                        onClick={() =>
                                          archiveModal(
                                            active.roster_id,
                                            "archive"
                                          )
                                        }
                                      >
                                        Archive
                                      </Button>
                                    )}
                                  </Col>
                                  <Col lg={6} className="ps-xl-1 mb-2">
                                    {all_permissions?.includes(
                                      "view_shifts"
                                    ) && (
                                      <Button
                                        className="w-100 archiveUnarchiveButton"
                                        color="primary"
                                        onClick={() => {
                                          navigate("/calendar", {
                                            state: {
                                              siteId: active.site_id,
                                              userId: authUserId,
                                              headerId: active.roster_id,
                                              restrictDate: active.end_date,
                                            },
                                          });
                                        }}
                                      >
                                        Access Roster
                                      </Button>
                                    )}
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          </React.Fragment>
                        ))}
                      </Row>
                      <Row id="noactiveresult" style={{ display: "none" }}>
                        <div className="py-4 mt-4 text-center">
                          <lord-icon
                            src="https://cdn.lordicon.com/msoeawqm.json"
                            trigger="loop"
                            colors="primary:#405189,secondary:#0ab39c"
                            style={{ width: "72px", height: "72px" }}
                          ></lord-icon>
                          <h5 className="mt-4">Sorry! No Result Found</h5>
                        </div>
                      </Row>
                    </TabPane>
                  ) : (
                    <Row
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        height: "60vh",
                      }}
                    >
                      <div>
                        <lord-icon
                          src="https://cdn.lordicon.com/nocovwne.json"
                          trigger="loop"
                          colors="primary:#121331,secondary:#08a88a"
                          style={{ width: "130px", height: "130px" }}
                        ></lord-icon>
                        <h5 style={{ color: "#888888" }}>
                          <i>
                            No rosters found. Please add a new roster to get
                            started.
                          </i>
                        </h5>
                      </div>
                    </Row>
                  )
                ) : archived_data.length > 0 ? (
                  <TabPane tabId="2" id="custom-hover-customere">
                    <Row className="mt-4" id="archivedteamlist">
                      {archived_data.map((archived, key) => (
                        <React.Fragment key={key}>
                          <Col key={key} lg={4} xl={3} md={6} className="mb-4">
                            <Card
                              className="p-3"
                              style={{ borderRadius: "15px" }}
                            >
                              <div className="d-flex justify-content-between mb-2 align-items-center">
                                <div className="hstack gap-1">
                                  <div className="pe-1">
                                    <Link to="/jobroster">
                                      <img
                                        src={img13}
                                        alt=""
                                        className="avatar-sm"
                                      />
                                    </Link>
                                  </div>
                                  <h5 className="mb-1">{archived.site_name}</h5>
                                </div>

                                {/* <div>
                                {all_permissions?.includes("edit_rosters") && (
                                  <button
                                    className="btn p-0"
                                    style={{
                                      fontSize: "18px",
                                      color: "#865ce2",
                                      width: "20px",
                                    }}
                                    onClick={() => {
                                      setModalEdit(true);
                                      setisLoading(true);
                                      setRosterId("");
                                      var formData = {
                                        user_id: authUserId,
                                        roster_id: active.roster_id,
                                      };
                                      axios
                                        .post("/get-roster-edit-data", formData)
                                        .then((response) => {
                                          console.log(response);
                                          setRosterId(
                                            response.roster_lounge_data.id
                                          );

                                          if (
                                            site_dropdown &&
                                            response.roster_lounge_data
                                          ) {
                                            const selectedWareOption =
                                              site_dropdown.find(
                                                (option) =>
                                                  option.id ===
                                                  response.roster_lounge_data
                                                    .site_id
                                              );
                                            if (selectedWareOption) {
                                              setSiteId({
                                                value:
                                                  response.roster_lounge_data
                                                    .site_id,
                                                label:
                                                  selectedWareOption.site_name,
                                              });
                                            }
                                          }
                                          console.log(site_dropdown);
                                          setRosterName(
                                            response.roster_lounge_data
                                              .roster_name
                                          );
                                          setStartDate(
                                            moment(
                                              response.roster_lounge_data
                                                .start_date
                                            ).format("DD MMM, YYYY")
                                          );
                                          setEndDate(
                                            moment(
                                              response.roster_lounge_data
                                                .end_date
                                            ).format("DD MMM, YYYY")
                                          );
                                          if (
                                            response &&
                                            response.roster_lounge_data &&
                                            response.roster_lounge_data
                                              .end_date !== "" &&
                                            response.roster_lounge_data
                                              .end_date !== null
                                          ) {
                                            setIsEndDate(true);
                                          } else {
                                            setIsEndDate(false);
                                          }
                                        })
                                        .catch((error) => {
                                          console.error(error);
                                        })
                                        .finally(() => {
                                          setisLoading(false);
                                        });
                                    }}
                                  >
                                    <i className="ri-pencil-fill me-0 align-bottom"></i>
                                  </button>
                                )}
                              </div> */}
                              </div>
                              <Row className="align-items-center">
                                <Col xs={6} lg={12}>
                                  <p className="mb-0 text-black">
                                    {archived.roster_name}
                                  </p>
                                  <p className="card-text text-muted  mb-2">
                                    {" "}
                                    {archived.date_range}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col xs={6} lg={5}>
                                  <b
                                    className="mb-2"
                                    style={{ color: "black" }}
                                  >
                                    Zones
                                  </b>{" "}
                                </Col>
                                <Col xs={6} lg={7} className="d-flex ">
                                  <p className="card-text text-muted mb-2">
                                    {archived.zones_count}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col xs={6} lg={5}>
                                  <b
                                    className="mb-2"
                                    style={{ color: "black" }}
                                  >
                                    Assigned
                                  </b>
                                </Col>
                                <Col xs={6} lg={7} className="d-flex ">
                                  <p className="card-text text-muted mb-2">
                                    {archived.assigned_count}
                                  </p>
                                </Col>
                              </Row>
                              <Row className="align-items-center">
                                <Col xs={6} lg={5}>
                                  <b
                                    className="mb-2"
                                    style={{ color: "black" }}
                                  >
                                    Open Shifts
                                  </b>
                                </Col>
                                <Col xs={6} lg={7} className="d-flex ">
                                  <p className="card-text text-muted mb-2">
                                    {archived.open_shifts_count}
                                  </p>
                                </Col>
                              </Row>

                              <hr></hr>

                              <Row>
                                <Col lg={12} className="pe-lg-0 pe-xl-0 mb-2">
                                  {/* <Button
                                    className="w-100  archiveUnarchiveButton"
                                    color="primary"
                                   
                                  >
                                    Unarchive 
                                  </Button> */}
                                  <Button
                                    className="archiveUnarchiveButton"
                                    color="primary"
                                    style={{ width: "97%" }}
                                    onClick={() =>
                                      archiveModal(
                                        archived.roster_id,
                                        "unarchive"
                                      )
                                    }
                                  >
                                    Unarchive
                                  </Button>
                                </Col>
                                {/* <Col lg={6} className="mb-2">
                               
                                  <Button
                                    className="w-100 accessButton"
                                    color="primary"
                                    onClick={() => {
                                      navigate("/jobroster");
                                    }}
                                  >
                                    Access Roster
                                  </Button>
                                
                              </Col> */}
                              </Row>
                            </Card>
                          </Col>

                          {/*                         
                        <Col xl={3} lg={6}>
                          <Col>
                            <Card className="card-body">
                              <div className="d-flex mb-1 align-items-center">
                                <div className="flex-shrink-0">
                                  <Link to="/jobroster">
                                    <img
                                      src={img13}
                                      alt=""
                                      className="avatar-sm"
                                    />
                                  </Link>
                                </div>
                                <div className="flex-grow-1 ms-2">
                                  <h5 className="card-title mb-1">
                                    {archived.site_name}
                                  </h5>
                                  <p className="text-muted mb-0">
                                    {archived.roster_name}
                                  </p>
                                </div>
                              </div>
                              <Col lg={12}>
                                <p className="card-text text-muted  mb-2">
                                  {" "}
                                  <i className="bx bx-calendar text-primary"></i>
                                  {archived.date_range}
                                </p>
                              </Col>
                              <Row>
                                <Col lg={5}>
                                  <h6 className="mb-2">Zones</h6>
                                </Col>
                                <Col lg={7}>
                                  <p className="card-text text-muted">
                                    {archived.zones_count}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5}>
                                  <h6 className="mb-2">Assigned</h6>
                                </Col>
                                <Col lg={7}>
                                  <p className="card-text text-muted">
                                    {archived.assigned_count}
                                  </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col lg={5}>
                                  <h6 className="mb-1">Open Shifts</h6>
                                </Col>
                                <Col lg={7}>
                                  <p className="card-text text-muted">
                                    {archived.open_shifts_count}
                                  </p>
                                </Col>
                              </Row>
                              <hr></hr>
                             
                              <Row className="align-items-center">
                                <Col
                                  xs={12}
                                  md={5}
                                  className="d-flex justify-content-center justify-content-md-start mb-3 mb-md-0"
                                >
                                  <Button
                                    className="w-100 w-md-75 archiveUnarchiveButton"
                                    color="primary"
                                   
                                  >
                                    Unarchive Roster
                                  </Button>
                                </Col>
                                <Col
                                  xs={0}
                                  md={2}
                                  className="d-none d-md-block"
                                ></Col>{" "}
                                
                                <Col
                                  xs={12}
                                  md={5}
                                  className="d-flex justify-content-center justify-content-md-end"
                                >
                                  <Button
                                    className="w-100 w-md-75 accessButton"
                                    color="primary"
                                    onClick={handleAccessRoster}
                                  >
                                    Access Roster
                                  </Button>
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                        </Col> */}
                        </React.Fragment>
                      ))}
                    </Row>
                    <Row id="noarchivedresult" style={{ display: "none" }}>
                      <div className="py-4 mt-4 text-center">
                        <lord-icon
                          src="https://cdn.lordicon.com/msoeawqm.json"
                          trigger="loop"
                          colors="primary:#405189,secondary:#0ab39c"
                          style={{ width: "72px", height: "72px" }}
                        ></lord-icon>
                        <h5 className="mt-4">Sorry! No Result Found</h5>
                      </div>
                    </Row>
                  </TabPane>
                ) : (
                  <Row
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                      height: "60vh",
                    }}
                  >
                    <div>
                      <lord-icon
                        src="https://cdn.lordicon.com/nocovwne.json"
                        trigger="loop"
                        colors="primary:#121331,secondary:#08a88a"
                        style={{ width: "130px", height: "130px" }}
                      ></lord-icon>
                      <h5 style={{ color: "#888888" }}>
                        <i>No archived rosters found.</i>
                      </h5>
                    </div>
                  </Row>
                )}
              </TabContent>
              {/* </CardBody> */}
              {/* </Card> */}
            </Col>
          </Row>
          <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={modal}
            toggle={toggle}
            centered
          >
            <ModalHeader toggle={toggle}>Add New Roster</ModalHeader>
            <ModalBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="firstnameInput" className="form-label">
                          Site
                        </Label>
                        <Select
                          value={site_id}
                          className={`${site_id_error ? "is-invalid" : ""}`}
                          onChange={(selectedOption) => {
                            handleOptionChange(selectedOption);
                            setSiteIdError("");
                          }}
                          options={
                            site_dropdown && Array.isArray(site_dropdown)
                              ? site_dropdown.map((item) => ({
                                  value: item.id,
                                  label: item.site_name,
                                }))
                              : []
                          }
                        />
                        {site_id_error && (
                          <div className="invalid-feedback">
                            {site_id_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="phonenumberInput" className="form-label">
                          Roster Name
                        </Label>
                        <Input
                          type="text"
                          value={roster_name}
                          onChange={(e) => {
                            setRosterName(e.target.value);
                            setRosterNameError("");
                          }}
                          className={`form-control ${
                            roster_name_error ? "is-invalid" : ""
                          }`}
                          id="phonenumberInput"
                          placeholder="Enter roster name"
                        />
                        {roster_name_error && (
                          <div className="invalid-feedback">
                            {roster_name_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="birthdayidInput" className="form-label">
                          Start Date
                        </Label>
                        <Flatpickr
                          className={`form-control ${
                            start_date_error ? "is-invalid" : ""
                          }`}
                          options={{
                            dateFormat: "d M, Y",
                          }}
                          onChange={(selectedDates) => {
                            setStartDate(
                              moment(selectedDates[0]).format("DD MMM, YYYY")
                            );
                            setStartDateError("");
                          }}
                          value={start_date}
                          placeholder="Select Date"
                        />
                        {start_date_error && (
                          <div className="invalid-feedback">
                            {start_date_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="birthdayidInput" className="form-label">
                          End Date
                        </Label>
                        <Row>
                          <Col sm={1}>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              defaultChecked
                              style={{ marginTop: "10px" }}
                              checked={is_end_date}
                              onChange={() => setIsEndDate(!is_end_date)}
                            />
                          </Col>
                          {is_end_date && (
                            <Col lg={11}>
                              <Flatpickr
                                className={`form-control ${
                                  end_date_error ? "is-invalid" : ""
                                }`}
                                options={{
                                  dateFormat: "d M, Y",
                                }}
                                onChange={(selectedDates) => {
                                  setEndDate(
                                    moment(selectedDates[0]).format(
                                      "DD MMM, YYYY"
                                    )
                                  );
                                  setEndDateError("");
                                }}
                                value={end_date}
                                placeholder="Select Date"
                              />
                              {end_date_error && (
                                <div className="invalid-feedback">
                                  {end_date_error}
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setModal(false);
                          }}
                        >
                          {/* <i className="ri-close-line me-1 align-middle"></i>{" "} */}
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(event) => saveRoster(event)}
                        >
                          <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
          </Modal>
          <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={modalEdit}
            toggle={toggleModalEdit}
            centered
          >
            <ModalHeader toggle={toggleModalEdit}>Edit Roster</ModalHeader>
            <div className="modal-content border-0 mt-3"></div>
            <ModalBody>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Input
                      type="hidden"
                      value={roster_id}
                      id="phonenumberInput"
                      placeholder="Enter roster name"
                    />
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="firstnameInput" className="form-label">
                          Site
                        </Label>
                        <Select
                          value={site_id}
                          className={`${site_id_error ? "is-invalid" : ""}`}
                          onChange={(selectedOption) => {
                            handleOptionChange(selectedOption);
                            setSiteIdError("");
                          }}
                          options={
                            site_dropdown && Array.isArray(site_dropdown)
                              ? site_dropdown.map((item) => ({
                                  value: item.id,
                                  label: item.site_name,
                                }))
                              : []
                          }
                          isDisabled={true}
                        />
                        {site_id_error && (
                          <div className="invalid-feedback">
                            {site_id_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="phonenumberInput" className="form-label">
                          Roster Name
                        </Label>
                        <Input
                          type="text"
                          value={roster_name}
                          onChange={(e) => {
                            setRosterName(e.target.value);
                            setRosterNameError("");
                          }}
                          className={`form-control ${
                            roster_name_error ? "is-invalid" : ""
                          }`}
                          id="phonenumberInput"
                          placeholder="Enter roster name"
                        />
                        {roster_name_error && (
                          <div className="invalid-feedback">
                            {roster_name_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="birthdayidInput" className="form-label">
                          Start Date
                        </Label>
                        <Flatpickr
                          className={`form-control ${
                            start_date_error ? "is-invalid" : ""
                          }`}
                          options={{
                            dateFormat: "d M, Y",
                            readOnly: true,
                            allowInput: false,
                            clickOpens: false,
                          }}
                          onChange={(selectedDates) => {
                            setStartDate(
                              moment(selectedDates[0]).format("DD MMM, YYYY")
                            );
                            setStartDateError("");
                          }}
                          value={start_date}
                          placeholder="Select Date"
                        />
                        {start_date_error && (
                          <div className="invalid-feedback">
                            {start_date_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="birthdayidInput" className="form-label">
                          End Date
                        </Label>
                        <Row>
                          <Col sm={1}>
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              id="formCheck1"
                              defaultChecked={!!end_date} // If end_date has a value, checkbox will be checked, otherwise, it will be unchecked
                              style={{ marginTop: "10px" }}
                              checked={is_end_date}
                              onChange={() => setIsEndDate(!is_end_date)}
                            />
                          </Col>
                          {is_end_date && (
                            <Col lg={11}>
                              <Flatpickr
                                className={`form-control ${
                                  end_date_error ? "is-invalid" : ""
                                }`}
                                options={{
                                  dateFormat: "d M, Y",
                                }}
                                onChange={(selectedDates) => {
                                  setEndDate(
                                    moment(selectedDates[0]).format(
                                      "DD MMM, YYYY"
                                    )
                                  );
                                  setEndDateError("");
                                }}
                                value={end_date}
                                placeholder="Select Date"
                              />
                              {end_date_error && (
                                <div className="invalid-feedback">
                                  {end_date_error}
                                </div>
                              )}
                            </Col>
                          )}
                        </Row>
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-link link-success text-decoration-none fw-medium"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setModalEdit(false);
                          }}
                        >
                          <i className="ri-close-line me-1 align-middle"></i>{" "}
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(event) => UpdateRoster(event)}
                        >
                          <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                          Update
                        </button>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </ModalBody>
          </Modal>
          {isModalLoading ? (
            <Modal
              size="md"
              isOpen={modal_assignModal}
              toggle={() => {
                tog_assignModal();
              }}
              centered
            >
              <ModalHeader toggle={tog_assignModal}></ModalHeader>
              <ModalBody className="text-center">
                <Row>
                  <Col sm={4}>
                    {/* <div className="d-flex justify-content-center mt-4 mb-3"> */}
                    <div className="placeholder-glow">
                      <span
                        className="placeholder col-12 img-thumbnail avatar-xxs "
                        style={{
                          height: "9rem",
                          width: "9rem",
                        }}
                      ></span>
                      {/* </div> */}
                    </div>
                  </Col>
                  <Col sm={8}>
                    <div className="confirmationText">
                      <h5 className="text-left">
                        <span className="placeholder col-8"></span>
                        <span className="placeholder col-6"></span>
                      </h5>
                      <p className="subText">
                        <span className="placeholder col-4"></span>
                      </p>
                      <div className="d-flex flex-wrap gap-2">
                        <Button className="w-lg cancelButton" color="primary">
                          <span className="placeholder col-6"></span>
                        </Button>
                        <Button className="w-lg confirmButton" color="primary">
                          <span className="placeholder col-6"></span>
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          ) : (
            <Modal
              className="zoomIn"
              id="addSeller"
              size="md"
              isOpen={modal_assignModal}
              toggle={tog_assignModal}
              centered
            >
              <ModalHeader toggle={tog_assignModal}></ModalHeader>
              <ModalBody className="text-center">
                <Row>
                  <Col lg={4}>
                    <img
                      src={img13}
                      alt=""
                      style={{ height: "9rem", width: "9rem" }}
                      className="  avatar-xxs "
                    />
                  </Col>

                  <Col lg={8}>
                    <div className="confirmationText">
                      <h5 className="text-left">
                        Please confirm that you want to{" "}
                        {buttonClicked == "archive" ? "archive" : "unarchive"}{" "}
                        this roster?
                      </h5>
                      <p className="subText">Confirm to proceed</p>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          className="w-lg cancelButton"
                          color="primary"
                          // outline
                          onClick={tog_assignModal}
                          // className="btn btn-talent btn-sm mt-auto"
                        >
                          Cancel
                        </Button>
                        <Button
                          className="w-lg confirmButton"
                          color="primary"
                          // style={styles.confirmButton}
                          onClick={(event) => handleSubmit(event)}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          )}
          <ToastContainer closeButton={false} limit={1} theme="light" />
          {isLoading && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, 0.8)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <Loader />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RosterLounge;
