import { Card, CardBody, Col, NavLink, Row, Toast } from "reactstrap";
import React, { useEffect, useState, useCallback, useMemo } from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Table } from "reactstrap";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DeleteModal from "../../../Components/Common/DeleteModal";
import Loader from "../../../Components/Common/Loader";
import { get_cookie } from "../../../helpers/get_cookie";

export const Workorder = () => {
  document.title = "Work Order | Get Rostered";
  // Vertical Nav Tabs
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [workOrderGroups, setWorkOrderGroups] = useState("");
  const [rowsData, setRowsData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [authUserId, setAuthUserId] = useState();
  const [role_id, setRoleid] = useState();
  //const [business_id, setbusinessid] = useState();
  const [originalName, setOriginalName] = useState('');

  const [isLoading, setisLoading] = useState(false);

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res.id, "helllllooooooo");
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const loadTable = (id) => {
    console.log(id, "idddd");
    setisLoading(true);

    var datavalue = {
      user_id: id,
      business_id: business_id,
    };
    axios.post("/get-work-order-template-groups", datavalue).then((res) => {
      console.log(res.data);
      setWorkOrderGroups(res.data);
      setisLoading(false);
    });
  };

  const addTableRows = () => {
    setRowsData([...rowsData, { grouproles: "", groupRate: "" }]);
  };

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    if (name === "group_name") {
      const updatedWorkOrderGroups = [...workOrderGroups];
      updatedWorkOrderGroups[index].group_name = value;
      setWorkOrderGroups(updatedWorkOrderGroups);
    } else if (name === "grouproles") {
      const updatedRowsData = [...rowsData];
      updatedRowsData[index].grouproles = value;
      setRowsData(updatedRowsData);
    }
  };

  console.log(rowsData, "rowsdata");
  console.log(workOrderGroups, "workOrderGroups");

  //Edit

  const handleEdit = (index) => {
    const updatedWorkOrderGroups = [...workOrderGroups];
    updatedWorkOrderGroups[index].isEditing = true;
    setWorkOrderGroups(updatedWorkOrderGroups);
  };
  //cancel button for edit
  const handleCancel = (index) => {
    const updatedWorkOrderGroups = [...workOrderGroups];
    updatedWorkOrderGroups[index].isEditing = false;
    setWorkOrderGroups(updatedWorkOrderGroups);
  };
  //save(Update)
  const handleSave = async (index) => {
    const item = workOrderGroups[index];
    console.log("update:", item);
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      group_id: item.id,
      group_name: item.group_name,
    };
    if (item.group_name == "") {
      toast.warning("Group name can not be empty");
    }

    axios
      .post("/work-order-update-group", datavalue)
      .then((res) => {
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };

  const [deleteId, setDeleteId] = useState("");
  //Delete(Upadte)
  const handleDelete = async (index) => {
    const item = workOrderGroups[index];
    var id = item.id;
    setDeleteId(id);
    setDeleteModal(true);
  };
  const handleDeletePermission = async () => {
    var deleteval = {
      user_id:authUserId,
      business_id: business_id,
      group_id: deleteId,
    };
    axios
      .post("/work-order-delete-group", deleteval)
      .then((res) => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  //SAVE(new row)
  const saveTableRow = (index) => {
    const data = rowsData[index];
    // console.log('Saving:', data.groupName);
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      group_name: data.grouproles,
    };
    if (data.grouproles == "") {
      toast.error("The name field is required.", { theme: "light" });
      return;
    }

    axios
      .post("/work-order-create-group", datavalue)
      .then((res) => {
        console.log(res.success);
        toast.success(res.message, { theme: "light" });
        const updatedRows = [...rowsData];
        updatedRows.splice(index, 1);
        setRowsData([...updatedRows]);
        loadTable(authUserId);
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  //cancel(new row)
  const deleteTableRows = (index) => {
    const updatedRowsData = [...rowsData];
    updatedRowsData.splice(index, 1);
    setRowsData(updatedRowsData);
  };
  const [permission, setPermission] = useState("");
  let rolename = ""; // Declare 'a' as a 'let' variable

  //   useEffect(() => {
  //     axios.get("/api/RolePermission")
  //       .then((res) => {
  //         console.log(res, "rolepermission12324");
  //         setPermission(res);
  //       });
  //   }, []);

  const filteredMenuItemsGeneral = !permission.includes("roles_permissions");

  return (
    <div>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeletePermission}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Workorder</h5>
        </CardHeader>
        <Row>
          <Col xl={12}>
            <CardBody>
              {filteredMenuItemsGeneral && (
                <Table className="table table-bordered   align-middle mb-3">
                  <thead className="bg-light">
                    <tr>
                      <th className="listTypeHead" style={{ width: "230px" }}>
                        Group Name
                      </th>

                      <th style={{ width: "145px" }}>Action</th>
                    </tr>
                  </thead>
                  <tbody className="mb-3">
                    {workOrderGroups &&
                      workOrderGroups?.map((item, index) => (
                        <tr key={index}>
                          <td
                            className="fw-medium"
                            style={{
                              maxWidth: "230px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {item.isEditing ? (
                              <input
                                type="text"
                                value={item.group_name}
                                onChange={(event) => handleChange(index, event)}
                                className="form-control"
                                name="group_name"
                              />
                            ) : (
                              item.group_name
                            )}
                          </td>

                          <td
                            className="fw-medium"
                            style={{
                              maxWidth: "145px",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <div className="hstack gap-1 flex-wrap">
                              {item.isEditing ? (
                                <>
                                  <button
                                    className="btn btn-success"
                                    onClick={() => handleSave(index)}
                                  >
                                    Update
                                  </button>
                                  <button
                                    className="btn btn-primary"
                                    onClick={() => handleCancel(index)}
                                  >
                                    Cancel
                                  </button>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-sm btn-soft-info edit-list "
                                    onClick={() => handleEdit(index)}
                                  >
                                    <i className="bx bxs-pencil fs-12 pt-1"></i>
                                  </button>
                                  <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick={() => handleDelete(index)}
                                  >
                                    <i className="ri-delete-bin-5-fill fs-12 pt-1"></i>
                                  </button>
                                  {/* <button
                                    className="btn btn-sm btn-soft-danger  "
                                    onClick="/permission"
                                  > */}

                                  {/* </button> */}
                                </>
                              )}
                            </div>
                          </td>
                        </tr>
                      ))}
                    {/* ///Adding for new rows  */}
                    {rowsData &&
                      rowsData.map((data, index) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="text"
                              value={data.grouproles}
                              onChange={(event) => handleChange(index, event)}
                              name="grouproles"
                              className="form-control"
                            />
                          </td>

                          <td>
                            <div className="hstack gap-2">
                              <button
                                className="btn btn-success"
                                onClick={() => saveTableRow(index)}
                              >
                                Save
                              </button>
                              <button
                                className="btn btn-primary"
                                onClick={() => deleteTableRows(index)}
                              >
                                Cancel
                              </button>
                            </div>
                          </td>
                        </tr>
                      ))}
                    {/* <TableInput rowsData={rowsData} deleteTableRows={deleteTableRows} handleChange={handleChange} /> */}
                  </tbody>

                  <button
                    className="btn btn-primary mt-3"
                    onClick={addTableRows}
                  >
                    +
                  </button>
                </Table>
              )}
            </CardBody>
            {isLoading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 9999,
                }}
              >
                <Loader />
              </div>
            )}
          </Col>
        </Row>
      </Card>
      <ToastContainer closeButton={false} limit={1} theme="light" />
    </div>
  );
};
