// import React, { useEffect, useState } from "react";
// import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
// import axios from 'axios';
// import { get_cookie } from "../../helpers/get_cookie";

// // import Components
// import BreadCrumb from '../../Components/Common/BreadCrumb';
// import {
//     BasicLineCharts,
// } from "./LineCharts";
// import {
//     MonthlySales,
//     MonthlyForecast,
//     StackedColumn2,
//     DistributedColumn
// } from './ColumnCharts';
// import { CustomDataLabel } from './BarCharts';
// import { Line } from './MixedCharts';
// import LeadsSource from "./LeadSource";
// import LeadsRevenue from "./LeadRevenue";
// import LeadsAverage from "./LeadAverage";
// import EmployeeSales from "./EmployeeSales";

const Dashboard = () => {
}
//     const [salesData, setSalesData] = useState([]);
//     const [forecastData, setForecastData] = useState([]);
//     const [leadsData, SetLeadsData] = useState([]);
//     const [leadsRevenueData, SetLeadsrevenueData] = useState([]);
//     const [leadsAverageData, SetLeadsaverageData] = useState([]);
//     const [employeeSalesData, SetEmployeeSalesData] = useState([]);

//     const loadDashBoardData = () => {
//         const api_token = JSON.parse(get_cookie('authUser'));
//         const headers = {
//             Authorization: `Bearer ${api_token.token}` // Assuming the token is stored in the 'token' property
//         };
//         axios.get("api/get-dashboard-data", { headers })
//             .then(res => {
//                 console.log(res);
//                 setSalesData(res.sales_data);
//                 setForecastData(res.forecast_data);
//                 SetLeadsData(res.lead_data);
//                 SetLeadsrevenueData(res.revenue_data);
//                 SetEmployeeSalesData(res.invoice_lead_data);
//                 SetLeadsaverageData(res.revenue_average_data);
//             })
//     }

//     useEffect(() => {
//         loadDashBoardData();
//     }, [])
        
//     document.title = "Dashboard | Get Rostered";
//     return (
//         <React.Fragment>
//             <div className="page-content">
//                 <Container fluid>
//                     <BreadCrumb title="Dashboard" pageTitle="Get Rostered" />
                  
//                     <Row>
//                         <Col lg={6}>
//                             <Card>
//                                 <CardHeader>
//                                     <h4 className="card-title mb-0">Monthly Sales | Based on Invoices</h4>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <MonthlySales data={salesData} dataColors='["--vz-primary", "--vz-success"]' />
//                                 </CardBody>
//                             </Card>
//                         </Col>

//                         <Col lg={6}>
//                         <Card>
//                                 <CardHeader>
//                                     <h4 className="card-title mb-0">Monthly Forecast | Based on Jobs</h4>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <MonthlyForecast data={forecastData} dataColors='["--vz-danger"]' />
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col lg={12}>
//                             <Card>
//                                 <CardHeader>
//                                     <h4 className="card-title mb-0">Opportunities by Lead Source</h4>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <LeadsSource data={leadsData}/>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                         </Row>
//                         <Row>
//                         <Col lg={12}>
//                         <Card>
//                                 <CardHeader>
//                                     <h4 className="card-title mb-0">Revenue by Lead Source</h4>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <LeadsRevenue data={leadsRevenueData}/>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                     <Row>
//                         <Col lg={12}>
//                         <Card>
//                                 <CardHeader>
//                                     <h4 className="card-title mb-0">Average Job Size by Lead Source</h4>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <LeadsAverage data={leadsAverageData}/>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                     <Row>
//                     <Col lg={12}>
//                         <Card>
//                                 <CardHeader>
//                                     <h4 className="card-title mb-0">Employee Sales</h4>
//                                 </CardHeader>
//                                 <CardBody>
//                                     <EmployeeSales data={employeeSalesData}/>
//                                 </CardBody>
//                             </Card>
//                         </Col>
//                     </Row>
//                 </Container>
//             </div>
//         </React.Fragment>
//     );
// };

export default Dashboard;
