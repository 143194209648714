import PrismCode from "../../Components/Common/Prism";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  UncontrolledAlert,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import img13 from "../../assets/images/small/img-13.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../assets/scss/pages/_settings.scss";
import DeleteModal from "../../Components/Common/DeleteModal";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../store/ecommerce/action";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../config";
import { get_cookie } from "../../helpers/get_cookie";

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Sites = () => {
  document.title = "Sites | Get Rostered";
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [modal, setModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [authUserId, setAuthUserId] = useState();

  // permissions
  const [showAddSite, setShowAddSite] = useState(false);
  const [showEditSite, setShowEditSite] = useState(false);
  const [showRemoveSite, setShowRemoveSite] = useState(false);
  const all_permissions = JSON.parse(get_cookie("permissions"));

  useEffect(() => {
    const all_permissions = JSON.parse(get_cookie("permissions"));
    const add_site = all_permissions?.includes("add_site");
    const edit_site = all_permissions?.includes("edit_site");
    const remove_site = all_permissions?.includes("remove_site");

    setShowAddSite(add_site);
    setShowEditSite(edit_site);
    setShowRemoveSite(remove_site);
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      emailAddress: "",
      salary: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();

  //react
  const isContactSuccess = true;
  const error = null;
  const [data, setData] = useState([]);
  const crmcontacts = data;

  const loadTable = (userId) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
    };
    console.log(userId, "userId");
    setisLoading(true);
    axios
      .post("/sites", formData)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  console.log(showEditSite, "showEditSite");
  console.log(showAddSite, "showAddSite");
  console.log(showRemoveSite, "showRemoveSite");

  //input field values
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_name, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [sms_number, setSmsNumber] = useState("");
  const [address, setAddress] = useState("");
  const [abn, setAbn] = useState("");
  const [payment_terms, setPaymentTerms] = useState("");
  const [customer_sign_off_checklist, setCustomerSign] = useState("");
  const [customer_pre_job_checklist, setCustomerPre] = useState("");
  const [work_order_instructions, setWorkOrderInstructions] = useState("");
  const [pod_instructions, setPodInstructions] = useState("");
  const [defaultInterest, setDefaultInterest] = useState("N");
  const [activeInterest, setActiveInterest] = useState("N");
  const [imagesel, setImage] = useState("");
  const [id, setId] = useState("");
  const [shouldShowIdField, setShouldShowIdField] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");

  const [modalOpen, setModalOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setModalOpen(true);
  };
  console.log(selectedRow, "selectedRow");
  //checkbox code
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "default") {
      setDefaultInterest(checked ? "Y" : "N");
    } else if (name === "active") {
      setActiveInterest(checked ? "Y" : "N");
    }
  };
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };

  // Date & Time Format

  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);

  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    const formData = {
      site_id: contact.id,
      user_id: authUserId,
      business_id: business_id,
    };
    axios
      .post("/delete-site", formData)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        loadTable(authUserId);
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //react
  const smscolumn = useMemo(
    () => [
      {
        Header: "Site Name",
        accessor: "site_name",
        filterable: false,
      },
      {
        Header: "Site Address",
        accessor: "site_address",
        filterable: false,
      },
      {
        Header: "Zones",
        accessor: "zones",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          console.log(cellProps);
          const site_id = cellProps.row.original.id;
          return (
            <div className="d-flex gap-3">
              <Link
                // to="/apps-job-details"
                onClick={(e) => {
                  e.preventDefault(); // Prevents the default link behavior
                  handleRowClick(cellProps.row.original);
                }}
                style={{ cursor: "pointer" }} // Moved style prop outside of onClick
                className="text-primary d-inline-block"
              >
                <i
                  className="ri-eye-fill btn btn-sm btn-soft-warning"
                  id="viewtooltip"
                ></i>
              </Link>
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>
              {all_permissions.includes("edit_site") && (
                <Link
                  to={`/editsite/general/${site_id}`}
                  onClick={() => {
                    const customerData = cellProps.row.original;
                    handleContactClick(customerData);
                  }}
                  className="text-success"
                >
                  {/* <i
                    className="mdi mdi-pencil fs-12 pt-1 btn btn-sm btn-soft-info"
                    id="edittooltip"
                  /> */}
                  <i className="bx bxs-pencil fs-12 pt-1 btn btn-sm btn-soft-info" id="edittooltip"></i>
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    Edit
                  </UncontrolledTooltip>
                </Link>
              )}
              {all_permissions.includes("remove_site") && (
                <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    onClickDelete(orderData);
                  }}
                >
                  {/* <i
                    className="mdi mdi-delete fs-12 pt-1 btn btn-sm btn-soft-danger"
                    id="deletetooltip"
                  /> */}
                  <i className="ri-delete-bin-5-fill fs-12 pt-1 btn btn-sm btn-soft-danger" id="deletetooltip"></i>
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
              )}
            </div>
          );
        },
      },
    ],
    [handleContactClick, checkedAll]
  );
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setUploadedImage(selectedFile);
      setSelectedImage(objectUrl);
      setIsImageSelected(true);
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <div className="page-content pt-0">
        <DeleteModal
          show={deleteModal}
          orderId={selectedOrderId}
          onDeleteClick={handleDeleteOrder}
          onCloseClick={() => setDeleteModal(false)}
        />
        <Col>
          <Card className="mb-0">
            <CardHeader>
              <div className="d-flex align-items-center flex-wrap gap-2">
                <div className="d-flex justify-content-between align-items-center flex-grow-1">
                  <h5 className="mb-0 text-primary">Sites</h5>
                  {all_permissions.includes("add_site") && (
                    <Link
                    to={{
                      pathname: "/business_settings/sites/addsite"
                      
                    }}
                      className="text-primary d-inline-block"
                    >
                      <Button type="button" className="btn btn-sm btn-success">
                        Add Site
                      </Button>
                    </Link>
                  )}
                </div>
                <div className="flex-shrink-0">
                  <div className="hstack text-nowrap gap-2">
                    {isMultiDeleteButton && (
                      <button
                        className="btn btn-danger"
                        onClick={() => setDeleteModalMulti(true)}
                      >
                        <i className="ri-delete-bin-2-line"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </CardHeader>
          </Card>

          <Card id="contactList">
            <CardBody className="pt-0">
              <div>
                {isContactSuccess && crmcontacts.length ? (
                  <TableContainer
                    columns={smscolumn}
                    data={crmcontacts || []}
                    isGlobalFilter={true}
                    isAddUserList={false}
                    customPageSize={100}
                    className="custom-header-css"
                    divClass="table-responsive table-card mb-3"
                    tableClass="align-middle table-nowrap"
                    theadClass="table-light"
                    handleContactClick={handleContactClicks}
                    isContactsFilter={true}
                    SearchPlaceholder="Search by site name, site address..."
                  />
                ) : (
                  crmcontacts.length === 0 && (
                    <p style={{ textAlign: "center", marginTop: "10px" }}>
                      No records found
                    </p>
                  )
                )}
              </div>
              <ToastContainer closeButton={false} limit={1} />
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </CardBody>
          </Card>
          {/* <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={modalOpen}
            toggle={toggleModal}
            centered
          >
            <ModalHeader className="pb-0">
                <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <p>
                      <b>Site Name:</b> <span>{selectedRow?.site_name}</span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <p>
                      <b>Site Address:</b>{" "}
                      <span>{selectedRow?.site_address}</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </ModalHeader>
            <hr></hr>
            <ModalBody className="pt-0">
              
              {selectedRow?.zone_data.map((zone, index) => (
                <div key={index}>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-3">
                        <p>
                          <b>Zone Name:</b> <span>{zone?.zone_name}</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className="mb-1">
                        <p>
                          <b>Zone Description:</b>{" "}
                          <span>{zone?.zone_description}</span>
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <hr className="border-dashed"></hr>
                </div>
              ))}
              <Row>
                <Col lg={6}>
                  <div className="mb-3">
                    <p>
                      <b>TimeZone:</b> <span>{selectedRow?.timezone}</span>
                    </p>
                  </div>
                </Col>
              </Row>
              <div className="col-lg-12">
                <div className="hstack gap-2 justify-content-end">
                  <Button color="danger" onClick={toggleModal}>
                    Close
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal> */}
          <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={modalOpen}
            toggle={toggleModal}
            centered
          >
            <ModalHeader className="pb-0"></ModalHeader>
            <ModalBody className="pt-0">
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <p>
                      <span>
                        <i
                          className="text-secondary fs-18 me-1 bx bx-globe"
                          style={{ verticalAlign: "middle" }}
                        ></i>
                      </span>
                      <b> Site Name:</b> <span>{selectedRow?.site_name}</span>
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <p>
                      <span>
                        <i
                          className="text-danger fs-18 me-1 bx bx-map"
                          style={{ verticalAlign: "middle" }}
                        ></i>
                      </span>
                      <b> Site Address:</b> {selectedRow?.site_address}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <p>
                      <span>
                        <i
                          className="text-warning fs-18 me-1 bx bx-time"
                          style={{ verticalAlign: "middle" }}
                        ></i>
                      </span>
                      <b> TimeZone:</b> {selectedRow?.timezone}
                    </p>
                  </div>
                </Col>
              </Row>
              {selectedRow?.zone_data && selectedRow.zone_data.length > 0 && (
                <Row>
                  <Col lg={12}>
                    <div className="mb-2">
                      <p>
                        <span>
                          <i
                            className="text-muted fs-18 me-1 bx bx-podcast"
                            style={{ verticalAlign: "middle" }}
                          ></i>
                        </span>
                        <b> Zones:</b>
                      </p>
                    </div>
                  </Col>
                  {selectedRow.zone_data.map((zone, index) => (
                    <div
                      key={index}
                      className="mb-3"
                      style={{ marginLeft: "6%" }}
                    >
                      <p className="mb-0">
                        <b>&#8226; {zone?.zone_name} - </b>{" "}
                        {zone?.zone_description}
                      </p>
                    </div>
                  ))}
                </Row>
              )}
              <div className="d-flex justify-content-end mt-0">
                <Button color="danger" onClick={toggleModal}>
                  Close
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </Col>
      </div>
    </React.Fragment>
  );
};
export default Sites;
