import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Collapse,
  Label,
  Input,
  Button,
  PopoverBody,
  UncontrolledPopover,
  Popover,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Modal,
  ModalHeader,
  ButtonGroup,
  ModalBody,
} from "reactstrap";
import classnames from "classnames";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { get_cookie } from "../../../helpers/get_cookie";
import moment from "moment";
import axios from "axios";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import userDummy from "../../../assets/images/users/user-dummy-img.jpg";
import Loader from "../../../Components/Common/Loader";
import Flatpickr from "react-flatpickr";
import { Link } from "react-router-dom";
import Cleave from "cleave.js/react";
import saveAs from "file-saver";
import img13 from "../../../assets/images/small/userimage.png";

const ExportTimesheets = () => {
  document.title = "Export Timesheets | Get Rostered";
  const authUser_id = JSON.parse(get_cookie("workauthUser")).user_id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [fullname, setfullname] = useState("");
  const [userimageurl, setuserimageurl] = useState([]);
  const [workerName, setWorkerName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [view, setView] = useState("day");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [toTime, setToTime] = useState("");
  const [toTimeError, setToTimeError] = useState("");
  const [fromTimeError, setFromTimeError] = useState("");
  const [fromTime, setFromTime] = useState("");
  const [zoneId, setZoneId] = useState("");
  const [zoneIdError, setZoneIdError] = useState("");
  const [zones, setZones] = useState([]);
  const [close_date_error, setCloseDateError] = useState("");
  const [close_date, setCloseDate] = useState(new Date());
  const [timeFormat, setTimeFormat] = useState("");
  const [memberId, setMemberId] = useState("");
  const [memberIdError, setMemberIdError] = useState("");
  const user_id = JSON.parse(get_cookie("authUser")).user_id;
  const [firstLetter, setFirstLetter] = useState("");
  const [shiftMembers, setShiftMembers] = useState([]);
  const [siteId, setSiteId] = useState({ value: "all", label: "All sites" });
  const [siteIdError, setSiteIdError] = useState("");
  const [sites, setSites] = useState([]);
  const [timesheetData, setTimesheetData] = useState([]);
  const [status, setStatus] = useState("");
  const [timesheetId, setTimesheetId] = useState("");
  const [site_id, set_site_id] = useState("");
  const [shiftId, setShiftId] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedRowIndex, setSelectedRowIndex] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [filename, setFilename] = useState([]);
  const [extentions, setExtentions] = useState("");
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  useEffect(() => {
    getTimesheetData();
  }, [siteId]);
  const productCountry = [
    {
      options: [
        { label: "CSV/Excel - All Fields", value: "CSV/Excel - All Fields" },
      ],
    },
  ];
  const getTimesheetData = () => {
    setIsLoading(true);
    const formData = {
      user_id: user_id,
      business_id: business_id,
      start_date: startDate ? startDate : moment().format("YYYY-MM-DD"),
      end_date: endDate ? endDate : moment().format("YYYY-MM-DD"),
      site_id: siteId.value,
    };
    axios
      .post("/list-sites-workers-for-export-timesheets", formData)
      .then((res) => {
        console.log(res);
        setSites(res.sites);
        setShiftMembers(res.workers);
        setTimesheetData(res.timesheets);
        setFilename(res.business_name);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const UpdateTimesheet = () => {
    setIsLoading(true);
    var formData = {
      user_id: user_id,
      timesheet_id: timesheetId,
      shift_date: close_date,
      timesheet_start_time: fromTime,
      timesheet_finish_time: toTime,
      total_breaks: timeFormat,
      site_id: site_id,
    };
    axios
      .post("/update-timesheet-table", formData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          getTimesheetData();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong while editing shift...");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  function handleMember(selectedOption) {
    setMemberId(selectedOption);
  }
  function handleSite(selectedOption) {
    setSiteId(selectedOption);
    getTimesheetData();
  }
  function handleZone(selectedOption) {
    setZoneId(selectedOption);
  }
  function onTimeFormatChange(e) {
    setTimeFormat(e.target.rawValue);
  }
  //calendar
  const flatpickrRef = useRef(null);
  const handleDateChange = (selectedDate) => {
    setCurrentDate(new Date(selectedDate));
    const date = new Date(selectedDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    let newStartDate, newEndDate;
    setSelectedDate(date);
    newStartDate = formattedDate;
    newEndDate = formattedDate;
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);
    if (flatpickrRef.current !== null) {
      flatpickrRef.current.flatpickr.close();
    }
  };
  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(new Date());
    }
  }, []);
  const handleViewChange = (newView) => {
    let newSelectedDate = new Date();
    if (newView === "day") {
      // For day view, no need to change the selected date
    } else if (newView === "week") {
      // For week view, add 7 days to the selected date
      let currentDate = new Date();
      let currentDay = currentDate.getDay();
      let diff =
        currentDate.getDate() - currentDay + (currentDay == 0 ? -6 : 1); // Adjust to Monday if current day is Sunday
      newSelectedDate = new Date(currentDate.setDate(diff));
    } else {
      // For month view, set the selected date to the first day of the next month
      newSelectedDate.setDate(1);
    }

    // Set the selected date
    setSelectedDate(newSelectedDate);
    // Calculate start and end dates based on the selected view
    let newStartDate, newEndDate;
    if (newView === "day") {
      newStartDate = moment(newSelectedDate).format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).format("YYYY-MM-DD");
    } else if (newView === "week") {
      newStartDate = moment(newSelectedDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("week").format("YYYY-MM-DD");
    } else {
      newStartDate = moment(newSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("month").format("YYYY-MM-DD");
    }

    // Call getTimesheetData with the calculated start and end dates
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);

    // Update the view state
    setView(newView);
    //getTimesheetData();
  };
  const handleNext = async () => {
    let newSelectedDate;
    if (view === "day") {
      newSelectedDate = new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000); // Next day
    } else if (view === "week") {
      newSelectedDate = new Date(
        selectedDate.getTime() + 7 * 24 * 60 * 60 * 1000
      ); // Next week
    } else if (view === "month") {
      newSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() + 1,
        1
      ); // Next month
    }
    setSelectedDate(newSelectedDate);
    updateStartAndEndDate(newSelectedDate);
  };
  const handlePrevious = async () => {
    let newSelectedDate;
    if (view === "day") {
      newSelectedDate = new Date(selectedDate.getTime() - 24 * 60 * 60 * 1000); // Previous day
    } else if (view === "week") {
      newSelectedDate = new Date(
        selectedDate.getTime() - 7 * 24 * 60 * 60 * 1000
      ); // Previous week
    } else if (view === "month") {
      newSelectedDate = new Date(
        selectedDate.getFullYear(),
        selectedDate.getMonth() - 1,
        1
      ); // Previous month
    }
    setSelectedDate(newSelectedDate);
    updateStartAndEndDate(newSelectedDate);
  };

  const updateStartAndEndDate = async (newSelectedDate) => {
    let newStartDate, newEndDate;
    if (view === "day") {
      newStartDate = moment(newSelectedDate).format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).format("YYYY-MM-DD");
    } else if (view === "week") {
      newStartDate = moment(newSelectedDate)
        .startOf("week")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("week").format("YYYY-MM-DD");
    } else if (view === "month") {
      newStartDate = moment(newSelectedDate)
        .startOf("month")
        .format("YYYY-MM-DD");
      newEndDate = moment(newSelectedDate).endOf("month").format("YYYY-MM-DD");
    }
    setStartDate(newStartDate); // Wait for the completion of setStartDate
    setEndDate(newEndDate);
  };

  useEffect(() => {
    // Ensure both startDate and endDate are not empty before sending data to the backend
    if (startDate && endDate) {
      // Call your backend function here, passing startDate and endDate
      getTimesheetData();
    }
  }, [startDate, endDate]);

  // Custom Tabs Bordered
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  // Arrow Nav tabs
  const [arrowNavTab, setarrowNavTab] = useState("1");
  const arrowNavToggle = (tab) => {
    if (arrowNavTab !== tab) {
      setarrowNavTab(tab);
    }
  };
  const handleSelectChange = (e) => {
    const selectedValue = e.target.value;
    setToTime(selectedValue); // Update state
    setToTimeError("");
  };
  const handleStartTime = (e) => {
    const selectedValue = e.target.value;
    setFromTime(selectedValue);
    setFromTimeError("");
  };
  const generateTimeOptions = () => {
    const options = [];
    for (let h = 0; h < 24; h++) {
      for (let m = 0; m < 60; m += 15) {
        const hour = h < 10 ? `0${h}` : `${h}`;
        const minute = m === 0 ? "00" : `${m}`;
        const time = `${hour}:${minute}:00`;
        options.push({ label: formatTime(time), value: time });
      }
    }
    return options;
  };
  // Function to format time as AM/PM
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const formattedHour = parseInt(hour, 10) % 12 || 12;
    const period = parseInt(hour, 10) < 12 ? "AM" : "PM";
    return `${formattedHour}:${minute} ${period}`;
  };
  const pastelColors = [
    "#fd99a8", // Plum
    "#FFA07A", // LightSalmon
    "#FFD700", // Gold
    "#90df90", // PaleGreen
    "#8bdddd", // PaleTurquoise
    "#af91e3", // Lavender
    "#f791eb", // LightPink
  ];

  const getEditData = (index, shiftId, siteId, Progress_Status) => {
    const isChecked = selectedRowIndex.find(
      (row) => row.shiftId === shiftId && row.siteId === siteId
    );

    if (isChecked) {
      // If already checked, remove it from the array
      setSelectedRowIndex(
        selectedRowIndex.filter(
          (row) => !(row.shiftId === shiftId && row.siteId === siteId)
        )
      );
    } else {
      // If not checked, add it to the array
      setSelectedRowIndex([
        ...selectedRowIndex,
        { index, shiftId, siteId, Progress_Status },
      ]);
    }
  };

  const handleSelectAll = () => {
    const allChecked = selectedRowIndex.length === timesheetData.length;

    if (allChecked) {
      setSelectedRowIndex([]);
    } else {
      const selectedRows = timesheetData.map((item, index) => ({
        index,
        shiftId: item.shift_id,
        siteId: item.site_id,
        Progress_Status: item.Progress_Status,
      }));
      setSelectedRowIndex(selectedRows);
    }
  };

  const calculateTotals = () => {
    let totalHours = 0;
    let totalPayment = 0;
    selectedRowIndex.forEach((row) => {
      const item = timesheetData[row.index];
      if (item) {
        // Ensure item is not undefined
        const hours = parseFloat(item.Hours.replace(/[^0-9.]/g, "")) || 0;
        totalHours += hours;
        if (item.Payment) {
          totalPayment += parseFloat(item.Payment.replace("$", "")) || 0;
        }
      }
    });
    return { totalChecked: selectedRowIndex.length, totalHours, totalPayment };
  };
  const exporttoExcel = async () => {
    //setIsLoading(true);
    var formData = {
      selectedRows: [],
      filename: filename,
      extentions: extentions,
    };
    selectedRowIndex.forEach((row) => {
      const item = timesheetData[row.index];
      formData.selectedRows.push(item);
    });
    const jsonData = JSON.stringify(formData);
    try {
      const response = await axios.post("export-to-excel", jsonData, {
        responseType: "json",
      });
      const excelData = response.excelData;
      console.log(excelData);
      const blob = new Blob(
        [Uint8Array.from(atob(excelData), (c) => c.charCodeAt(0))],
        {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }
      );
      saveAs(blob, filename + ".xlsx");
      setModalEdit(false);
    } catch (error) {
      console.error("Error downloading Excel:", error);
    }
  };
  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };
  function handleSelectDay(selectedDay) {
    setExtentions(selectedDay);
  }
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    /* validation */
    var formData = {
      selectedRows: [],
    };
    selectedRowIndex.forEach((row) => {
      const item = timesheetData[row.index];
      formData.selectedRows.push(item);
    });
    const jsonData = JSON.stringify(formData);
    axios
      .post("/timesheets-confirm", jsonData)
      .then((response) => {
        if (response.error === 1) {
          toast.success(response.message, { theme: "light" });
          setmodal_assignModal(false);
          getTimesheetData();
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      });
  };
  const buttonClass = (buttonView) => {
    return view === buttonView
      ? "btn btn-soft-primary selected"
      : "btn btn-ghost-primary";
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: siteIdError ? "red" : provided.borderColor,
      "&:hover": {
        borderColor: siteIdError ? "red" : provided.borderColor,
      },
      backgroundColor: "#F6F6F6",
      minHeight: "50px",
      border: "none",
      borderRadius: "40px",
      boxShadow: state.isFocused
        ? siteIdError
          ? "0 0 0 1px red"
          : "0 0 0 1px #7F4BDA"
        : provided.boxShadow,
      padding: "2px 10px",
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "#7F4BDA" : provided.backgroundColor,
      color: state.isSelected ? "white" : provided.color,
      "&:hover": {
        backgroundColor: "#e8e6f7",
        color: "black",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      paddingRight: "10px",
      padding: 0,
      color: "#7F4BDA", // Set your desired color here
      "&:hover": {
        color: "#7527CE", // Optional: change color on hover
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  const isAnyRowPaid = selectedRowIndex.every(
    (row) => row.Progress_Status === "Paid"
  );
  console.log(selectedRowIndex, "isAnyRowPaid");
  const timeOptions = generateTimeOptions();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/*<BreadCrumb title="Export Timesheets" />*/}
          <Row className="mt-3">
            <Col lg={12}>
              <Row>
                <Col md="3">
                  <Card
                    className="p-3"
                    style={{ borderRadius: "11px", boxShadow: "none" }}
                  >
                    <Row style={{ marginTop: "14px" }}>
                      <Col md="12">
                        <div className="mb-3">
                          <Select
                            value={siteId}
                            className={`${siteIdError ? "is-invalid" : ""}`}
                            onChange={(selectedOption) => {
                              handleSite(selectedOption);
                              setSiteIdError("");
                            }}
                            options={[
                              { value: "all", label: "All sites" }, // Add the "All sites" option
                              ...(sites && Array.isArray(sites)
                                ? sites.map((item) => ({
                                    value: item.id,
                                    label: item.site_name,
                                  }))
                                : []),
                            ]}
                            styles={customStyles}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {shiftMembers && Array.isArray(shiftMembers)
                          ? shiftMembers.map(
                              (
                                item,
                                index // added index argument for the key and color calculation
                              ) => (
                                <div key={index}>
                                  {" "}
                                  {/* added key attribute */}
                                  <div className="mb-2 d-flex mt-2 p-2 profile-card-cal cursor-pointer">
                                    <div
                                      className="avatar-xxs"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        height: "2.5rem",
                                        width: "2.5rem",
                                      }}
                                    >
                                      {item.s3url ? (
                                        <img
                                          src={item.s3url}
                                          alt=""
                                          style={{
                                            height: "100%",
                                            width: "100%",
                                          }}
                                          className="avatar-xxs rounded-circle"
                                        />
                                      ) : (
                                        <span
                                          className="avatar-title rounded-circle text-white"
                                          style={{
                                            padding: "4px",
                                            backgroundColor:
                                              pastelColors[
                                                index % pastelColors.length
                                              ],
                                          }}
                                        >
                                          {item.initials}
                                        </span>
                                      )}
                                    </div>
                                    <div className="ms-2">
                                      <div className="emp_name">
                                        {item.full_name}
                                      </div>
                                      <div className="emp_det text-muted">
                                        {item.timesheet_count +
                                          " " +
                                          "time sheet "}{" "}
                                        | {item.total_hours + " " + "hrs"}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )
                          : null}
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col md="9">
                  <div className="ps-0 p-3 align-items-start pb-0">
                    <div className="week-view-calendar overflow-hidden w-100">
                      <div
                        className="navigation mb-4 d-flex"
                        style={{ justifyContent: "space-between" }}
                      >
                        <div className="timesheetheaderdata">
                          <button
                            className="btn buttonstyle"
                            onClick={handlePrevious}
                          >
                            <i className="ri-arrow-left-s-line align-bottom fw-bold"></i>
                          </button>
                          <span className="btn btn" id="overviewCalendar">
                            {view === "day" &&
                              selectedDate instanceof Date &&
                              selectedDate.toDateString()}
                            {view === "week" && selectedDate && (
                              <>
                                {selectedDate.toDateString()} -{" "}
                                {new Date(
                                  selectedDate.getTime() +
                                    6 * 24 * 60 * 60 * 1000
                                ).toDateString()}
                              </>
                            )}
                            {view === "month" &&
                              selectedDate &&
                              selectedDate.toLocaleString("default", {
                                month: "long",
                              })}
                            <UncontrolledPopover
                              placement="bottom"
                              target="overviewCalendar"
                              trigger="legacy"
                            >
                              <PopoverBody>
                                <Flatpickr
                                  ref={flatpickrRef}
                                  name="unavailabilityDates"
                                  className="form-control"
                                  id="datepicker-overview-input"
                                  placeholder="Select date(s)"
                                  options={{
                                    mode: "single",
                                    enableTime: false,
                                    dateFormat: "D M d Y H:i:s \\G\\M\\TO (T)",
                                    // dateFormat: "d/m/Y", // Set the date format to dd/mm/yyyy
                                    // defaultDate: "2024-02-21",
                                    inline: true, // Use inline mode
                                    // onChange: handleDateChange,
                                    onClose: () =>
                                      console.log("Calendar closed"),
                                  }}
                                  onChange={handleDateChange}
                                  value={selectedDate || ""}
                                />
                              </PopoverBody>
                            </UncontrolledPopover>
                          </span>
                          <button
                            className="btn buttonstyle"
                            onClick={handleNext}
                          >
                            <i
                              className="ri-arrow-right-s-line align-bottom fw-bold"
                              style={{ fontSize: "large" }}
                            ></i>
                          </button>
                        </div>
                        <div>
                          <ButtonGroup
                            style={{
                              backgroundColor: "white",
                              borderRadius: "12px",
                            }}
                          >
                            <button
                              className={buttonClass("day")}
                              style={{
                                fontWeight: "bold",
                                color: "#7f4bda",
                                borderRadius: "11px",
                              }}
                              onClick={() => handleViewChange("day")}
                            >
                              Day
                            </button>
                            <button
                              className={buttonClass("week")}
                              style={{
                                fontWeight: "bold",
                                color: "#7f4bda",
                                borderRadius: "11px",
                              }}
                              onClick={() => handleViewChange("week")}
                            >
                              Week
                            </button>
                            <button
                              className={buttonClass("month")}
                              style={{
                                fontWeight: "bold",
                                color: "#7f4bda",
                                borderRadius: "11px",
                              }}
                              onClick={() => handleViewChange("month")}
                            >
                              Month
                            </button>
                          </ButtonGroup>
                          <button
                            className="btn btn-white p-2 ms-3"
                            style={{ color: "#7f4bda" }}
                            onClick={() => getTimesheetData()}
                          >
                            <i className="bx bx-refresh align-inherit fs-16"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col md={12}>
                      <p>
                        <b>Selected Items Summary</b>
                      </p>
                      <Card style={{ borderRadius: "8px" }}>
                        <Row style={{ padding: "7px 17px" }}>
                          <Col md={4} className="pt-2">
                            <b>
                              {calculateTotals().totalChecked} Items selected
                              for payments
                            </b>
                          </Col>
                          <Col
                            md={2}
                            className="pt-2"
                            style={{ fontWeight: "bold", color: "#7f4bda" }}
                          >
                            TOTAL HOURS:{" "}
                          </Col>
                          <Col
                            md={2}
                            className="pt-2"
                            style={{ fontWeight: "bold" }}
                          >
                            <p style={{ color: "red" }}>
                              {calculateTotals().totalHours} hrs
                            </p>
                          </Col>
                          <Col
                            md={2}
                            className="pt-2"
                            style={{ fontWeight: "bold", color: "#7f4bda" }}
                          >
                            TOTAL PAYMENTS:{" "}
                          </Col>
                          <Col
                            md={2}
                            className="pt-2"
                            style={{ fontWeight: "bold" }}
                          >
                            <p style={{ color: "green" }}>
                              $ {calculateTotals().totalPayment}
                            </p>
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                  <div style={{ margin: "21px 0px" }}>
                    <button
                      style={{ borderRadius: "7px", padding: "5px 30px" }}
                      className="btn btn-primary p-2"
                      onClick={() => {
                        setModalEdit(true);
                      }}
                    >
                      Export Selected Items
                    </button>
                    {/*<button
                          style={{ borderRadius: "7px", padding: "5px 30px",color:"#865ce2"}}
                          className="btn btn-soft-primary p-2 ms-3"
                          onClick={() => {
                            setmodal_assignModal(true);
                          }}
                        >
                          Mark Selected Items as Paid
                        </button>*/}
                    <button
                      style={{
                        borderRadius: "7px",
                        padding: "5px 30px",
                        color: "#865ce2",
                        cursor: isAnyRowPaid ? "not-allowed" : "pointer",
                      }}
                      className="btn btn-soft-primary p-2 ms-3"
                      onClick={() => {
                        if (!isAnyRowPaid) {
                          setmodal_assignModal(true);
                        }
                      }}
                      disabled={isAnyRowPaid}
                    >
                      Mark Selected Items as Paid
                    </button>
                  </div>
                  <div className="table-responsive">
                    <Table className="table-striped table-nowrap align-middle mb-0">
                      <thead style={{ backgroundColor: "#e7ebee" }}>
                        <tr>
                          <th scope="col" style={{ width: "46px" }}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="cardtableCheck"
                                onClick={handleSelectAll}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="cardtableCheck"
                              ></label>
                            </div>
                          </th>
                          <th scope="col">Name</th>
                          <th scope="col">Date</th>
                          <th scope="col">Progress</th>
                          <th scope="col">Zone</th>
                          <th scope="col">Time</th>
                          <th scope="col">Hours</th>
                          <th scope="col">Payments</th>
                        </tr>
                      </thead>
                      {timesheetData && timesheetData.length > 0 ? (
                        timesheetData.map((item, index) => (
                          <tbody key={item.id}>
                            <tr style={{ backgroundColor: "#e8e6f7" }}>
                              <td>
                                <div className="form-check">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id={`cardtableCheck${index + 1}`}
                                    onClick={() =>
                                      getEditData(
                                        index,
                                        item.shift_id,
                                        item.site_id,
                                        item.Progress_Status
                                      )
                                    }
                                    checked={selectedRowIndex.some(
                                      (row) => row.index === index
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`cardtableCheck${index + 1}`}
                                  ></label>
                                </div>
                              </td>
                              <td>{item.full_name}</td>
                              <td>{item.Date}</td>
                              <td>{item.Progress_Status}</td>
                              <td>{item.Zone}</td>
                              <td>
                                {item.Time}{" "}
                                {item.breaks ? `| ${item.breaks}` : ""}
                              </td>
                              <td>{item.Hours}</td>
                              <td>{item.Payment}</td>
                            </tr>
                          </tbody>
                        ))
                      ) : (
                        <tbody>
                          <tr>
                            <td colSpan="8" style={{ textAlign: "center" }}>
                              No data found
                            </td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                </Col>
              </Row>
              <ToastContainer closeButton={false} limit={1} theme="light" />
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}

              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalEdit}
                toggle={toggleModalEdit}
                centered
              >
                <ModalHeader toggle={toggleModalEdit}>
                  Export Selected Items
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label for="leadStatusinput" className="form-label">
                          Please select the payroll application to export to
                        </Label>
                        <Select
                          value={productCountry[0].options.find(
                            (option) => option.value === extentions
                          )}
                          onChange={(selectedOption) => {
                            handleSelectDay(selectedOption.value); // Pass the selected value to the function
                          }}
                          options={productCountry[0].options} // Extract the options array from the nested structure
                          id="day"
                        ></Select>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label for="leadStatusinput" className="form-label">
                          Please enter the export file name
                        </Label>
                        <Input
                          type="text"
                          value={filename}
                          onChange={(e) => {
                            setFilename(e.target.value);
                          }}
                          className="form-control"
                          id="firstnameInput"
                          placeholder="Enter file name"
                        />
                      </div>
                    </Col>

                    <Col lg={12}>
                      <Row>
                        <div className="hstack gap-2 justify-content-end">
                          <Col
                            lg={6}
                            className="d-flex justify-content-end gap-2"
                          >
                            <button
                              className="btn btn-link link-success text-decoration-none fw-medium"
                              data-bs-dismiss="modal"
                              onClick={toggleModalEdit}
                            >
                              <i className="ri-close-line me-1 align-middle"></i>{" "}
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={exporttoExcel}
                            >
                              <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                              Export
                            </button>
                          </Col>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modal_assignModal}
                toggle={tog_assignModal}
                centered
              >
                <ModalHeader toggle={tog_assignModal}>
                  Mark Selected Items as Paid
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <hr />
                    <Col md={12}>
                      <h6>
                        Are you sure you want to mark the selected items as
                        paid?
                      </h6>
                      <div className="mb-3">
                        <h5 style={{ fontWeight: "bold" }}>
                          Paid timesheets cannot be edited later.
                        </h5>
                      </div>
                    </Col>
                    <hr />
                    <Col lg={12}>
                      <Row>
                        <div className="hstack gap-2 justify-content-end">
                          <Col
                            lg={6}
                            className="d-flex justify-content-end gap-2"
                          >
                            <button
                              className="btn btn-link link-success text-decoration-none fw-medium"
                              data-bs-dismiss="modal"
                              onClick={tog_assignModal}
                            >
                              <i className="ri-close-line me-1 align-middle"></i>{" "}
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                              Ok
                            </button>
                          </Col>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ExportTimesheets;
