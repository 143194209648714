import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = ({business_name}) => {

  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid>
          <div className="text-center">{business_name}</div>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
