import React, { useState, useEffect } from 'react';
import { Col, Input, Label, Row, CardHeader, CardBody, Accordion, AccordionItem, Collapse, Card, Container, Table, ModalBody, Modal } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import axios from 'axios';
import moment from "moment";
import { api } from "../../config";

// import Components
import BreadCrumb from '../../Components/Common/BreadCrumb';


const VehicleCheck = () => {
    const [col1, setcol1] = useState(false);
    const t_col1 = () => {
        setcol1(!col1);

    };
    const [showModal, setShowModal] = useState(false)
    const [createdDateEnd, setCreatedDateEnd] = useState();
    const [pageTitle, setpageTitle] = useState([]);
    const [company, setCompany] = useState('')
    const [createdDateStart, setCreatedDateStart] = useState();
    const [vechicle, setVechicle] = useState('');
    const [vechicleid, setVechicleId] = useState('');
    const [report, setReport] = useState([]);
    const [data, setData] = useState('')
    const [viewTables, setViewTables] = useState([]);
    const [org_date, setOrg_Date] = useState([]);



    document.title = "Daily Vehicle Check | Get Rostered";
    useEffect(() => {
        loaddata();
    }, []);

    // useEffect(() => {
    //     //end date
    //     setCreatedDateEnd(moment().format("DD/MM/YYYY"));

    //     //start date
    //     setCreatedDateStart(moment().subtract(1, 'months').format('DD/MM/YYYY'));

    // }, []);



    const loaddata = () => {
        axios
            .get("/api/crm-daily-vehicle-check")
            .then((res) => {
                console.log(res, "com12");
                setCompany(res.companies)
                setpageTitle(res.pageTitle);
                setVechicle(res.vehicles);
                setOrg_Date(res?.organisation_settings);
                //start date
                setCreatedDateStart(moment().subtract(1, 'months').format(res.organisation_settings?.date_format_js));
                //end date
                setCreatedDateEnd(moment().format(res.organisation_settings?.date_format_js));
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const applyFilter = (event) => {
        event.preventDefault();
        const selectedDateFormat = org_date?.date_format;
        const filterData = {
            'vehicle_id': vechicleid,
            'created_date_start': createdDateStart,
            'created_date_end': createdDateEnd,
            'selected_date_format': selectedDateFormat
        }

        console.log(filterData, "data")
        // let arr = [];
        axios.post("api/daily-vehicle-checklist-get-data", filterData)
            .then(res => {
                console.log(res, "res");
                setReport(res.reports)
                t_col1();
                // arr.push(res);
                // console.log(arr)
                // setData(arr);

            })
    }
    const reset = () => {
        loaddata();
        setCreatedDateEnd(moment().format(org_date.date_format_js));
        setCreatedDateStart(moment().subtract(1, 'months').format(org_date.date_format_js));
    }
    const [groups, setGroups] = useState([]);
    const [finalData, setFinalData] = useState([]);
    function popupdata(id) {
        var data = {
            "daily_driver_vehicle_check_id": id
        }
        console.log("Popup data for ID:", id);
        axios.post("api/crm-daily-check-popup-data", data)
            .then(res => {
                console.log(res, "res");
                setShowModal(true);
                setData(res.data)
                setViewTables(res.viewTables);
                setGroups(res.groups);
                setFinalData(res.details)
                // setReport(res.reports)
                // arr.push(res);
                // console.log(arr)
                // setData(arr);

            })

    }
    // console.log(org_date?.date_format_js,"org_date?.date_format_js");
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={pageTitle} pageTitle="Get Rostered" />
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                    <Accordion id="default-accordion-example">
                                        <AccordionItem>
                                            <h2 className="accordion-header" id="headingOne">
                                                <button
                                                    className={classnames("accordion-button fw-semibold", { collapsed: !col1 })} type="button" onClick={t_col1} style={{ cursor: "pointer" }} >
                                                    Search Filters
                                                </button>
                                            </h2>
                                            <Collapse isOpen={col1} className="accordion-collapse" id="collapseOne" >
                                                <div className="accordion-body">

                                                    <Row>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">Created Date</Label>
                                                                <Flatpickr
                                                                    className="form-control" placeholder='Start Date'
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    value={createdDateStart}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateStart(moment(selectedDates[0]).format(org_date?.date_format_js));
                                                                        } else {
                                                                            setCreatedDateStart(null);
                                                                        }
                                                                    }}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={2}>
                                                            <div className="mb-3">
                                                                <Label for="createdDateinput" className="form-label">
                                                                    End Date
                                                                </Label>
                                                                <Flatpickr
                                                                    className="form-control"
                                                                    value={createdDateEnd}
                                                                    options={{
                                                                        dateFormat: org_date?.date_format,
                                                                    }}
                                                                    onChange={(selectedDates) => {
                                                                        if (selectedDates.length === 1) {
                                                                            setCreatedDateEnd(moment(selectedDates[0]).format(org_date?.date_format_js));
                                                                        } else {
                                                                            setCreatedDateEnd(null);
                                                                        }
                                                                    }} />
                                                            </div>
                                                        </Col>
                                                        <Col lg={3}>
                                                            <Label for="Sortingorder" className="form-label">Vehicle</Label>
                                                            <select className="form-select mb-3"

                                                                value={vechicleid}
                                                                onChange={(e) => setVechicleId(e.target.value)}
                                                            >
                                                                <option value="">None Selected</option>
                                                                {vechicle && vechicle.length > 0 ? (
                                                                    vechicle.map((role, index) => (
                                                                        <option key={index} value={role.id}>{role.vehicle_name}- - {role.license_plate_number}
                                                                        </option>
                                                                    ))
                                                                ) : (
                                                                    <option value="">None Selected</option>
                                                                )}
                                                            </select>
                                                        </Col>


                                                        <Col lg={5}>
                                                            <div className="text-end">
                                                                <button type="submit" className="btn form-btn-marg btn-primary" onClick={applyFilter}>Apply</button>
                                                                <button type="submit" className="btn form-btn-marg btn-primary" onClick={reset}>Reset</button>
                                                            </div>
                                                        </Col>

                                                    </Row>
                                                </div>
                                            </Collapse>
                                        </AccordionItem>
                                    </Accordion>
                                </CardHeader>
                                <CardBody>

                                    <Table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Vehicle</th>
                                                <th>Driver</th>
                                                <th>Report</th>

                                            </tr>
                                        </thead>
                                        <tbody>
                                            {report && report.length > 0 ? (
                                                report.map((value) => (
                                                    <tr key={value.id}>
                                                        <td>{value.date}</td>
                                                        <td>{value.vehicle}</td>
                                                        <td>{value.driver}</td>
                                                        <td>
                                                            <a
                                                                className="showReport"
                                                                data-dailycheckid={value.id}
                                                                href="javascript:;"
                                                                onClick={() => popupdata(value.id)}>
                                                                {value.report}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4"><center>No data found</center></td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <Modal id='showModal' className='modal-dialog modal-lg' isOpen={showModal} toggle={() => setShowModal(false)}>
                    <ModalBody className=''>
                        <div className='d-flex align-items-center justify-content-between'>
                            <img src={api.WEB_URL + "user-uploads/company-logo/" + company?.logo} />
                            <button
                                type='button'
                                className='btn btn-light'
                                data-bs-dismiss='modal'
                                onClick={() => setShowModal(false)}>
                                <i className='ri-close-line'></i>
                            </button>
                        </div>
                        <div className='row mt-3'>
                            <h3 className='text-center pb-3'>Daily Vehicle Check</h3>
                            <div className='col-lg-6'>
                                <Table className='border-none'>
                                    <tbody>
                                        <tr>
                                            <td><b>Date:</b></td>
                                            <td>{data.date}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Driver:</b></td>
                                            <td>{data.driver}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Start Odometer:</b></td>
                                            <td>{data.odometer}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className='col-lg-6'>
                                <Table className='border-none'>
                                    <tbody>
                                        <tr>
                                            <td><b>Time:</b></td>
                                            <td>{data.time}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Vehicle:</b></td>
                                            <td>{data.vehicle}</td>
                                        </tr>
                                        <tr>
                                            <td><b>Fuel Percent:</b></td>
                                            <td>{data.fuel}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                            <div className='p-3'>
                                <table>
                                    <thead>
                                        <th></th>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><b>Notes: </b></td>
                                        </tr>
                                        {data.notes ? (
                                            <tr>
                                                <td id="notes">{data.notes}</td>
                                            </tr>
                                        ) : (
                                            <tr>
                                                <td id="notes">No notes here today!</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr />
                        {/* <div id="viewTables">
        {viewTables.map((value, index) => (
          <div key={index} dangerouslySetInnerHTML={{ __html: value }} className='p-3' />
          ))}
      </div> */}





                        <div className='p-3'>
                            {groups?.map((group) => (
                                <div key={group.id}>
                                    <h4>{group.checklist_group}</h4>
                                    <Table className='mt-3'>
                                        <thead style={{ background: "whitesmoke", borderTop: "1px solid #d9d9d9" }}>

                                            <tr>
                                                <th style={{ color: "#666", width: "70%" }}>Check</th>

                                                <th style={{ color: "#666", width: "70%" }}>Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {finalData.map((data) => {
                                                if (data.checklist_group === group.checklist_group) {
                                                    return (
                                                        <tr key={data.id}>
                                                            <td>{data.checklist}</td>
                                                            <td>{data.status}</td>
                                                        </tr>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        </React.Fragment>

    );
};

export default VehicleCheck;
