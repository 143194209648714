import React, { useState,useEffect} from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useNavigate } from "react-router-dom";
//import images
import logoLight from "../../assets/images/bg-auth.jpg";
import logoRoster from "../../assets/images/logopurple.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { add_cookie, add_permissions_cookie } from "../../helpers/get_cookie";
const BusinessList = () => {
  document.title = "Business List | Get Rostered Business";
  const location = useLocation();
  const response = location.state.formData;
  const isChecked = location.state.isChecked;
  const [isResend, setIsResend] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [email, setEmail] = useState("");
  const [isInCorrect, setIsInCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  // You can get the email from the previous screen or wherever it is stored
  const navigate = useNavigate();
  console.log(location.state.formData, "location.state.formData");
  const [isErr, setIsErr] = useState(false);
  const [data, setData] = useState("");
  useEffect(() => {
    getBusinessList();
  }, [response.user_id]);

  const getBusinessList = () => {
    const Bearer_token = response.business_access_token;
    const headers = {
        Authorization: `Bearer ${Bearer_token}`,
      };
    const formData = {
      user_id: response.user_id,
    };
    axios
      .post("/get-business-list", formData,{ headers })
      .then((res) => {
        console.log(res);
        setData(res.business_list);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  
  const handleSubmit = async (business, e) => {
    setIsLoading(true);
    e?.preventDefault();
    console.log(business,"business_data");
    response.business_id = business.id;
    response.profile_status = business.profile_setup_completed;
    // Axios request
    try {
      const Bearer_token = response.business_access_token;
      // Set the bearer token in the headers for the subsequent request
      const headers = {
        Authorization: `Bearer ${Bearer_token}`,
      };
      const payload = {
        user_id: response?.user_id,
        business_id : response?.business_id,
      };
        const rolesAndPermissionsRes = await axios.post(
          "/get-roles-and-permissions",
          payload,
          { headers }
        );
        const permissions = rolesAndPermissionsRes.roles_and_permissions
          .map((role) => role.permission_names)
          .flat();
        // Adding permissions to cookie
        if (rolesAndPermissionsRes) {
          add_permissions_cookie(permissions);
        }
        add_cookie(response, isChecked);
        {
          permissions?.includes("view_rosters")
            ? navigate("/roster_lounge")
            : navigate("/business_settings");
        }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container className="mt-3">
                            <Row>
                                <Col xxl={12}>
                                    <Card style={{padding:"30px",borderRadius:"22px",backgroundColor:"#f2f2f2",marginTop:"4rem",boxShadow:"0 1px 2px #cabae5"}}>
                                        <Row className="g-0">
                                            <Col md={6} style={{padding:"14px"}}>
                                                <CardHeader style={{borderBottom:"none",textAlign:"center",backgroundColor:"#f2f2f2"}}><img src={logoRoster} alt="" height="40" /></CardHeader>
                                                <CardBody style={{fontSize:"14px"}}>
                                                    <p className="card-text mb-2">Welcome back! It looks like you're associated with multiple business accounts. Please select the account you wish to access from the list below. This will ensure you are directed to the appropriate portal and can manage the specific business effectively.
                                                    </p>
                                                    <p className="card-text mb-2" style={{fontWeight:"bold"}}>Instructions:</p>
                                                    <p className="card-text mb-2">1. Review the list of business accounts associated with your profile.</p>
                                                    <p className="card-text mb-2">2. Click on the business account you want to manage.</p>
                                                    <p className="card-text mb-2">3. You will be redirected to the dashboard for the selected business.</p>
                                                </CardBody>
                                            </Col>
                                            <Col md={6} style={{padding:"14px",alignContent:"end"}}>
                                              {data && data.map((business, index) => (
                                                  <Card key={index} style={{ cursor: "pointer", boxShadow:"0 1px 2px #cabae5", transition: "transform 0.2s" }}
      onMouseEnter={(e) => (e.currentTarget.style.transform = "scale(1.03)")}
      onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}>
                                                    <CardBody>
                                                      <Row className="g-0" onClick={() => handleSubmit(business)}>
                                                        <Col md={2}>
                                                          <img
                                                            className="rounded-start img-fluid h-100 object-cover"
                                                            src={business.s3_logo_url}
                                                            alt={`${business.name} logo`}
                                                          />
                                                        </Col>
                                                        <Col md={10}>
                                                        <div className="mt-2">
                                                          <p
                                                            className="card-text mb-2"
                                                            style={{
                                                              fontWeight: "bold",
                                                              padding: "13px 13px 13px 26px",
                                                              fontSize: "large",
                                                            }}
                                                          >
                                                            {business.business_name}
                                                          </p>
                                                          </div>
                                                        </Col>
                                                      </Row>
                                                    </CardBody>
                                                  </Card>
                                              ))}
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
              <ToastContainer />
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default BusinessList;
