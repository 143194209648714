import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Spinner,
} from "reactstrap";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useNavigate } from "react-router-dom";
//import images
import logoLight from "../../assets/images/logo-light.png";
import logoRoster from "../../assets/images/logopurple.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useLocation } from "react-router-dom";
const BasicTwosVerify = () => {
  document.title = "OTP Verification | Get Rostered Business";
  const location = useLocation();
  console.log(location.state.formData, "location.state.formData");
  const emailFromRegistration = location.state.formData.email;
  const [isResend, setIsResend] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [email, setEmail] = useState("");
  const [isInCorrect, setIsInCorrect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  // You can get the email from the previous screen or wherever it is stored
  const navigate = useNavigate();

  const getInputElement = (index) => {
    return document.getElementById("digit" + index + "-input");
  };

  const moveToNext = (index) => {
    if (getInputElement(index).value.length === 1) {
      if (index !== 6) {
        getInputElement(index + 1).focus();
      } else {
        getInputElement(index).blur();
        // Submit code
        console.log("submit code");
      }
    }
  };
  const handleKeyUp = (index, value) => {
    // Update the OTP values based on the input
    const newOtpValues = [...otpValues];
    newOtpValues[index] = value;
    setOtpValues(newOtpValues);

    // Move focus to the next input field when a digit is entered
    if (value && index < otpValues.length - 1) {
      const nextInput = document.getElementById(`digit${index + 2}-input`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handleConfirm = async () => {
    // setIsLoading(true);
    const verificationCode = otpValues.join("");
    console.log(otp, "otp value");
    // Make the API call
    setIsButtonClicked(true);
    try {
      await axios
        .post("/confirm-user", {
          email: emailFromRegistration,
          verification_code: verificationCode,
          first_name: location.state.formData.first_name,
          last_name: location.state.formData.last_name,
          password: location.state.formData.password,
          country_name: location.state.formData.country_name,
          post_code: location.state.formData.post_code,
          state_code: location.state.formData.state_code,
          suburb: location.state.formData.suburb,
          site_address: location.state.formData.site_address,
          site_name: location.state.formData.site_name,
          latitude: location.state.formData.latitude,
          longitude: location.state.formData.longitude,
          business_name: location.state.formData.business_name,
        })
        .then((response) => {
          if (response.status === "success") {
            toast.success(
              "Registration Successful. Redirecting to login page..",
              { autoClose: 2000 }
            );
            setTimeout(() => {
              navigate("/login");
            }, 3000);
            //setIsLoading(false);
            setIsButtonClicked(false);
          } else {
            setIsInCorrect(true);
            //setIsLoading(false);
            setIsButtonClicked(false);
          }
        });
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Error confirming user:", error);
      //setIsLoading(false);
      setIsButtonClicked(false);
    }
  };
  return (
    <React.Fragment>
      <div className="auth-page-wrapper">
        <ParticlesAuth>
          <div className="auth-page-content">
            <Container className="mt-3">
              <Row>
                <Col lg={12}>
                  <div className="text-center mt-sm-4 mb-0 text-white-50">
                    <div>
                      <Link
                        to="/dashboard"
                        className="d-inline-block auth-logo"
                      >
                        <img src={logoRoster} alt="" height="40" />
                      </Link>
                    </div>
                    {/* <p className="mt-3 fs-15 fw-medium"></p> */}
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  <Card className="">
                    <CardBody className="p-4">
                      <div className="mb-4">
                        {/* <div className="avatar-lg mx-auto">
                                                    <div className="avatar-title bg-light text-primary display-5 rounded-circle"> */}
                        {/* <i className="ri-mail-line"></i> */}
                        <div className="avatar-lg mx-auto">
                          <div className="bg-white text-primary display-5 rounded-circle">
                            <lord-icon
                              src="https://cdn.lordicon.com/aycieyht.json"
                              trigger="loop"
                              delay="1000"
                              colors="primary:#865ce2,secondary:#e8308c,tertiary:#ee66aa,quaternary:#3a3347"
                              style={{ width: "100px", height: "100px" }}
                            ></lord-icon>
                          </div>
                        </div>
                      </div>

                      <div className="p-2 mt-4">
                        <div className="text-muted text-center mb-4 mx-lg-3">
                          {!isResend ? (
                            <>
                              <h4 className="">Thank you for registering!</h4>
                              <p className="mt-2">
                                {" "}
                                We've sent a One-Time Password (OTP) to your
                                email.
                                <br />
                                Please check both your Inbox and Spam folders
                                for the OTP.
                              </p>
                            </>
                          ) : (
                            <>
                              <h4 className="">
                                OTP has been Resent to your email
                              </h4>
                              <p className="mt-2">
                                Please check your email's Inbox and Spam
                                folders.
                              </p>
                            </>
                          )}
                        </div>

                        <form>
                          <Row>
                            {[1, 2, 3, 4, 5, 6].map((index) => (
                              <Col className="col-2" key={index}>
                                <div className="mb-3">
                                  <label
                                    htmlFor={`digit${index}-input`}
                                    className="visually-hidden"
                                  >
                                    Digit {index}
                                  </label>
                                  <input
                                    type="text"
                                    value={otpValues[index - 1]}
                                    className="form-control bg-light border-light text-center"
                                    maxLength="1"
                                    id={`digit${index}-input`}
                                    onChange={(e) =>
                                      handleKeyUp(index - 1, e.target.value)
                                    }
                                    // onKeyUp={(e) => handleKeyUp(index - 1, e.target.value)}
                                  />
                                </div>
                              </Col>
                            ))}
                          </Row>
                          {/* <Row>
                                                        <OtpInput
                                                            value={otp}
                                                            onChange={setOtp}
                                                            numInputs={6}
                                                            style={{ width: "3em", height: "3em" }}
                                                            renderSeparator={<span>-</span>}
                                                            renderInput={(props) => <input {...props} />}
                                                        />
                                                    </Row> */}
                        </form>
                        {isInCorrect && (
                          <div className="text-danger">
                            The OTP you entered is incorrect. Please enter the
                            correct OTP.
                          </div>
                        )}

                        <div className="mt-3">
                          <button
                            //className="w-100 btn text-white btn-signup"
                            className={`${isButtonClicked ? "w-100 btn text-white btn-signup" : "w-100 btn text-white btn-signup"}`}
                            //disabled={isLoading ? true : false}
                            disabled={isButtonClicked}
                            onClick={handleConfirm}
                          >
                            {isButtonClicked ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Confirm
                          </button>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <div className="mt-4 d-flex justify-content-between ">
                    <Link to="/register">Back to Register</Link>
                    <t className="mb-0   ">
                      Didn't receive a code? &nbsp;
                      <span
                        className="fw-semibold text-info text-decoration-underline"
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          axios
                            .post("/resend-otp", {
                              email: emailFromRegistration,
                            })
                            .then((res) => {
                              console.log(res);
                              if (res.status === "success") {
                                toast.success(res.message, { autoClose: 2000 });
                                setIsResend(true);
                              } else if (res.status === "error") {
                                toast.error(res.message, { autoClose: 2000 });
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }}
                      >
                        Resend
                      </span>
                    </t>
                  </div>
                </Col>
              </Row>
              <ToastContainer />
            </Container>
          </div>
        </ParticlesAuth>
      </div>
    </React.Fragment>
  );
};

export default BasicTwosVerify;
