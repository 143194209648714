import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// Import Images
import error403cover from "../../../assets/images/403-page.png";

const Cover403 = () => {
  document.title = "403 Error | Get Rostered";
  return (
    <React.Fragment>
      <div className="auth-page-content">
        <div className="auth-page-wrapper py-5 d-flex justify-content-center align-items-center min-vh-100 bg-white">
          <div className="auth-page-content overflow-hidden p-0">
            <Container>
              <Row className="justify-content-center">
                <Col xl={7} lg={8}>
                  <div className="text-center">
                    <img
                      src={error403cover}
                      alt="error img"
                      className="img-fluid"
                    />
                    <div className="mt-3">
                      <h3 className="text-uppercase">
                        Access Denied
                      </h3>
                      <p className="text-muted mb-2">You don't have permission to access this page.</p>

                      {/* <p>
                        Here are some possible reasons why you might not have
                        access:
                      </p>
                      <ul>
                        <li>
                          This page may be restricted to certain user roles.
                        </li>
                        <li>
                          Your account may not be authorized to view this
                          content.
                        </li>
                      </ul> */}

                      <p className="text-muted mb-4">
                        If you believe you should have access, please contact
                        your system administrator.
                      </p>
                      <Link to="/" className="btn btn-primary">
                        <i className="mdi mdi-home me-1"></i>Go to Home Page
                      </Link>
                      
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Cover403;
