import React, { createContext, useContext, useState, useEffect } from 'react';
import Trialovermodal from './pages/Trialendmodal';
import PaymentFailed from './pages/PymentFailedmodal';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalType, setModalType] = useState(null);
  const [restrictionCheckEnabled, setRestrictionCheckEnabled] = useState(true);

  useEffect(() => {
    if (restrictionCheckEnabled) {
      const savedModalType = localStorage.getItem('errorCode');
      if (savedModalType) {
        setModalType(savedModalType);
      }
    } else {
      setModalType(null);
      localStorage.removeItem('errorCode');
    }
  }, [restrictionCheckEnabled]);

  const showModal = (type) => {
    if (restrictionCheckEnabled) {
      setModalType(type);
      localStorage.setItem('errorCode', type);
    }
  };

  const hideModal = () => {
    setModalType(null);
    localStorage.removeItem('errorCode');
  };

  const toggleModal = () => {
    setModalType(null);
    localStorage.removeItem('errorCode');
  };

  const enableRestrictionCheck = () => setRestrictionCheckEnabled(true);
  const disableRestrictionCheck = () => setRestrictionCheckEnabled(false);

  return (
    <ModalContext.Provider value={{ showModal, hideModal, toggleModal, enableRestrictionCheck, disableRestrictionCheck }}>
      {children}
      {modalType === 'TRIAL_OVER' && <Trialovermodal isOpen={true} toggle={toggleModal} hide={hideModal} />}
      {modalType === 'PAYMENT_FAILED' && <PaymentFailed isOpen={true} toggle={toggleModal} />}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
