import PrismCode from "../../Components/Common/Prism";
import {
  Card,
  ButtonGroup,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  UncontrolledAlert,
  Accordion,
  AccordionItem,
  Collapse,
  UncontrolledButtonDropdown,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import img13 from "../../assets/images/small/img-13.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../../assets/scss/pages/_settings.scss";
import ReactTable from "react-table";
import DeleteModal from "../../Components/Common/DeleteModal";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import CustomCopyModal from "../../Components/Common/CustomCopyModal";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import TableContainer from "../../Components/Common/TableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import {
  getOrders as onGetOrders,
  addNewOrder as onAddNewOrder,
  updateOrder as onUpdateOrder,
  deleteOrder as onDeleteOrder,
} from "../../store/ecommerce/action";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../config";
import da from "date-fns/esm/locale/da/index.js";
import classnames from "classnames";
import Flatpickr from "react-flatpickr";
import { format } from "date-fns";
import GoogleAutocomplete from "react-google-autocomplete";
import Select from "react-select";
import MyProfile from "../SearchWorkforce/ProfileView";
import { get_cookie } from "../../helpers/get_cookie";
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const JobApplications = () => {
  document.title = "Job Applications | Get Rostered";
  const [modal, setModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [customHoverTab, setcustomHoverTab] = useState("1");
  const [authUserId, setAuthUserId] = useState();
  const [modalEdit, setModalEdit] = useState(false);
  const [defaultstatusset, setdefaultstatusset] = useState([]);
  const [job_status_id, setJobStatusId] = useState("");
  const [sys_skills, setSysSkills] = useState("");
  const [skill_id, setSkillId] = useState("");
  const [skillIdError, setSkillIdError] = useState("");
  const [sys_status, setstatussys] = useState([]);
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [isTalentButton, setTalentButton] = useState(false);
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const toggleModalEdit = () => {
    setModalEdit(!modalEdit);
  };
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
        loadTable(res.id, "", "1");
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  function handleSelectStatus(selectedStatus) {
    setJobStatusId(selectedStatus);
  }

  const [col1, setcol1] = useState(false);
  const t_col1 = () => {
    setcol1(!col1);
  };
  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  const [rowsData, setRowsData] = useState([]);
  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      emailAddress: "",
      salary: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const [selectedstatus, setselectedstatus] = useState(null);

  const $status_array = {
    Open: "Open",
    Closed: "Closed",
    Filled: "Filled",
    OnHold: "OnHold",
    Draft: "Draft",
  };
  const StatusOption = Object.keys($status_array).map((status) => ({
    value: status,
    label: $status_array[status],
  }));

  const [contact, setContact] = useState([]);
  const dispatch = useDispatch();
  //react
  const isContactSuccess = true;
  const error = null;
  const [data, setData] = useState([]);
  const crmcontacts = data;

  const loadTable = (userId, value = "", customHoverTab = "") => {
    const statusIds = selectedStatus
      ? selectedStatus.map((status) => status.value)
      : [];

    let customhover = "";

    if (customHoverTab == 1) {
      customhover = "Active";
    } else if (customHoverTab == 2) {
      customhover = "Closed";
    }
    const formData = {
      user_id: userId,
      business_id: business_id,
      status_id: statusIds,
      status: customhover, // pass the customHoverTab value
    };
    setisLoading(true);
    axios
      .post("/job-applications-list", formData)
      .then((response) => {
        setData(response.jobApplications); // Updated to access correct path
        if (value !== "search") {
          setstatussys(response.job_application_status); // Updated to access correct path
          setSysSkills(response.job_application_status); // Updated to access correct path
        }
        setdefaultstatusset(response.data.defaultstatusset.default_status_set); // Updated to access correct path
        setSysSkills(response.sys_skills); // Updated to access correct path
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const [selectedStatus, setSelectedStatus] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    if (sys_status && sys_status.length > 0) {
      const defaultValues = sys_status
        .filter(
          (status) => status.id === 1 || status.id === 2 || status.id === 3
        )
        .map((status) => ({ value: status.id, label: status.status_name }));
      setSelectedStatus(defaultValues);

      // Update statusOptions based on all.statussys
      const dynamicStatusOptions = sys_status.map((status) => ({
        value: status.id,
        label: status.status_name,
      }));
      setStatusOptions(dynamicStatusOptions);
    }
  }, [sys_status]);

  const handleStatusMulti = (selectedOptions) => {
    setSelectedStatus(selectedOptions);
  };

  const handleSearch = () => {
    console.log("Search button clicked!");
    // Handle the search functionality, using selectedStatus
    console.log("Selected status for search:", selectedStatus);
    loadTable(authUserId, "search", customHoverTab);
  };

  const handleReset = () => {
    // Set default values or clear selected options based on your requirements
    const defaultValues = sys_status
      .filter((status) => status.id === 1 || status.id === 2 || status.id === 3)
      .map((status) => ({ value: status.id, label: status.status_name }));
    setSelectedStatus([]);
  };

  const skillOptions =
    sys_skills &&
    sys_skills?.map((skill) => ({
      value: skill.id,
      label: skill.name,
    }));

  const handleChangeEdit = (selectedOption) => {
    setSkillId(selectedOption);
    setSkillIdError("");
  };
  const toggleDefaultCanvas = () => {
    setOpen(!open);
  };

  console.log(sys_status, "sys_statussys_statussys_status");
  //input field values
  const [company_name, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [contact_name, setContactName] = useState("");
  const [phone, setPhone] = useState("");
  const [sms_number, setSmsNumber] = useState("");
  const [abn, setAbn] = useState("");
  const [id, setId] = useState("");
  const [shouldShowIdField, setShouldShowIdField] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [openProfileId, setOpenProfileId] = useState();
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  const [isEdit, setIsEdit] = useState(false);
  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct
  const [deleteModal, setDeleteModal] = useState(false);

  const [modal_large, setModal_Company] = useState(false);
  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    //setIsEdit(false);
    toggle();
  };
  // Date & Time Format
  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };

  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);
  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
      loadTable(authUserId, "", tab); // Pass the tab value here
    }
  };

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var orderIds = selectedOrderId;
    axios
      .get(`${"api/companies/destroy/"}${orderIds}`)
      .then((response) => {
        toast.success(response.message, { theme: "light" });
        loadTable();
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setModalOpen(true);
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  //react
  const jobapplicationcolumn = useMemo(
    () => [
      {
        Header: "Applicant",
        accessor: "full_name",
        Cell: ({ row }) => (
          <span
            className="cursor-pointer"
            onClick={() => {
              toggleDefaultCanvas();
              setOpenProfileId(row.original.id);
            }}
          >
            {row.original.full_name}
          </span>
        ),
        filterable: false,
      },
      {
        Header: "Job Vacancy Title",
        accessor: "Title",
        Cell: ({ row }) => (
          <div
            onClick={() => handleRowClick(row.original)}
            style={{ cursor: "pointer" }}
          >
            {row.original.title}
          </div>
        ),
        filterable: false,
      },
      {
        Header: "Applied Date",
        accessor: "application_date",
        filterable: false,
        Cell: ({ value }) => {
          if (value == null) {
            return "00/00/0000";
          }
          const formattedDate = format(new Date(value), "dd/MM/yyyy");
          return formattedDate;
        },
      },
      {
        Header: "Status",
        accessor: "status_name",
        filterable: false,
      },

      {
        Header: "Action",
        Cell: (cellProps) => {
          const site_id = cellProps.row.original.id;
          const statusName = cellProps.row.original.status_name;
          return (
            <div className="d-flex gap-3">
              <React.Fragment>
                <button
                  className="btn btn-sm btn-soft-warning edit-list"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    const orderId = orderData.job_application_id;
                    const app_status_id = orderData.app_status_id;
                    setModalEdit(true);
                    console.log(sys_skills);
                    const selectedAppStatus = sys_skills?.find(
                      (option) => option.id === app_status_id
                    );
                    setSkillId({
                      value: selectedAppStatus.id,
                      label: selectedAppStatus.status_name,
                    });
                    setId(orderId);
                  }}
                >
                  <i className="bx bxs-pencil fs-12 pt-1" id="edittooltip"></i>
                </button>
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </React.Fragment>
            </div>
          );
        },
      },
    ],
    [handleContactClick, checkedAll, sys_skills]
  );
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);
  //Save and edit Job Vacancies
  const handleSubmit = (event) => {
    event.preventDefault();
    /* validation */
    if (skill_id === "" || skill_id === null || skill_id.length === 0) {
      setSkillIdError("Please select skills.");
    } else {
      setSkillIdError("");
    }
    if (skill_id === "" || skill_id === null || skill_id.length === 0) {
      return;
    }
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      id: id,
      application_status_id: skill_id.value,
    };
    axios
      .post("/update-application-status", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        setModalEdit(false);
        setId("");
        setSkillId("");
        loadTable(authUserId, "", "1");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const addToTalentPool = () => {
    const formData = {
      user_id: authUserId,
      business_id: business_id,
      workforce_profile_id: openProfileId,
    };
    axios
      .post("/add-talent-pool", formData)
      .then((res) => {
        console.log(res);
        if (res.success == true) {
          setmodal_assignModal(false);
          setTalentButton(true);
          toggleDefaultCanvas(false);
          toast.success(res.message);
          loadTable(authUserId);
          window.location.reload();
        }
      })
      .then((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <div>
        <div>
          <Modal
            isOpen={modalOpen}
            toggle={toggleModal}
            centered
            size="lg"
            style={{ maxWidth: "60%" }}
          >
            {selectedRow && (
              <>
                <ModalHeader style={{ paddingBottom: "inherit" }}>
                  <div className="hstack gap-2 mt-2">
                    <i className="text-danger fs-18 me-1 bx bx-map-pin"></i>
                    <h6>{selectedRow.location}</h6>
                  </div>
                  <div className="mt-3">
                    <label
                      htmlFor="firstName"
                      className="form-label hstack fs-14"
                    >
                      <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>{" "}
                      Salary Offered: $
                      {selectedRow.salary_range_from
                        ? `${selectedRow.salary_range_from}`
                        : "$0.00"}{" "}
                      - $
                      {selectedRow.salary_range_to
                        ? `${selectedRow.salary_range_to}`
                        : "$0.00"}
                    </label>{" "}
                  </div>
                </ModalHeader>
                <hr className="mb-3" />
                <ModalBody>
                  <div className="row g-3">
                    <div className="mt-2">
                      <span
                        dangerouslySetInnerHTML={{
                          __html: selectedRow.description,
                        }}
                      ></span>
                    </div>
                    <div className="col-lg-12">
                      <div className="hstack gap-2 justify-content-end">
                        <Button color="danger" onClick={toggleModal}>
                          Close
                        </Button>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </>
            )}
          </Modal>
        </div>
      </div>
      <div className="page-content">
        <Container fluid>
          <div className="mb-3">
            <Nav pills className="nav nav-pills custom-hover-nav-tabs">
              <NavItem
                style={{
                  border: "1px solid #865ce2",
                  borderTopLeftRadius: "7px",
                  borderBottomLeftRadius: "7px",
                }}
              >
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customHoverTab === "1",
                  })}
                  onClick={() => {
                    customHovertoggle("1");
                  }}
                >
                  <i className="bx bx-bolt-circle nav-icon nav-tab-position"></i>
                  <h5 className="nav-titl nav-tab-position m-0">Active</h5>
                </NavLink>
              </NavItem>
              <NavItem
                style={{
                  border: "1px solid #865ce2",
                  borderTopRightRadius: "7px",
                  borderBottomRightRadius: "7px",
                  borderLeft: "none",
                }}
              >
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customHoverTab === "2",
                  })}
                  onClick={() => {
                    customHovertoggle("2");
                  }}
                >
                  <i className="bx bx-archive nav-icon nav-tab-position m-0"></i>
                  <h5 className="nav-titl nav-tab-position">Closed</h5>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <BreadCrumb title="Job Applications" />
          <DeleteModal
            show={deleteModal}
            orderId={selectedOrderId}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
          <Row className="mt-3">
            <Col lg={12}>
              {/* <Card>
                <CardHeader>
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="d-flex justify-content-between align-items-center flex-grow-1">
                      <h5
                        className="mb-0"
                        style={{
                          color: "black",
                          fontSize: "1.5em",
                          color: "black",
                        }}
                      >
                        <i className="bx bxs-user-account" />
                        Job Applications
                      </h5>
                    </div>
                  </div>
                </CardHeader>
              </Card> */}
              <Accordion id="default-accordion-example">
                <AccordionItem>
                  <h2 className="accordion-header" id="headingOne">
                    <button
                      className={classnames("accordion-button fw-semibold", {
                        collapsed: !col1,
                      })}
                      type="button"
                      onClick={t_col1}
                      style={{ cursor: "pointer" }}
                    >
                      Search Filters
                    </button>
                  </h2>
                  <Collapse
                    isOpen={col1}
                    className="accordion-collapse"
                    id="collapseOne"
                  >
                    <div className="accordion-body">
                      <Row className="d-flex justify-content-between">
                        <Col md={9}>
                          <div className="mb-3">
                            <Label for="leadStatusinput" className="form-label">
                              Job Vacancy Status
                            </Label>
                            <Select
                              isMulti={true}
                              onChange={handleStatusMulti}
                              options={statusOptions}
                              value={selectedStatus}
                            />
                          </div>
                        </Col>

                        <Col md={3}>
                          <div className="mt-3 d-flex gap-2">
                            <Button
                              className="btn btn-success"
                              style={{
                                backgroundColor: "#865ce2",
                                borderColor: "#f8f5ff",
                                marginTop: "10px",
                                //  padding: "1% 2%",
                              }}
                              onClick={handleSearch}
                            >
                              Search
                            </Button>
                            <Button
                              className="btn btn-secondary"
                              style={{
                                backgroundColor: "#A9A9A9",
                                borderColor: "#A9A9A9",
                                marginTop: "10px",
                                //  padding: "1% 2%",
                              }}
                              onClick={handleReset}
                            >
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Collapse>
                </AccordionItem>
              </Accordion>
              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {isContactSuccess && crmcontacts.length ? (
                      <TableContainer
                        columns={jobapplicationcolumn}
                        data={crmcontacts || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search by applicant and status"
                      />
                    ) : (
                      crmcontacts.length === 0 && (
                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                          No records found
                        </p>
                      )
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </CardBody>
              </Card>
              <Modal
                className="zoomIn"
                id="addSeller"
                size="lg"
                isOpen={modalEdit}
                toggle={toggleModalEdit}
                centered
              >
                <ModalHeader toggle={toggleModalEdit}>
                  Change Application Status
                </ModalHeader>
                <div className="modal-content border-0 mt-3"></div>
                <ModalBody>
                  <Row>
                    <Col md={12}>
                      <div className="mb-3">
                        <Label for="leadStatusinput" className="form-label">
                          Application Status
                        </Label>
                        <Select
                          className={`${skillIdError ? "is-invalid" : ""}`}
                          value={skill_id}
                          onChange={handleChangeEdit}
                          options={statusOptions}
                          placeholder="Select a skill..."
                        />
                        {skillIdError && (
                          <div className="invalid-feedback">{skillIdError}</div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <Row>
                        <div className="hstack gap-2 justify-content-end">
                          <Col
                            lg={6}
                            className="d-flex justify-content-end gap-2"
                          >
                            <Button
                              className="btn btn-light"
                              data-bs-dismiss="modal"
                              onClick={toggleModalEdit}
                            >
                              Close
                            </Button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              onClick={handleSubmit}
                            >
                              <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                              Update
                            </button>
                          </Col>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Modal
                size="md"
                isOpen={modal_assignModal}
                toggle={() => {
                  tog_assignModal();
                }}
              >
                <ModalHeader></ModalHeader>
                <ModalBody className="text-center">
                  <h5 className="text-center">
                    Ready to add this talented individual to your Talent Pool?
                  </h5>

                  <h5 className="mt-3">Confirm to proceed. </h5>

                  <lord-icon
                    src="https://cdn.lordicon.com/xzalkbkz.json"
                    trigger="loop"
                    style={{ width: "100px", height: "100px" }}
                  ></lord-icon>
                  <Row className="mt-5 d-flex justify-content-center">
                    <Col lg={3} className=" hstack gap-3 p-0 ">
                      <Button
                        className="btn btn-dark"
                        onClick={tog_assignModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="btn btn-success"
                        onClick={addToTalentPool}
                      >
                        Confirm
                      </Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
              <Offcanvas
                isOpen={open}
                toggle={toggleDefaultCanvas}
                style={{ minWidth: "75%", borderLeft: 0 }}
                direction="end"
                id="offcanvasExample"
              >
                <OffcanvasBody
                  className="grey-overall-bg p-0"
                  style={{ overflowX: "hidden" }}
                >
                  <MyProfile
                    profileId={openProfileId}
                    addToTalentPool={addToTalentPool}
                    isJobApplication={true}
                  />
                </OffcanvasBody>
              </Offcanvas>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default JobApplications;
