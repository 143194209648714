import PrismCode from "../../../Components/Common/Prism";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  UncontrolledAlert,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import Dragula from "react-dragula";
// import 'dragula/dist/dragula.css';
import { ChromePicker } from "react-color";
import drag from "../../../assets/images/draghandle.svg";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../Components/Common/Loader";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { SketchPicker } from "react-color";
import DeleteModal from "../../../Components/Common/DeleteModal";
import { get_cookie } from "../../../helpers/get_cookie";
//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const Zone = () => {
  document.title = "Zone | Roster";
  const params = useParams();
  const site_id = params.id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoading, setisLoading] = useState(true);
  const [fieldSets, setFieldSets] = useState(1);
  const [fieldSetsZone, setFieldSetsZone] = useState(1);
  const [zone_data, setZonesData] = useState([]);
  const [name, setZoneName] = useState("");
  const [description, setZoneDescription] = useState("");
  const [id, setId] = useState();
  const [zoneColor, setZoneColor] = useState();
  const [errorClass, setErrorClass] = useState("");
  const [zoneNameError, setZoneNameError] = useState("");
  const [zoneDescriptionError, setZoneDescriptionError] = useState("");
  const [updateNameError, setUpdateNameError] = useState("");
  const [updateDescriptionError, setUpdateDescriptionError] = useState("");
  const [editedData, setEditedData] = useState();
  const [authUserId, setAuthUserId] = useState();
  const [state, setState] = useState("");
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadzone(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const loadzone = (userId) => {
    setisLoading(true);
    const formData = {
      user_id: userId,
      business_id: business_id,
      id: site_id,
    };
    axios
      .post("/get-zone-data", formData)
      .then((response) => {
        console.log(response, "response");
        if (response.error === 1) {
          setState(false);
          setZonesData(response.zones);
          setEditedData(response.zones);
          let rgb_color;
          response.zones?.map((item, key) => {
            if (
              item.id === id &&
              item.hex_colour != null &&
              item.hex_colour != ""
            ) {
              setZoneColor(item.hex_colour);
              let color = item.hex_colour.replace(/^#/, "");
              const bigint = parseInt(color, 16);
              const r = (bigint >> 16) & 255;
              const g = (bigint >> 8) & 255;
              const b = bigint & 255;
              rgb_color = { r, g, b, a: 1 };
              console.log(rgb_color, "colorr");
              setColor(rgb_color);
            }
          });
        } else {
          setState(true);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  const containerRef = useRef(null);

  useEffect(() => {
    let options = {
      ignoreInputTextSelection: false,
    };
    const drake = Dragula([containerRef.current], options);

    drake.on("drop", () => {
      const newOrder = Array.from(containerRef.current.children).map((row) => {
        const id = row.getAttribute("data-id");
        const item = editedData.find((item) => item.id.toString() === id);
        return { id: item.id, zone_name: item.zone_name };
      });

      // Send new order to backend
      axios
        .post("/update-zone-ordering", { updatedOrder: newOrder })
        .then((response) => {
          console.log("Order updated successfully", response);
        })
        .catch((error) => {
          console.error("Error updating order", error);
        });
    });
    return () => {
      drake.destroy(); // Clean up Dragula when component unmounts
    };
  }, [editedData]);
  const [isEditing, setIsEditing] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [editingItemId, setEditingItemId] = useState(null);
  const [deletingId, setDeletingId] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const onClickDelete = (id) => {
    setDeletingId(id);
    setDeleteModal(true);
  };
  console.log(zone_data, "zone_data");
  const handleEditClick = (id) => {
    let rgb_color;
    setEditingItemId(id);
    setIsEditing(true);
    zone_data?.map((item, key) => {
      if (item.id === id && item.hex_colour != null && item.hex_colour != "") {
        setZoneColor(item.hex_colour);
        let color = item.hex_colour.replace(/^#/, "");
        const bigint = parseInt(color, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        rgb_color = { r, g, b, a: 1 };
        console.log(rgb_color, "colorr");
        setColor(rgb_color);
      }
    });
  };

  // color palete code
  const paletteColors = [
    ["#000", "#444", "#666", "#999", "#ccc", "#eee", "#F3F3F3", "#fff"],
    ["#f00", "#f90", "#ff0", "#0f0", "#0ff", "#00f", "#90f", "#f0f"],
    [
      "#F4CCCC",
      "#FCE5CD",
      "#FFF2CC",
      "#D9EAD3",
      "#D0E0E3",
      "#CFE2F3",
      "#D9D2E9",
      "#EAD1DC",
    ],
    [
      "#EA9999",
      "#F9CB9C",
      "#FFE599",
      "#B6D7A8",
      "#A2C4C9",
      "#9FC5E8",
      "#B4A7D6",
      "#D5A6BD",
    ],
    [
      "#E06666",
      "#F6B26B",
      "#FFD966",
      "#93C47D",
      "#76A5AF",
      "#6FA8DC",
      "#8E7CC3",
      "#C27BA0",
    ],
    [
      "#c00",
      "#E69138",
      "#F1C232",
      "#6AA84F",
      "#45818E",
      "#3D85C6",
      "#674EA7",
      "#A64D79",
    ],
    [
      "#900",
      "#B45F06",
      "#BF9000",
      "#38761D",
      "#134F5C",
      "#0B5394",
      "#351C75",
      "#741B47",
    ],
    [
      "#600",
      "#783F04",
      "#7F6000",
      "#274E13",
      "#0C343D",
      "#073763",
      "#20124D",
      "#4C1130",
    ],
  ];
  const [color, setColor] = useState({ r: 241, g: 112, b: 19, a: 1 });
  const [colour, setColour] = useState();
  const [colors, setColors] = useState();
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };
  const handleClose = () => {
    setDisplayColorPicker(false);
  };
  const handleChange = (newColor) => {
    setZoneColor(newColor.hex);
    console.log(newColor.rgb, "color");
    setColor(newColor.rgb);
    const { r, g, b } = newColor.rgb;
    const rgbValue = `rgb(${r}, ${g}, ${b})`;
    setColor(newColor.rgb);
    //setColour(rgbValue)
  };
  const styles = {
    swatch: {
      padding: "5px",
      background: "#fff",
      borderRadius: "1px",
      boxShadow: "0 0 0 1px rgba(0,0,0,.1)",
      display: "inline-block",
      cursor: "pointer",
      width: "80%",
    },
    color: {
      height: "14px",
      borderRadius: "2px",
      background: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    },
    popover: {
      position: "absolute",
      zIndex: "1000",
      top: "65%",
      left: "6%",
    },
    cover: {
      position: "fixed",
      top: "0px",
      right: "0px",
      bottom: "0px",
      left: "0px",
    },
  };

  //color pallet code end
  const handleCancelClick = () => {
    setIsEditing(false);
  };
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    setEditedData((prevData) => {
      const newData = [...prevData];
      newData[index] = {
        ...newData[index],
        [name]: value,
      };
      return newData;
    });
  };
  const handleUpdateClick = (index) => {
    const updatedData = editedData[index];
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      site_id: site_id,
      id: editingItemId,
      zone_name: "",
      zone_description: "",
      hex_colour: zoneColor,
    };
    if (updatedData !== undefined) {
      formData.zone_name = updatedData.zone_name;
      formData.zone_description = updatedData.zone_description;
    } else if (zone_data && zone_data.length > 0) {
      // Use the values from the first item in the zone_data array
      zone_data?.map((item, key) => {
        formData.zone_name = item.zone_name;
        formData.zone_description = item.zone_description;
      });
    }
    if (formData.zone_name === "") {
      setUpdateNameError("Zone Name is required");
    } else {
      setUpdateNameError(""); // Clear the error message if the input is not empty.
    }
    if (formData.zone_description === "") {
      setUpdateDescriptionError("Zone Description is required");
    } else {
      setUpdateDescriptionError(""); // Clear the error message if the input is not empty.
    }
    if (formData.zone_name === "" || formData.zone_description === "") {
      // If any of the required fields are empty, do not proceed with form submission.
      return;
    }
    console.log(formData);
    setisLoading(true);
    axios
      .post("/update-zone-data", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        setZonesData((prevData) => {
          const newData = [...prevData];
          newData[index] = { ...updatedData };
          return newData;
        });
        setIsEditing(false);
        loadzone(authUserId);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const handleSubmit = (event) => {
    //event.preventDefault();
    if (name === "") {
      setZoneNameError("Zone Name is required");
    } else {
      setZoneNameError(""); // Clear the error message if the input is not empty.
    }
    if (description === "") {
      setZoneDescriptionError("Zone Description is required");
    } else {
      setZoneDescriptionError(""); // Clear the error message if the input is not empty.
    }
    if (name === "" || description === "") {
      // If any of the required fields are empty, do not proceed with form submission.
      return;
    }
    setisLoading(true);
    var formData = {
      user_id: authUserId,
      business_id: business_id,
      site_id: site_id,
      zone_name: name,
      zone_description: description,
    };
    axios
      .post("/update-zone-data", formData)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        loadzone(authUserId);
        setZoneName("");
        setZoneDescription("");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };
  const handleDelete = () => {
    //event.preventDefault();
    setisLoading(true);
    axios
      .get(`/destroy-zone-data/${deletingId}`)
      .then((response) => {
        toast.success(response.success, { theme: "light" });
        setZonesData(zone_data.filter((zone) => zone.id !== deletingId));
        setEditedData(editedData.filter((zone) => zone.id !== deletingId));
        setDeleteModal(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      })
      .finally(() => {
        setisLoading(false); //reloading the page
      });
  };

  const [rows, setRows] = useState([
    { id: 1, name: "Row 1", color: "#ff0000", isEditing: false },
    { id: 2, name: "Row 2", color: "#00ff00", isEditing: false },
    { id: 3, name: "Row 3", color: "#0000ff", isEditing: false },
  ]);

  const handleEditClicks = (index) => {
    const newRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, isEditing: true } : row
    );
    setRows(newRows);
  };

  const handleNameChange = (index, event) => {
    const newRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, name: event.target.value } : row
    );
    setRows(newRows);
  };

  const handleColorChangeComplete = (index, color) => {
    const newRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, color: color.hex } : row
    );
    setRows(newRows);
  };

  const handleUpdateClicks = (index) => {
    const newRows = rows.map((row, rowIndex) =>
      rowIndex === index ? { ...row, isEditing: false } : row
    );
    setRows(newRows);
  };
  return (
    <div>
      <ToastContainer />
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Card>
        <CardHeader>
          <div className="d-flex align-items-center flex-wrap gap-2">
            <div className="flex-grow-1">
              <h5 className="mb-0 text-primary">Zones</h5>
            </div>
            <div className="flex-shrink-0"></div>
          </div>
        </CardHeader>

        {/* Display the "No Record Found for this Id" message */}
        {state && (
          <CardBody className="hidedata" style={{ paddingTop: "2%" }}>
            <Col xxl={12}>
              <div>No zones found for this site. Please add a zone</div>
            </Col>
          </CardBody>
        )}

        {/* Always display the "Add" button */}
        <CardBody>
          {isLoading ? (
            <div>
              <TabPane tabId={2}>
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-2"></div>
                  <div>
                    <Row className="gy-3">
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder placeholder-sm col-2 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder placeholder-xs col-4 bg-dark"></span>
                      </h3>
                      <h3 className="card-title placeholder-glow">
                        <span className="placeholder placeholder-xs col-6 bg-dark"></span>
                      </h3>
                    </Row>
                  </div>
                </div>
              </TabPane>
              <div className="table-responsive">
                <table className="table align-middle position-relative table-nowrap">
                  <thead className="table-active"></thead>
                  <tbody id="task-list">
                    <tr>
                      <td colSpan="12">
                        <Row>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm col-2 bg-dark"></span>
                          </h3>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-xs col-6 bg-dark"></span>
                          </h3>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-sm col-7 bg-dark"></span>
                          </h3>
                          <h3 className="card-title placeholder-glow">
                            <span className="placeholder placeholder-xs col-9 bg-dark"></span>
                          </h3>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div>
              <TabPane tabId={2}>
                <div className="mt-4">
                  <div className="d-flex align-items-center mb-2"></div>
                  <div>
                    <Row className="gy-3">
                      <Col sm={5}>
                        <div className="mb-3">
                          <Input
                            value={name}
                            onChange={(e) => {
                              setZoneName(e.target.value);
                              setZoneNameError("");
                            }}
                            type="text"
                            className={`form control ${
                              zoneNameError ? "is-invalid" : ""
                            }`}
                            id="billinginfo-firstName"
                            placeholder="Zone Name"
                          />
                          {zoneNameError && (
                            <div className="invalid-feedback">
                              {zoneNameError}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col sm={5}>
                        <div className="mb-3">
                          <Input
                            value={description}
                            onChange={(e) => {
                              setZoneDescription(e.target.value);
                              setZoneDescriptionError("");
                            }}
                            type="text"
                            className={`${
                              zoneDescriptionError ? "is-invalid" : ""
                            }`}
                            id="billinginfo-firstName"
                            placeholder="Zone Description"
                          />
                          {zoneDescriptionError && (
                            <div className="invalid-feedback">
                              {zoneDescriptionError}
                            </div>
                          )}
                        </div>
                      </Col>
                      <Col sm={2}>
                        <div className="flex-shrink-0">
                          <button
                            type="button"
                            className="btn btn-md btn-info mb-3 w-100"
                            onClick={handleSubmit}
                          >
                            Add
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>
              <div className="table-responsive" style={{ overflow: "visible" }}>
                <table className="table align-middle position-relative table-nowrap">
                  <thead className="table-active"></thead>
                  <tbody id="task-list" ref={containerRef}>
                    {editedData?.map((item, key) => (
                      <tr key={key} data-id={item.id}>
                        {isEditing && editingItemId === item.id ? (
                          <td colSpan="12">
                            <div style={{ position: "relative" }}>
                              <Row className="d-flex">
                                <Input
                                  type="hidden"
                                  name="id"
                                  value={item.id}
                                  onChange={(e) => handleInputChange(e, key)}
                                />
                                <Col md={3}>
                                  <Input
                                    type="text"
                                    className={`${
                                      updateNameError ? "is-invalid" : ""
                                    }`}
                                    name="zone_name"
                                    value={item.zone_name}
                                    onChange={(e) => {
                                      handleInputChange(e, key);
                                      setUpdateNameError("");
                                    }}
                                  />
                                  {updateNameError && (
                                    <div className="invalid-feedback">
                                      {updateNameError}
                                    </div>
                                  )}
                                </Col>
                                <Col md={4}>
                                  <Input
                                    type="text"
                                    className={`${
                                      updateDescriptionError ? "is-invalid" : ""
                                    }`}
                                    name="zone_description"
                                    value={item.zone_description}
                                    onChange={(e) => {
                                      handleInputChange(e, key);
                                      setUpdateDescriptionError("");
                                    }}
                                  />
                                  {updateDescriptionError && (
                                    <div className="invalid-feedback">
                                      {updateDescriptionError}
                                    </div>
                                  )}
                                </Col>
                                <Col md={2}>
                                  <div className="mt-2">
                                    <div>
                                      <div
                                        style={styles.swatch}
                                        onClick={handleClick}
                                      >
                                        <div style={styles.color} />
                                      </div>
                                      {displayColorPicker && (
                                        <div style={styles.popover}>
                                          <div
                                            style={styles.cover}
                                            onClick={handleClose}
                                          />
                                          <SketchPicker
                                            color={color}
                                            onChange={handleChange}
                                          />
                                        </div>
                                      )}
                                    </div>
                                    <Input
                                      type="hidden"
                                      className={`form control ${
                                        errorClass === "vehicleColorError"
                                          ? "errorActive"
                                          : ""
                                      }`}
                                      placeholder="Colour Picker"
                                      value={item.zoneColor}
                                      onChange={(e) =>
                                        handleInputChange(e, key)
                                      }
                                      id="colourPicker"
                                    />
                                  </div>
                                </Col>
                                <Col
                                  md={3}
                                  className="d-flex justify-content-end align-item-end mt-2"
                                >
                                  <Button
                                    className="btn btn-sm btn-success mb-3"
                                    type="button"
                                    onClick={() => handleUpdateClick(key)}
                                    style={{ marginRight: "10px" }}
                                  >
                                    Update
                                  </Button>
                                  <Button
                                    className="btn btn-sm btn-info mb-3"
                                    type="button"
                                    onClick={handleCancelClick}
                                  >
                                    Cancel
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </td>
                        ) : (
                          <td colSpan="12">
                            <div style={{ position: "relative" }}>
                              <Row className="d-flex">
                                <Col
                                  md={4}
                                  className="d-flex align-items-center justify-content-center"
                                >
                                  <div style={{ padding: "10px 0", flex: "1" }}>
                                    <span
                                      style={{
                                        backgroundColor: item.hex_colour,
                                        color: "white",
                                        padding: "8px",
                                        borderRadius: "2px",
                                      }}
                                    >
                                      {item.zone_name}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  md={5}
                                  className="d-flex align-items-center"
                                >
                                  <div
                                    style={{ flex: "1", paddingRight: "8px" }}
                                  >
                                    <span
                                      style={{
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                      }}
                                    >
                                      {item.zone_description}
                                    </span>
                                  </div>
                                </Col>
                                <Col
                                  md={3}
                                  className="text-end d-flex justify-content-end align-items-center"
                                >
                                  <div
                                    className="me-2"
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    style={{
                                      // border: hovered ? '2px solid #000' : 'none',
                                      cursor: "move",
                                      transition: "border 0.3s ease",
                                    }}
                                  >
                                    <img src={drag} alt="" height="23" />
                                  </div>
                                  <div>
                                    <Link
                                      to="#"
                                      onClick={() => handleEditClick(item.id)}
                                      className="text-success me-2"
                                    >
                                      <i
                                        className="mdi mdi-pencil font-size-18 btn btn-sm btn-soft-info edit-list"
                                        id="edittooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="edittooltip"
                                      >
                                        Edit
                                      </UncontrolledTooltip>
                                    </Link>
                                    <Link
                                      to="#"
                                      onClick={() => onClickDelete(item.id)}
                                      className="text-danger"
                                    >
                                      <i
                                        className="mdi mdi-delete font-size-18 btn btn-sm btn-soft-danger edit-list"
                                        id="deletetooltip"
                                      />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="deletetooltip"
                                      >
                                        Delete
                                      </UncontrolledTooltip>
                                    </Link>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  );
};
