import { Card, CardBody, CardHeader, Col, Container, Row, Accordion, AccordionItem, Collapse, Label, Input } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import Select from "react-select";
import Loader from "../../Components/Common/Loader";

// Import Table Data
// import { BaseExample } from '../GridTablesData';
import axios from 'axios';
import { get_cookie } from '../../helpers/get_cookie';
import Flatpickr from "react-flatpickr";
import classnames from "classnames";
import * as moment from "moment";
import TableContainer from "../../Components/Common/TableContainer";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from 'react-toastify';

import {
    getContacts as onGetContacts,
    addNewContact as onAddNewContact,
    updateContact as onUpdateContact,
    deleteContact as onDeleteContact,
} from "../../store/actions";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";

const CurrentContracts = () => {
    const isContactSuccess = true;
    const [contact_person, setContactperson] = useState("");
    const [contact, setContact] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [tag, setTag] = useState([]);
    const [assignTag, setAssignTag] = useState([]);
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
    const [isLoading, setisLoading] = useState(false);
    const [modal, setModal] = useState(false);
    const dispatch = useDispatch();
    //data table
    const [col1, setcol1] = useState(false);
    const [lead_status, setLeadStatus] = useState([]);
    const [sorting_order_array, setSortingOrderArray] = useState([]);
    const [lead_status_id, setLeadstatusid] = useState("");
    const [sorting_order_id, setSortingOrderid] = useState("");
    const [createdDateStart, setCreatedDateStart] = useState(null);
    const [createdDateEnd, setCreatedDateEnd] = useState(null);
    const [sort_descending, setSortdescending] = useState("");
    const [org_date, setOrg_Date] = useState([]);


    const loadTable = () => {
        const postData = {
            created_date_start: null,
            created_date_end: null,
            lead_status: null,
            sorting_order: null,
            sort_descending: null,
        };
        setisLoading(true);
        axios.post("/api/crm-leads/residentialData", postData)
            .then((res) => {
                // setOrg_Date(res.data[0]);
                setData(res.data);
                console.log(res?.data, "res1234");
                // setCreatedDateEnd(moment().format(res?.data[0]?.organisation_date_picker));
                // setCreatedDateStart(moment().subtract(1, 'months').format(res?.data[0]?.organisation_date_picker));
            })
            .catch((error) => {
                console.error(error);
            })

            axios.get("api/listdata")
            .then((response) => {
              setOrg_Date(response?.organisation_settings);
              console.log(response.organisation_settings)
            })
            .catch((error) => {
              console.error("Error fetching lead types:", error);
            });
            
        axios.get("/api/crm-leads/residential")
            .then((response) => {
                setLeadStatus(response.lead_statuses);
                const defaultSelectedOptions = response.lead_statuses.slice(0, 4).map(item => ({ value: item.id, label: item.lead_status }));
                setLeadstatusid(defaultSelectedOptions)
                setSortingOrderArray(response.sorting_order_array);

            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setisLoading(false);
            })

    }

    const t_col1 = () => {
        setcol1(!col1);

    };
    const [data, setData] = useState([]);
    const deleteCheckbox = () => {
        const ele = document.querySelectorAll(".contactCheckBox:checked");
        ele.length > 0 ? setIsMultiDeleteButton(true) : setIsMultiDeleteButton(false);
        setSelectedCheckBoxDelete(ele);
    };
    const handleContactClicks = () => {
        setContact("");
        setIsEdit(false);
        toggle();
    };
    const toggle = useCallback(() => {
        if (modal) {
            setModal(false);
            setContact(null);
        } else {
            setModal(true);
            setTag([]);
            setAssignTag([]);
        }
    }, [modal]);
    const crmcontacts = data;

    useEffect(() => {
        setContact(crmcontacts);
    }, [crmcontacts]);

    useEffect(() => {
        if (!isEmpty(crmcontacts)) {
            setContact(crmcontacts);
            setIsEdit(false);
        }
    }, [crmcontacts]);
    const handleContactClick = useCallback((arg) => {
        const contact = arg;

        setContact({
            _id: contact._id,
            // img: contact.img,
            name: contact.name,
            company: contact.company,
            email: contact.email,
            designation: contact.designation,
            phone: contact.phone,
            lead_score: contact.lead_score,
            last_contacted: contact.date,
            // time: contact.time,
            tags: contact.tags,
        });

        setIsEdit(true);
        toggle();
    }, [toggle]);

    const checkedAll = useCallback(() => {
        const checkall = document.getElementById("checkBoxAll");
        const ele = document.querySelectorAll(".contactCheckBox");

        if (checkall.checked) {
            ele.forEach((ele) => {
                ele.checked = true;
            });
        } else {
            ele.forEach((ele) => {
                ele.checked = false;
            });
        }
        deleteCheckbox();
    }, []);
    //end of data table content

    useEffect(() => {
        loadTable();
    }, []);
    const handleSubmit = (event) => {
        event.preventDefault();
        let result;
        if (lead_status_id == "" || lead_status_id == undefined || lead_status_id == null) {
            toast.error("Select Lead Status", { theme: "light" });
            return false;
        } else {
            const values = lead_status_id.map(item => item.label);
            result = values.join(',');
        }
        // let startDate;
        // if(createdDateStart !== undefined && createdDateStart !== "" && moment(createdDateStart, 'DD MMM, YYYY').isValid()){
        //   startDate = moment(createdDateStart, 'DD MMM, YYYY').format('DD/MM/YYYY');
        // }else{
        //     startDate = null;
        // }
        // let endDate;
        // if (createdDateEnd !== undefined && createdDateEnd !== "" && moment(createdDateEnd, 'DD MMM, YYYY').isValid()) {
        //   endDate = moment(createdDateEnd, 'DD MMM, YYYY').format('DD/MM/YYYY');
        // } else {
        //   endDate = null;
        // }
        const postData = {
            created_date_start: createdDateStart,
            created_date_end: createdDateEnd,
            lead_status: result,
            sorting_order: sorting_order_id.value,
            sort_descending: activeInterest,
        };
        console.log(postData, "postData");
        setisLoading(true);
        axios.post("/api/crm-leads/residentialData", postData)
            .then((res) => {
                setData(res.data);
                console.log(res.data);
            })
            .catch((error) => {
                console.error(error);
            })
            .finally(() => {
                setisLoading(false);
            })
    }
    const sortingOrderArray = Object.keys(sorting_order_array).map(key => ({
        value: key,
        label: sorting_order_array[key]
    }));
    function handleLeadStatus(selectedLeadStatus) {
        setLeadstatusid(selectedLeadStatus);
    }
    function handleSortingOrder(selectedArray) {
        setSortingOrderid(selectedArray);
    }
    const [activeInterest, setActiveInterest] = useState(1);
    const handleCheckboxChange = (event) => {
        setActiveInterest(event.target.checked ? 1 : 0);
    };

    const resetTable = () => {
        setCreatedDateStart(null);
        setCreatedDateEnd(null);
        const defaultSelectedOptions = lead_status.slice(0, 4).map(item => ({ value: item.id, label: item.lead_status }));
        setLeadstatusid(defaultSelectedOptions);
        setSortingOrderid("");
        setSortdescending(0);
        loadTable();
    }
    const columns = useMemo(
        () => [
            /*{
              Header: <input type="checkbox" id="checkBoxAll" className="form-check-input" onClick={() => checkedAll()} />,
              Cell: (cellProps) => {
                return <input type="checkbox" className="contactCheckBox form-check-input" value={cellProps.row.original._id} onChange={() => deleteCheckbox()} />;
              },
              id: '#',
            },*/
            {
                Header: "Name",
                accessor: "customer",
                filterable: false,
                Cell: (cell) => {
                    return <Link to={"/customerdetails/" + cell.value.id} className="fw-medium link-primary">{cell.value.name}</Link>;
                },
            },
            {
                Header: "Mobile",
                accessor: "mobile",
                filterable: false,
            },

            {
                Header: "Email",
                accessor: "email",
                filterable: false,
            },
            {
                Header: "Status",
                accessor: "lead_status",
                filterable: false,
            },
        ],
        [handleContactClick, checkedAll]
    );
    document.title = "Current Contracts | Get Rostered";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Contracts"/>
                    <Row className="mt-3">
                        <Col lg={12}>
                            <Card>
                                <CardHeader>
                                </CardHeader>
                                <CardBody>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CurrentContracts;