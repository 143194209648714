import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './pages/ErrorBoundary';
import { datadogRum } from '@datadog/browser-rum';
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { get_cookie } from './helpers/get_cookie';
import { configureStore } from "./store";
import { ProductFruits } from 'react-product-fruits';

const root = ReactDOM.createRoot(document.getElementById("root"));
let user = null;
user = JSON.parse(get_cookie("authUser"));
const userInfo = user ? {
    username: user.name, // REQUIRED - any unique user identifier
    email: user.email,
} : null;

root.render(
  <ErrorBoundary>
    <Provider store={configureStore({})}>
      <React.Fragment>
        <BrowserRouter basename={process.env.PUBLIC_URL}>
{user && (
       <ProductFruits workspaceCode={"XnlNmGobxqAbYdKA"} language="en" user={userInfo} />
)}
         
          <App />
        </BrowserRouter>
      </React.Fragment>
    </Provider>
    </ErrorBoundary>
);

datadogRum.init({
    applicationId: 'f9839b83-3a1a-4eda-b6e1-0efd5258257f',
    clientToken: 'pubd6695a55842822ea997c484fb0513541',
    // `site` refers to the Datadog site parameter of your organization
    // see https://docs.datadoghq.com/getting_started/site/
    site: 'ap1.datadoghq.com',
    service: 'business-getrostered',
    env: 'prod',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0', 
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

datadogRum.setUser({ id: user.user_id, name: user.name, email: user.email });

reportWebVitals();
