import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Accordion,
  AccordionItem,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  Collapse,
  Label,
  Table,
  Input,
  Button,
  Badge,
  Form,
} from "reactstrap";
import portdummy from "../../assets/images/portdummy.png";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  EffectCoverflow,
  Pagination,
  Mousewheel,
} from "swiper";
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  useMemo,
} from "react";

import moment from "moment/moment";
import { get_cookie } from "../../helpers/get_cookie";
import axios from "axios";
import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Flatpickr from "react-flatpickr";
import userDummy from "../../assets/images/users/user-dummy-img.jpg";
import industryDummy from "../../assets/images/industry.png";
import profileBg from "../../assets/images/profile-bg.jpg";
import workHistory from "../../assets/images/freepik/circle-briefcase.png";
import avatar5 from "../../assets/images/users/avatar-5.jpg";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import crownwhite from "../../assets/images/crownwhite.png";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import LocationIcon from "../../assets/images/location.svg";
import "../../assets/scss/custom.scss";
import DOMPurify from "dompurify";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/scrollbar";
import "swiper/css/effect-fade";
import "swiper/css/effect-flip";
import SiteDetailPopup from "../../Components/Common/SiteDetailPopup";
import Rating from "react-rating";

const MyProfile = ({
  profileId,
  isTalentPool,
  addToTalentPool,
  isSearchWorkforce,
  isJobApplication,
}) => {
  const all_permissions = JSON.parse(get_cookie("permissions"));
  const business_id = JSON.parse(get_cookie("authUser")).business_id;

  const [username, setUserName] = useState(null);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [email, setEmail] = useState(null);
  const [suburb, setSuburb] = useState(null);
  //   const [userProfile, setUserProfile] = useState();
  const [userProfileImg, setUserProfileImg] = useState();
  const [userRating, setuserRating] = useState(0);
  //   const [skills, setSkills] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [userSkill, setUserSkill] = useState(null);
  const [tags, setTags] = useState([]);
  const [suggestion, setSuggestions] = useState([]);
  // side section states
  const [workforcePreferences, setWorkforcePreferences] = useState(null);
  const [hourlyRate, setHourlyRate] = useState(null);
  const [monthlySalary, setMonthlySalary] = useState(null);
  const [preferredWorkType, setPreferredWorkType] = useState("");
  const [availableStartDate, setAvailableStartDate] = useState(null);
  const [profileSumary, setProfileSumary] = useState(null);
  const [modal_grid, setmodal_grid] = useState(false);
  const [userId, setUserId] = useState(null);
  const [isButtonClicked, setIsButtonClicked] = useState(false);

  //Profile view
  const [jobVacancyDropdown, setJobVacancyDropdown] = useState([]);
  const [selectedJobVacancy, setSelectedJobVacancy] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [vacancyDetails, setVacancyDetails] = useState();
  const [decimalValue, setDecimalValue] = useState();
  const [offerValidUpto, setOfferValidUpto] = useState();
  const [proposedStartDate, setProposedStartDate] = useState();
  const [salaryType, setSalaryType] = useState("Hourly");
  const [profileDetails, setProfileDetails] = useState([]);
  const [isTeam, setIsTeam] = useState(false);
  //constants to store the main data
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isTalent, setIsTalent] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [education, setEducation] = useState([]);
  const [history, setHistory] = useState([]);
  const [location, setLocation] = useState([]);
  const [portfolio, setPortfolio] = useState([]);
  const [review, setReview] = useState([]);
  const [userDetails, setUserDetails] = useState();
  const [userProfile, setUserProfile] = useState([]);
  const [preferences, setPreferences] = useState();
  const [industry, setIndustry] = useState([]);
  const [skills, setSkills] = useState([]);
  const [currentAvailability, setCurrentAvailability] = useState();
  const [availability, setAvailability] = useState([]);
  const [verificationDocument, setVerificationDocument] = useState([]);
  const [jobStatus, setJobStatus] = useState(false);
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [modal_assignTalent, setmodal_assignTalent] = useState(false);
  const [talent, setTalent] = useState([]);
  const [text, setText] = useState([]);
  const [showMoreList, setShowMoreList] = useState([]);
  const [showMoreListOne, setShowMoreListOne] = useState([]);
  const [businessData, setBusinessData] = useState([]);
  const [workforceUserId, setworkforceUserId] = useState([]);
  const [isChatExists, setIsChatExists] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // s3 bucket url for portfolio and profile
  const s3portfolioUrl =
    "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-portfolio/";
  // const s3ImgUrl =
  //   "https://s3.ap-southeast-2.amazonaws.com/devwork.getrostered.app/workforce-profile-pictures/";

  //swiper
  SwiperCore.use([Autoplay]);
  const navigate = useNavigate();
  //date restriction for job vacancy
  const today = new Date();
  const thirtyDaysLater = new Date();
  thirtyDaysLater.setDate(today.getDate() + 30);

  const sevenDaysLater = new Date();
  sevenDaysLater.setDate(today.getDate() + 7);
  function tog_grid() {
    setmodal_grid(!modal_grid);
  }
  const handleclick = () => {
    setShowPopup(!showPopup);
  };
  const handleInputChange = (e) => {
    const inputValue = e.target.value;

    // Validate the input against the desired decimal format
    const isValidInput = /^\d{0,10}(\.\d{0,2})?$/.test(inputValue);

    if (isValidInput) {
      setDecimalValue(inputValue || "");
    }
  };
  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }
  function tog_assignTalent() {
    setmodal_assignTalent(!modal_assignTalent);
  }
  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  function stripHtmlTagsOne(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  useEffect(() => {
    setUserId(JSON.parse(get_cookie("authUser")).user_id);
    // const fetchUserProfile = async () => {
    //   try {
    //     if (get_cookie("authUser") !== false) {
    //       const user_id = JSON.parse(get_cookie("authUser")).user_id;

    //       // Call userdetails API
    //       const userResponse = await axios.get(`/userdetails/${user_id}`);

    //       // Log the entire response to the console for debugging
    //       // console.log(userResponse.data);

    //       if (userResponse.data && userResponse.message === "Success") {
    //         // Assuming the API response has a property 'data' containing user details
    //         const userProfile = userResponse.data.user_profile[0];
    //         const workforcePreferences =
    //           userResponse.data.user_profile[0].workforce_preferences;
    //         const userDetails = userResponse.data.user_details;

    //         setUserProfile(userProfile);
    //         // Assuming the user details are nested under 'data'
    //         const {
    //           first_name,
    //           last_name,
    //           location,
    //           email,
    //           star_rating,
    //           user_skills,
    //           workforce_preferences,
    //           industry_names,
    //         } = userProfile;
    //         const { profile_picture_url } = userDetails;
    //         const {
    //           desired_hourly_rate,
    //           desired_monthly_salary,
    //           preferred_work_type,
    //           available_start_date,
    //           minimum_engagement_duration,
    //           profile_summary,
    //         } = workforcePreferences;
    //         // Profile section
    //         setUserName(`${first_name} ${last_name}`);
    //         setFirstName(first_name);
    //         setLastName(last_name);
    //         setSuburb(location);
    //         setEmail(email);

    //         setUserSkill(user_skills);
    //         setUserProfileImg(
    //           profile_picture_url || "https://picsum.photos/200"
    //         );

    //         // Side Section-1
    //         setHourlyRate(desired_hourly_rate);
    //         setMonthlySalary(desired_monthly_salary);
    //         setPreferredWorkType(preferred_work_type);
    //         setAvailableStartDate(available_start_date);
    //         setWorkforcePreferences(workforce_preferences);
    //         setProfileSumary(profile_summary);
    //         setCurrentAvailability(minimum_engagement_duration);
    //         const defaultSelectedOptions = industry_names?.map((item) => ({
    //           value: item.id,
    //           label: item.name,
    //         }));

    //         setSelectedIndustry(defaultSelectedOptions);
    //         // setSelectedFromUom({
    //         //     value: res.from_uom_id,
    //         //     label: res.from_uom_name,
    //         // });

    //         console.log(defaultSelectedOptions);
    //         // Call get-skills API
    //         const skillsResponse = await axios.get("/get-skills");

    //         if (skillsResponse.data && skillsResponse.message === "Success") {
    //           // Assuming the skills are nested under 'data'
    //           const skillsData = skillsResponse.data;

    //           // Update state with the fetched skills
    //           setSkills(skillsData);

    //           // Log the skills to the console for debugging
    //           console.log(skills);
    //         } else {
    //           // Handle case where skills are not found
    //           console.error("Skills not found");
    //         }
    //       } else {
    //         // Handle case where user details are not found
    //         console.error("User details not found");
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error fetching user profile:", error);
    //   }
    // };

    // fetchUserProfile();

    const today = new Date();
    const thirtyDays = new Date(today); // Create a new Date object
    thirtyDays.setDate(today.getDate() + 30); // Add 30 days to the new Date object

    const sevenDays = new Date(today); // Create another new Date object
    sevenDays.setDate(today.getDate() + 7); // Add 7 days to the second Date object

    const validityDate = moment(thirtyDays).format("YYYY-MM-DD");
    const proposedDate = moment(sevenDays).format("YYYY-MM-DD");

    // Set the default validity and start dates as Today+30 days and Today+7 days respectively
    setOfferValidUpto(validityDate);
    setProposedStartDate(proposedDate);
  }, []);
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span className="' + className + '">' + (index + 1) + "</span>";
    },
  };

  const toggleShowMore = (index) => {
    const updatedShowMoreList = [...showMoreList];
    updatedShowMoreList[index] = !updatedShowMoreList[index];
    setShowMoreList(updatedShowMoreList);
  };

  const toggleShowMoreOne = (index) => {
    const updatedShowMoreListOne = [...showMoreListOne];
    updatedShowMoreListOne[index] = !updatedShowMoreListOne[index];
    setShowMoreListOne(updatedShowMoreListOne);
  };

  const loadData = () => {
    setIsLoading(true);

    let profileDetail;
    if (userId != null) {
      let formData = {
        user_id: userId,
        business_id: business_id,
      };
      axios
        .post("/get-job-vacancy-dropdown-list", formData)
        .then((res) => {
          console.log(res);
          setJobVacancyDropdown(res.data);
        })
        .then((err) => {
          console.log(err);
        });
      profileDetail = {
        profile_id: profileId,
        business_user_id: userId,
        business_id: business_id,
      };
      axios
        .post("/get-work-details", profileDetail)
        .then((res) => {
          console.log(res, "work details");
          if (res.message === "Success") {
            setBusinessData(res.data.business_data);
            setData(res.data);
            setworkforceUserId(res.data.workforce_user_id);
            setProfileDetails(res.data);
            setIsTalent(res.data.is_talent);
            setCertificates(res.data.certificates);
            setEducation(res.data.education);
            setHistory(res.data.history);
            setLocation(res.data.locations);
            setPortfolio(res.data.portfolio);
            setReview(res.data.review);
            setUserDetails(res.data.user_details);
            setUserProfile(res.data.user_profile);
            setPreferences(res.data.user_profile?.workforce_preferences);
            setCurrentAvailability(
              res.data.user_profile?.workforce_availability[0]
            );
            setAvailability(res.data.availability_list);
            setSkills(res.data.user_profile?.workforce_skills);
            setVerificationDocument(
              res.data.user_profile?.workforce_verification_documents
            );
            setuserRating(res.data.user_profile?.star_rating);
            setIsLoading(false);
            setJobStatus(res.data.job_offer_status);
            setIsTeam(res.data.is_team);
            setTalent(res.data.status);
            setText(res.data.text);
            setIsChatExists(res.data.is_chat);
          }
        })
        .then((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    loadData();
  }, [userId]);

  useEffect(() => {
    const newSuggestions = skills?.map((skill) => ({
      id: skill.id,
      name: skill.name,
    }));
    setSuggestions(newSuggestions);
  }, [skills]);

  const sendJobOffer = () => {
    const formData = {
      user_id: userId,
      business_id: business_id,
      workforce_profile_id: profileId,
      job_vacancy_id: selectedJobVacancy,
      offer_valid_until: offerValidUpto,
      proposed_job_start_date: proposedStartDate,
      salary_range_type: salaryType,
      salary: decimalValue,
    };
    console.log(offerValidUpto, "validity date");
    if (selectedJobVacancy == 0) {
      toast.warning("Select job vacancy");
    } else if (decimalValue == null) {
      toast.warning("Please enter a salary value");
    } else if (offerValidUpto == null) {
      toast.warning("Enter offer validity");
    } else if (proposedStartDate == null) {
      toast.warning("Select a start date");
    } else {
      setIsButtonClicked(true);
      axios
        .post("/send-job-offer", formData)
        .then((res) => {
          console.log(res);
          setIsButtonClicked(false);
          if (res.success == true) {
            toast.success(res.message);
            tog_grid();
            loadData();
            window.location.reload();
          }
        })
        .then((err) => {
          console.log(err);
          setIsButtonClicked(false);
        });
    }
  };

  const getVacancyDetails = (vacancyId) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
      vacancy_id: vacancyId,
    };
    axios
      .post("/get-job-vacancy-detail", formData)
      .then((res) => {
        console.log(res);
        setVacancyDetails(res.data);
      })
      .then((err) => {
        console.log(err);
      });
  };
  const clearJobVacancyFields = () => {
    setSelectedJobVacancy(0);
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const addToTeam = () => {
    const formData = {
      workforce_profile_id: profileId,
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/add-to-my-team", formData)
      .then((res) => {
        console.log(res);
        tog_assignModal();
        toast.success(res.message);
        window.location.reload();
      })
      .then((err) => {
        console.log(err);
      });
  };
  const addTalentPool = () => {
    addToTalentPool();
    loadData();
    setmodal_assignTalent(false);
  };

  // Message Button
  const [messageModal, setMessageModal] = useState(false);
  const [message, setMessage] = useState("");
  const [messageData, setMessageData] = useState("");

  const messageContent = () => {
    if (businessData && businessData.business_name) {
      setMessage(
        `I came across your profile and was impressed by your experience and skills. We have a role at ${businessData.business_name} that I think you’d be perfect for. Are you available for a chat?`
      );
    }
  };

  const messageModalOpen = () => {
    setMessageModal(!messageModal);
    messageContent();
  };

  const handleSendMessage = () => {
    const formData = {
      message: message,
      message_id: null,
      receiver_id: workforceUserId,
      user_id: userId,
      business_id: business_id,
    };
    axios
      .post("/send-business-chat", formData)
      .then((res) => {
        console.log(res);
        messageModalOpen();
        toast.success("Message sent successfully");
        setMessageData(res);
      })
      .then((err) => {
        console.log(err);
      });
  };
  // -------- End ----------- //

  document.title = "Manage Profile | Get Rostered Workforce";

  const ReadMore = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const toggleReadMore = () => setIsExpanded(!isExpanded);

    if (text) {
      // Only show "Read more" if the text length exceeds 75 characters
      const shouldShowReadMore = text && text.length > 75;

      return (
        <div>
          <p
            className={`text-muted mb-1 fst-italic ${
              isExpanded ? "" : "text-truncate-two-lines"
            }`}
          >
            {isExpanded ? text : `${text.slice(0, 75)}`}
            {shouldShowReadMore && (
              <span
                onClick={toggleReadMore}
                style={{ color: "#865ce2", cursor: "pointer" }}
              >
                {isExpanded ? " Show less" : " Read more"}
              </span>
            )}
          </p>
        </div>
      );
    }
  };

  const getRelativeTime = (reviewDate) => {
    const now = new Date();
    const reviewDateObj = new Date(reviewDate);

    const differenceInTime = now.getTime() - reviewDateObj.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

    if (differenceInDays === 0) {
      return "Today";
    } else if (differenceInDays === 1) {
      return "Yesterday";
    } else if (differenceInDays === -1) {
      return "Tomorrow";
    } else if (differenceInDays > 1) {
      return `${differenceInDays} days ago`;
    } else if (differenceInDays < -1) {
      return `In ${Math.abs(differenceInDays)} days`;
    } else {
      return reviewDate; // fallback to the original date format
    }
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          {!isLoading ? (
            <>
              <Row>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <Card className="card-body profilecard">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          {console.log(userProfile?.profile_pics)}

                          {userProfile?.profile_pics ? (
                            <img
                              src={userProfile?.profile_pics}
                              alt="user-img"
                              className="avatar-lg rounded-circle"
                            />
                          ) : (
                            <img
                              style={{ width: "100px" }}
                              src={userDummy}
                              alt="user-img"
                              className="img-thumbnail userDummy rounded-circle"
                            />
                          )}
                        </div>
                        <div className="flex-grow-1 ms-4">
                          <h5 className="card-title searchprofiletitle mb-0">
                            {userDetails?.first_name} {userDetails?.last_name}
                          </h5>
                          <p className="location_set_area mb-0">
                            <img
                              src={LocationIcon}
                              alt="Location icon"
                              className="search-location-icon"
                            />
                            <span className="search-location-text searchprofiletext">
                              &nbsp;
                              {userDetails?.suburb ? userDetails?.suburb : "--"}
                            </span>
                          </p>
                          <h6 className="mb-1 d-flex align-items-center">
                            {/* <span
                              className="star-container"
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {[1, 2, 3, 4, 5].map((star) => (
                                <i
                                  className="ri-star-s-fill"
                                  key={star}
                                  style={{
                                    fontSize: "20px",
                                    color: star <= userRating ? "gold" : "gray",
                                    marginRight: "2px",
                                  }}
                                ></i>
                              ))}
                            </span> */}
                            <Rating
                              readonly
                              initialRating={userRating}
                              emptySymbol={
                                <i className="mdi mdi-star-outline text-muted custom-star-size"></i>
                              }
                              fullSymbol={
                                <i className="mdi mdi-star text-warning custom-star-size"></i>
                              }
                            />
                            <span
                              className="profileamount"
                              style={{ marginLeft: "8px" }}
                            >
                              {preferences?.desired_hourly_rate
                                ? `$ ${preferences?.desired_hourly_rate} / Hr`
                                : "$- / Hr"}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col md={6} className="buttondiv">
                  <div className="button-container">
                    {isTalentPool ? (
                      <>
                        {jobStatus == null ? (
                          <Button
                            color="primary"
                            className="searchprofilebtn"
                            style={{ marginRight: "5%" }}
                            onClick={() => {
                              setmodal_grid(true);
                            }}
                          >
                            <i className="bx bx-link-external align-middle fs-16 me-2"></i>
                            Send Job Offer
                          </Button>
                        ) : // <button
                        //   className="searchprofilebtn"
                        //   onClick={ setmodal_grid(true) }
                        // >
                        //   <i className="bx bxs-send me-1" /> Send Job Offer
                        // </button>
                        jobStatus.offer_status_id === 1 ? null : (
                          <>
                            {jobStatus.offer_status_id === 2 &&
                            all_permissions?.includes(
                              "add_workforce_member"
                            ) ? (
                              // <button
                              //   className="searchprofilebtn"

                              //   onClick={() => tog_assignModal(true)}
                              // >
                              //   <i className="bx bxs-send me-1" /> Add to Team
                              // </button>
                              <Button
                                color="primary"
                                className="searchprofilebtn"
                                /*onClick={() => tog_assignModal(true)}*/
                                onClick={() => {
                                  handleclick();
                                }}
                                style={{ marginRight: "5%" }}
                              >
                                <i className="bx bx-plus align-middle fs-16 me-1"></i>
                                Add to Team
                                <img
                                  src={crownwhite}
                                  height={25}
                                  className="header-crown"
                                  alt="icon"
                                />
                              </Button>
                            ) : null}
                          </>
                        )}
                      </>
                    ) : isSearchWorkforce &&
                      all_permissions?.includes("add_workforce_member") ? (
                      <>
                        {console.log(talent, "isTalent")}
                        {isTalent ? null : (
                          <Button
                            color="primary"
                            className="searchprofilebtn"
                            onClick={() => {
                              tog_assignTalent();
                            }}
                            style={{ marginRight: "5%" }}
                          >
                            <i className="bx bx-plus align-middle fs-16 me-1"></i>
                            Add to Talent pool
                            {/* <img src={crownwhite} height={25} className="header-crown"  alt="icon"  /> */}
                          </Button>
                        )}
                      </>
                    ) : isJobApplication &&
                      all_permissions?.includes("add_workforce_member") ? (
                      <>
                        {talent == null ? (
                          <Button
                            color="primary"
                            className="searchprofilebtn"
                            onClick={() => {
                              tog_assignTalent();
                            }}
                            style={{ marginRight: "5%" }}
                          >
                            <i className="bx bx-plus align-middle fs-16 me-1"></i>
                            Add to Talent Pool
                            {/* <img src={crownwhite} height={25} className="header-crown"  alt="icon"  /> */}
                          </Button>
                        ) : (
                          <div
                            className="text-primary me-3"
                            style={{
                              // border: "1px solid #7f4bda",

                              padding: "10px",
                            }}
                          >
                            <h6 className="text-primary align-middle fs-14 mt-2 ">
                              <i className="ri-information-line align-middle fs-15 me-2"></i>{" "}
                              {text}
                            </h6>
                          </div>
                        )}
                      </>
                    ) : null}
                    {userId !== workforceUserId && (
                      <Button
                        color="primary"
                        outline
                        className="searchprofilebtn"
                        onClick={() => {
                          if (isChatExists) {
                            console.log(
                              "chat exists .. navigating to chats page"
                            );
                            navigate("/chat", {
                              state: { profileId: workforceUserId },
                            });
                          } else {
                            messageModalOpen();
                          }
                        }}
                      >
                        <i className="bx bxs-envelope align-middle fs-16 me-2"></i>
                        Message
                      </Button>
                    )}
                  </div>
                </Col>
                <hr style={{ borderWidth: "2px", borderColor: "#7F4BDA" }} />
              </Row>
              <Row>
                <Col md={5}>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Verifications
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          {verificationDocument?.length > 0 ? (
                            verificationDocument.map((document, index) => (
                              <p className="card-text" key={index}>
                                {document.document_type_name}
                                <i
                                  className="bx bxs-check-circle label-icon align-middle fs-16 me-2"
                                  style={{ color: "#7f4bda" }}
                                ></i>
                              </p>
                            ))
                          ) : (
                            <span
                              className="fs-12 me-2"
                              style={{ fontStyle: "italic" }}
                            >
                              No Data Found
                            </span>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Availability
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <div className="mb-0">
                            {currentAvailability?.total_hours && (
                              <>
                                <div className="mb-3">
                                  <span>Hours Available</span>
                                  <span style={{ marginLeft: "10px" }}>
                                    {currentAvailability.total_hours} Hrs
                                  </span>
                                </div>
                              </>
                            )}
                            {!currentAvailability?.total_hours && (
                              <div className="mb-0">
                                <span
                                  className="fs-12 me-2"
                                  style={{ fontStyle: "italic" }}
                                >
                                  No Data Found
                                </span>
                              </div>
                            )}

                            <div>
                              {availability ? (
                                availability.map((availabilityItem, index) => (
                                  <span
                                    key={index}
                                    className="badge badge-soft-success fs-12 me-2"
                                  >
                                    {availabilityItem.substring(0, 3)}
                                  </span>
                                ))
                              ) : (
                                <span
                                  className="fs-12 me-2 mb-0"
                                  style={{ fontStyle: "italic" }}
                                >
                                  No Data Found
                                </span>
                              )}
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Education
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          {availability ? (
                            education?.length > 0 ? (
                              education.map((educationItem, index) => (
                                <div
                                  key={index}
                                  className="education-container subcard mb-3"
                                >
                                  <div className="justify-content-between">
                                    <h5>{educationItem.institution_name}</h5>
                                    <span className="text-gray">
                                      {new Date(
                                        educationItem.start_date
                                      ).getFullYear()}{" "}
                                      -{" "}
                                      {new Date(
                                        educationItem.end_date
                                      ).getFullYear()}
                                    </span>
                                  </div>
                                  <div className="mt-2">
                                    <span>{educationItem.degree}</span>
                                    {", "}
                                    <span className="text-muted">
                                      {educationItem.field_of_study}
                                    </span>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <span
                                className="fs-12 me-2 mb-0"
                                style={{ fontStyle: "italic" }}
                              >
                                No Data Found
                              </span>
                            )
                          ) : null}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Certifications
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          {certificates?.length > 0 ? (
                            certificates.map((certificate, index) => (
                              <div
                                key={index}
                                className="certificate-container subcard mb-3"
                              >
                                <h5>{certificate.certification_name}</h5>
                                <div className="text-muted mt-1">
                                  {certificate.issuing_authority}
                                </div>
                                <div className="d-flex justify-content-between">
                                  <div style={{ fontWeight: "bold" }}>
                                    <span className="text-success">
                                      Issued on:{" "}
                                    </span>
                                    <span className="text-success">
                                      {certificate.date_issued}
                                    </span>
                                  </div>
                                  <div style={{ fontWeight: "bold" }}>
                                    <span className="text-danger">
                                      Expiry:{" "}
                                    </span>{" "}
                                    <span className="text-danger">
                                      {certificate.expiration_date}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          ) : (
                            <span
                              className="fs-12 me-2"
                              style={{ fontStyle: "italic" }}
                            >
                              No Data Found
                            </span>
                          )}
                          <div className="text-end"></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Preferred Locations
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          {location?.length > 0 ? (
                            location.map((location, index) => (
                              <div
                                key={index}
                                className="certificate-container subcard"
                              >
                                <p className="location_set_area mb-1">
                                  <img
                                    src={LocationIcon}
                                    alt="Location icon"
                                    className="search-location-icon"
                                  />
                                  <span className="search-location-text searchprofiletext ms-2">
                                    &nbsp;
                                    {location.location}
                                  </span>
                                </p>
                                {/*<div className="text-muted mt-1">
                                  {location.location}
                                </div>*/}
                              </div>
                            ))
                          ) : (
                            <span
                              className="fs-12 me-2"
                              style={{ fontStyle: "italic" }}
                            >
                              No Data Found
                            </span>
                          )}
                          <div className="text-end"></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md={7}>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            About Me
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h5 className="mb-2 text-d-primary">
                            {data?.profile_details?.profile_title}
                          </h5>
                          {data?.profile_details?.profile_summary?.length >
                          0 ? (
                            <p
                              dangerouslySetInnerHTML={{
                                __html:
                                  data &&
                                  data.profile_details &&
                                  data.profile_details.profile_summary,
                              }}
                            ></p>
                          ) : (
                            <p
                              className="fs-12 me-2 mb-0"
                              style={{ fontStyle: "italic" }}
                            >
                              No Data Found
                            </p>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Skills and Qualifications
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          {skills && skills.length > 0 ? (
                            skills.map((skill, index) => (
                              <span
                                key={index}
                                className="badge badge-soft-primary fs-12 me-2"
                                style={{ color: "#272727", margin: "5px 0px" }}
                              >
                                {skill.name}
                              </span>
                            ))
                          ) : (
                            <span
                              className="fs-12 me-2"
                              style={{ fontStyle: "italic" }}
                            >
                              No Data Found
                            </span>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Employment History
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <div className="profile-timeline">
                            <div></div>
                            <div
                              className="accordion accordion-flush"
                              id="todayExample"
                            >
                              {history?.length > 0 ? (
                                history.map((historyItem, index) => (
                                  <div
                                    key={index}
                                    className="accordion-item border-0"
                                  >
                                    <div className="accordion-header">
                                      <button
                                        className="accordion-button p-2 shadow-none"
                                        type="button"
                                        id="headingOne"
                                      >
                                        <div className="d-flex">
                                          <div className="flex-grow-1 ms-3">
                                            <h5 className="mb-1">
                                              {historyItem.role},{" "}
                                              {historyItem.company_name}
                                            </h5>
                                            <p
                                              style={{ fontWeight: "bold" }}
                                              className="text-muted"
                                            >
                                              {historyItem.location}
                                            </p>
                                            <p>
                                              {formatDate(
                                                historyItem.start_date
                                              )}{" "}
                                              -{" "}
                                              {historyItem.end_date == null ? (
                                                "Present"
                                              ) : (
                                                <>
                                                  {formatDate(
                                                    historyItem.end_date
                                                  )}
                                                </>
                                              )}
                                            </p>
                                            <p className="text-dark">
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    historyItem.description,
                                                }}
                                              ></span>
                                            </p>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <span
                                  className="fs-12 me-2"
                                  style={{ fontStyle: "italic" }}
                                >
                                  No Data Found
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="text-end"></div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Employee Reviews
                          </h6>
                        </CardHeader>
                        <CardBody>
                          {review && review.length > 0 ? (
                            <div
                              style={{
                                maxHeight: "250px", // Adjust this height to fit two reviews
                                overflowY: "auto",
                              }}
                              className="scrollable-div"
                            >
                              {review.map((reviewItem, index) => (
                                <Card
                                  key={index}
                                  className="border border-dashed mb-3"
                                  style={{ boxShadow: "0px 2px 5px #bdafdc" }}
                                >
                                  <CardBody>
                                    <Row>
                                      <Col
                                        md={2}
                                        className="d-flex align-items-center justify-content-center"
                                      >
                                        <div className="avatar-title bg-white">
                                          {reviewItem.business_profile ? (
                                            <img
                                              src={`${reviewItem.business_profile}`}
                                              alt="business-img"
                                              className="avatar-sm rounded"
                                            />
                                          ) : (
                                            <img
                                              src={industryDummy}
                                              alt="business-img"
                                              className="avatar-sm rounded"
                                            />
                                          )}
                                        </div>
                                      </Col>
                                      <Col md={10}>
                                        <div className="d-flex justify-content-between align-items-center mb-1">
                                          <label className="mb-0 fs-15">
                                            <b>
                                              {reviewItem.business_owner_name}
                                            </b>
                                          </label>
                                          <div className="d-flex align-items-center">
                                            <p className="mb-0 me-2 fs-10 fst-italic">
                                              {getRelativeTime(
                                                reviewItem.review_date
                                              )}
                                            </p>
                                            <div className="star-size">
                                              <Rating
                                                readonly
                                                initialRating={
                                                  reviewItem.ratings
                                                }
                                                emptySymbol="mdi mdi-star-outline text-muted custom-star"
                                                fullSymbol="mdi mdi-star text-warning custom-star"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <label className="fs-11 profiletitle">
                                          <i>
                                            <b>{reviewItem.business_name}</b>
                                          </i>
                                        </label>
                                        <ReadMore text={reviewItem.reviews} />
                                      </Col>
                                    </Row>
                                  </CardBody>
                                </Card>
                              ))}
                            </div>
                          ) : (
                            <div className="text-left mt-0">
                              <span
                                className="fs-12"
                                style={{ fontStyle: "italic" }}
                              >
                                No Data Found
                              </span>
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                {/*<div className="justify-content-between d-flex align-items-center mt-3 mb-4">
                  <h5 className="mb-0 pb-1 profiletitle">Portfolio</h5>
                </div>*/}
                <Col lg={12}>
                  <Card className="border card-border-light profiledetail">
                    <CardHeader className="cardheader">
                      <h6 className="card-title profiletitle mb-0">
                        Portfolio
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <Swiper
                        slidesPerView={3}
                        pagination={{ el: ".swiper-pagination", pagination }}
                        modules={[Pagination, Autoplay]}
                        loop={true}
                        autoplay={{ delay: 1500, disableOnInteraction: false }}
                        className="mySwiper swiper responsive-swiper rounded gallery-light pb-0"
                      >
                        <div className="swiper-wrapper">
                          {portfolio?.length > 0 ? (
                            portfolio.map((portfolioItem, index) => (
                              <SwiperSlide key={index}>
                                <div
                                  className="gallery-box card"
                                  style={{ padding: "22px !important" }}
                                >
                                  <div className="gallery-container">
                                    {portfolioItem.image_file_name ? (
                                      <img
                                        src={`${s3portfolioUrl}${portfolioItem.image_file_name}`}
                                        alt=""
                                        className="gallery-img mx-auto imgstyle"
                                      />
                                    ) : (
                                      <img
                                        src={portdummy}
                                        alt=""
                                        className="gallery-img mx-auto imgstyle"
                                      />
                                    )}
                                  </div>
                                  <div className="box-content">
                                    <div className="d-flex align-items-center mt-1">
                                      <div className="flex-grow-1 text-muted">
                                        <h4 className="card-title mb-2">
                                          {portfolioItem.name}
                                        </h4>
                                        <p className="card-text">
                                          {showMoreList[index]
                                            ? stripHtmlTags(
                                                portfolioItem?.description
                                              )
                                            : portfolioItem?.description
                                                .length > 120
                                            ? `${stripHtmlTags(
                                                portfolioItem.description
                                              ).substr(0, 120)}...`
                                            : stripHtmlTags(
                                                portfolioItem.description
                                              )}
                                          {portfolioItem.description.length >
                                            120 && (
                                            <span
                                              className="btn btn-link p-0 ps-1"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                toggleShowMore(index);
                                              }}
                                            >
                                              {showMoreList[index]
                                                ? "Read Less"
                                                : "Read More"}
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            ))
                          ) : (
                            <span
                              className="fs-12 me-2"
                              style={{ fontStyle: "italic" }}
                            >
                              No Data Found
                            </span>
                          )}
                        </div>
                        <div className="swiper-pagination swiper-pagination-dark"></div>
                      </Swiper>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row>
                <Col md={6}>
                  <div className="d-flex align-items-center">
                    <Card className="card-body profilecard">
                      <div className="d-flex align-items-center">
                        <h3 className=" placeholder-glow ">
                          <img
                            src={userDummy}
                            alt="user-img"
                            className="avatar-lg rounded-circle placeholder-glow"
                          />
                        </h3>
                      </div>
                    </Card>
                  </div>
                </Col>
                <Col md={6} className="buttondiv">
                  <div className="button-container">
                    <h3 className=" placeholder-glow ">
                      <span className="placeholder col-3"></span>
                    </h3>
                    <h4 className=" placeholder-glow mt-2">
                      <span className="placeholder col-5"></span>
                    </h4>
                    <h4 className=" placeholder-glow mt-1">
                      <span className="placeholder col-9"></span>
                    </h4>
                  </div>
                </Col>
                <hr style={{ borderWidth: "2px", borderColor: "#7F4BDA" }} />
              </Row>
              <Row>
                <Col md={5}>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Verifications
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Availability
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Education
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Certifications
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Col md={7}>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            About Me
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Skills and Qualifications
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Employment History
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Card className="border card-border-light profiledetail">
                        <CardHeader className="cardheader">
                          <h6 className="card-title profiletitle mb-0">
                            Employee Reviews
                          </h6>
                        </CardHeader>
                        <CardBody className="cardbody">
                          <h3 className=" placeholder-glow ">
                            <span className="placeholder col-3"></span>
                          </h3>
                          <h4 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h4>
                          <h4 className=" placeholder-glow mt-1">
                            <span className="placeholder col-9"></span>
                          </h4>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <div className="justify-content-between d-flex align-items-center mt-3 mb-4">
                  <h5 className="mb-0 pb-1 profiletitle">Portfolio</h5>
                </div>
                <Col lg={12}>
                  <Card>
                    <h3 className=" placeholder-glow ">
                      <span className="placeholder col-3"></span>
                    </h3>
                    <h4 className=" placeholder-glow mt-2">
                      <span className="placeholder col-5"></span>
                    </h4>
                    <h4 className=" placeholder-glow mt-1">
                      <span className="placeholder col-9"></span>
                    </h4>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </Container>

        <Modal
          isOpen={messageModal}
          toggle={messageModalOpen}
          className="modal-dialog-center-left"
        >
          <ModalHeader
            toggle={messageModalOpen}
            id="myModalLabel"
          ></ModalHeader>
          <div className="modal-body text-center ps-5 pe-5 pt-0">
            <Row className="justify-content-center mb-2">
              <Col
                lg={12}
                className="d-flex align-items-center justify-content-center "
              >
                <i className="bx bxs-message-rounded-detail messageIcon"></i>
              </Col>
            </Row>
            <Row className="justify-content-center mb-2">
              <Col
                lg={12}
                className="d-flex align-items-center justify-content-center "
              >
                <h5 className="messageHeading">
                  Initial Outreach with {userDetails?.first_name}{" "}
                  {userDetails?.last_name}
                </h5>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                lg={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div>
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea5"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    rows={4}
                    cols={50}
                  />
                </div>
              </Col>
            </Row>
            <Row className="mb-4">
              <Col
                lg={12}
                className="d-flex align-items-center justify-content-center"
              >
                <div className="d-flex flex-wrap gap-2">
                  <Button
                    className="w-lg cancelButton"
                    color="primary"
                    onClick={messageModalOpen}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="w-lg confirmButton"
                    color="primary"
                    onClick={handleSendMessage}
                  >
                    Send
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          isOpen={modal_grid}
          centered
          size="lg"
          modalClassName="zoomIn"
          toggle={() => {
            tog_grid();
          }}
        >
          <ModalHeader>
            <h5 className="modal-title">
              {userDetails?.first_name} {userDetails?.last_name}
            </h5>
          </ModalHeader>
          <ModalBody>
            <div className="row g-3">
              <Col lg={6}>
                <div>
                  <label htmlFor="firstName" className="form-label">
                    Select Job Vacancy
                  </label>{" "}
                  <span className="text-danger">*</span>
                  <select
                    className="form-select mb-2"
                    value={selectedJobVacancy}
                    onChange={(e) => {
                      setSelectedJobVacancy(e.target.value);
                      setIsSelected(true);
                      getVacancyDetails(e.target.value);
                    }}
                  >
                    <option value={0} disabled={true}>
                      Select..
                    </option>
                    {jobVacancyDropdown?.map((vacancy) => (
                      <option key={vacancy.id} value={vacancy.id}>
                        {" "}
                        {vacancy.title}{" "}
                      </option>
                    ))}
                  </select>
                </div>
              </Col>
              <Col lg={2}>
                <div>
                  <label htmlFor="lastName" className="form-label">
                    Salary Offered
                  </label>{" "}
                  <span className="text-danger">*</span>
                  <select
                    className="form-select mb-2"
                    value={salaryType}
                    onChange={(e) => setSalaryType(e.target.value)}
                  >
                    <option value="Hourly">Hourly</option>
                    <option value="Weekly">Weekly</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Yearly">Yearly</option>
                  </select>
                </div>
              </Col>
              <Col lg={4}>
                <label
                  htmlFor="lastName"
                  className="form-label "
                  style={{ visibility: "hidden" }}
                >
                  rate
                </label>

                <div className="input-group">
                  <div className="input-group-text bg-soft-info">$</div>
                  <Input
                    className="form-control"
                    value={decimalValue}
                    onChange={handleInputChange}
                    placeholder="00.00"
                  >
                    ${" "}
                  </Input>
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <label className="form-label">Offer valid until</label>{" "}
                  <span className="text-danger">*</span>
                  <Flatpickr
                    className="form-control"
                    value={offerValidUpto}
                    options={{
                      dateFormat: "Y-m-d",
                      altInput: true,
                      altFormat: "d-m-Y",
                      minDate: "today",
                    }}
                    onChange={(selectedDate) => {
                      console.log(selectedDate, "selecteddate");
                      if (selectedDate.length > 0) {
                        setOfferValidUpto(
                          moment(selectedDate[0]).format("YYYY-MM-DD")
                        );
                      } else {
                        setOfferValidUpto(null);
                      }
                    }}
                  />
                </div>
              </Col>
              <Col lg={6}>
                <div>
                  <label className="form-label">Proposed Job Start Date</label>{" "}
                  <span className="text-danger">*</span>
                  <Flatpickr
                    className="form-control"
                    value={proposedStartDate}
                    options={{
                      dateFormat: "Y-m-d",
                      altInput: true,
                      altFormat: "d-m-Y",
                      minDate: "today",
                    }}
                    onChange={(selectedDate) => {
                      if (selectedDate) {
                        setProposedStartDate(
                          moment(selectedDate[0]).format("YYYY-MM-DD")
                        );
                      } else {
                        setProposedStartDate(null);
                      }
                    }}
                  />
                </div>
              </Col>
              <hr className="mt-3" />
              {isSelected && vacancyDetails ? (
                <div className="mt-0">
                  <h5> {vacancyDetails.title}</h5>
                  <div className="hstack gap-2 align-items-center mt-2">
                    <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>
                    <h6 className="mt-0">
                      $ {vacancyDetails.salary_range_from} to ${" "}
                      {vacancyDetails.salary_range_to}{" "}
                      {vacancyDetails.salary_range_type}
                    </h6>
                  </div>
                  <div className="hstack gap-2 mt-2">
                    <i className="text-danger fs-18 me-1 bx bx-map-pin"></i>
                    <h6>{vacancyDetails.location}</h6>
                  </div>
                  <div className="mt-3">
                    {" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: vacancyDetails.description,
                      }}
                    ></span>{" "}
                  </div>
                </div>
              ) : null}

              <div className="col-lg-12">
                <div className="hstack gap-2 justify-content-end">
                  <Button
                    color="light"
                    onClick={() => {
                      setmodal_grid(false);
                      clearJobVacancyFields();
                    }}
                  >
                    Close
                  </Button>
                  {/*<Button
                    color="primary"
                    onClick={() => {
                      sendJobOffer();
                      setIsButtonClicked(true);
                    }}
                    className={` ${
                      isButtonClicked ? "text-white" : "text-white"
                    }`}
                    disabled={isButtonClicked ? true : false}
                  >
                    {isButtonClicked ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                      </Spinner>
                    ) : null}
                    Send Job Offer
                  </Button>*/}
                  <Button
                    color="primary"
                    onClick={() => sendJobOffer()}
                    className={`${
                      isButtonClicked ? "text-white" : "text-white"
                    }`}
                    disabled={isButtonClicked}
                  >
                    {isButtonClicked ? (
                      <Spinner size="sm" className="me-2"></Spinner>
                    ) : null}
                    Send Job Offer
                  </Button>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          size="md"
          isOpen={modal_assignModal}
          toggle={() => {
            tog_assignModal();
          }}
          centered
        >
          <ModalHeader toggle={tog_assignModal}></ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col lg={4}>
                {console.log(userProfile?.profile_pics)}

                {userProfile?.profile_pics ? (
                  <img
                    src={userProfile?.profile_pics}
                    alt="user-img"
                    style={{ height: "9rem", width: "9rem" }}
                    className=" img-thumbnail avatar-xxs rounded-circle"
                  />
                ) : (
                  <img
                    src={userDummy}
                    alt="user-img"
                    style={{ height: "9rem", width: "9rem" }}
                    className=" img-thumbnail avatar-xxs rounded-circle"
                  />
                )}
              </Col>
              <Col lg={8}>
                <div className="confirmationText">
                  <h5 className="text-left">
                    Ready to add this talented individual to your Team?
                  </h5>
                  <p className="subText">Confirm to proceed</p>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      className="w-lg cancelButton"
                      color="primary"
                      onClick={tog_assignModal}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-lg confirmButton"
                      color="primary"
                      onClick={() => {
                        addToTeam();
                        tog_assignModal();
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <SiteDetailPopup
          showPopup={showPopup}
          profileId={profileId}
          handleclick={() => handleclick()}
          addToTeam={() => addToTeam()}
          talentPool={true}
        />
        {/*<Modal
          size="md"
          isOpen={modal_assignTalent}
          toggle={() => {
            tog_assignTalent();
          }}
        >
          <ModalHeader></ModalHeader>
          <ModalBody className="text-center">
            <h5 className="text-center">
              Ready to add this talented individual to your Talent Pool?
            </h5>

            <h5 className="mt-3">Confirm to proceed. </h5>

            <lord-icon
              src="https://cdn.lordicon.com/xzalkbkz.json"
              trigger="loop"
              style={{ width: "100px", height: "100px" }}
            ></lord-icon>
            <Row className="mt-5 d-flex justify-content-center">
              <Col lg={3} className=" hstack gap-3 p-0 ">
                <Button
                  className="btn btn-dark"
                  onClick={() => setmodal_assignTalent(false)}
                >
                  Cancel
                </Button>
                <Button className="btn btn-success" onClick={addTalentPool}>
                  Confirm
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>*/}
        <Modal
          size="md"
          isOpen={modal_assignTalent}
          toggle={() => {
            tog_assignTalent();
          }}
          centered
        >
          <ModalHeader toggle={tog_assignTalent}></ModalHeader>
          <ModalBody className="text-center">
            <Row>
              <Col lg={4}>
                {console.log(userProfile?.profile_pics, "qqqqq123")}
                {userProfile?.profile_pics ? (
                  <img
                    src={userProfile?.profile_pics}
                    alt="user-img"
                    style={{ height: "9rem", width: "9rem" }}
                    className=" img-thumbnail avatar-xxs rounded-circle"
                  />
                ) : (
                  <img
                    src={userDummy}
                    alt="user-img"
                    style={{ height: "9rem", width: "9rem" }}
                    className=" img-thumbnail avatar-xxs rounded-circle"
                  />
                )}
              </Col>
              <Col lg={8}>
                {/* fghfh */}
                <div className="confirmationText">
                  <h5 className="text-left">
                    Ready to add this talented individual to your Talent Pool?
                  </h5>
                  <p className="subText">Confirm to proceed</p>
                  <div className="d-flex flex-wrap gap-2">
                    <Button
                      className="w-lg cancelButton"
                      color="primary"
                      // outline
                      onClick={() => setmodal_assignTalent(false)}
                      // className="btn btn-talent btn-sm mt-auto"
                    >
                      Cancel
                    </Button>
                    <Button
                      className="w-lg confirmButton"
                      color="primary"
                      // style={styles.confirmButton}
                      onClick={() => {
                        addToTalentPool();
                        setmodal_assignTalent(false);
                      }}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default MyProfile;
