import React from 'react';
import {
  Card,
  CardBody,
  Col,
  Container,
  Button
} from "reactstrap";
import logoPurple from './assets/images/logopurple.png';

const PrivacyPolicy = () => {
  return (
    <Container >
   <Card style={{ marginTop: '2.5rem', marginBottom: '2.5rem', padding: '2.5rem' }}>
    <CardBody>
    <div style={{ position: 'relative' }}>
      
      <h2 style={{ marginTop: 0, marginBottom: 0, fontWeight: 600, lineHeight: 1.2 }}>GET ROSTERED PTY LTD</h2>
      <img
              src={logoPurple}
              alt="Logo Purple"
              height={50}
              style={{
                position: 'absolute',
                top: 0,
                right: 40,
              }}
            />
      <p>Australian Company Number 676 787 554</p>

      <h4>PRIVACY POLICY AND NOTICE</h4>
      <p>This is the privacy notice of Get Rostered. In this document, &quot;we&quot; or &quot;us&quot; refers to Get Rostered Pty Ltd. Our registered office is at Victoria, Australia.</p>
      <p>This notice explains our policy about all information that we record about you. It covers both information that could identify you and information that could not. We are extremely concerned to protect your privacy and confidentiality. Our policy complies with Australian law and with the laws of all jurisdictions of which we are aware.</p>
      <p>If you disagree with any part of this privacy policy, please do not use our platform. Except as set out below, we do not share, sell, or disclose to a third party any personally identifiable information collected on our platform.</p>

      <h5>1. Business and Personal Information</h5>
    <p>This includes basic identification and contact information such as your name and contact details. It also includes all information given to us in the course of your business and ours. We undertake to preserve the confidentiality of the information and the terms of our relationship. It is not used for any other purpose. We expect you to reciprocate this policy.</p>
    <p>This information is used:</p>
    <ul>
        <li>To provide you with the services you request.</li>
        <li>For verifying your identity for security purposes.</li>
        <li>For marketing our services and products.</li>
        <li>Information that does not identify any individual may be used in a general way by us or third parties to provide class information, for example, relating to demographics or usage of a particular page or service.</li>
    </ul>
    <p>We keep information which forms part of our business record for a minimum of six years.</p>
    
    <h5>2. Market Place Information</h5>
    <p>When we obtain information from you specifically to enable you to buy a service offered on our platform by another person, we assume that in giving us your information, you are also giving us permission to pass it to the relevant person.</p>
    
    <h5>3. Your Domain Name and E-mail Address</h5>
    <p>This information is recognised by our servers and the pages that you visit are recorded. We shall not under any circumstances divulge your e-mail address to any person who is not an employee or contractor of ours and who does not need to know either generally or specifically. This information is used:</p>
    <ul>
        <li>To correspond with you or deal with you as you expect.</li>
        <li>In a collective way not referable to any particular individual for the purpose of quality control and improvement of our site.</li>
        <li>To send you news about the services to which you have signed up.</li>
        <li>To tell you about other services we offer.</li>
    </ul>
    
    <h5>4. Information You Post on Our Platform</h5>
    <p>Information you send to us by posting to a forum or blog or in your advertisement is stored on our servers. We do not specifically use that information except to allow it to be read. However, you will see in our terms and conditions that we reserve a right to use it in any way we decide.</p>
    
    <h5>5. Website Usage Information</h5>
    <p>We may use software embedded in our platform (such as JavaScript) to collect information about pages you view, how you have reached them, what you do when you visit a page, the length of time you remain on the page, and how we perform in providing content to you.</p>
    
    <h5>6. Financial Information Relating to Your Credit Cards</h5>
    <p>This information is never taken by us either through our platform or otherwise. At the point of payment, you are transferred to a secure page on the website of a reputable payment service provider. That page may be dressed in our “livery” but it is not controlled by us. Our staff and contractors never have access to it.</p>
    
    <h5>7. Third Party Advertising</h5>
    <p>Third parties may advertise on our platform. In doing so, those parties, their agents, or other companies working for them may use technology that automatically collects your IP address when they send an advertisement that appears on our platform to your browser. They may also use other technology such as cookies or JavaScript to personalize the content of and to measure the performance of their adverts. We do not have control over these technologies or the data that these parties obtain. Accordingly, this privacy notice does not cover the information practices of these third parties.</p>
    
    <h5>8. Cookies</h5>
    <p>Cookies are small text files that are placed on your computer's hard drive through your web browser when you visit any website. They are widely used to make websites work more efficiently, as well as to provide information to the owners of the site. Like all other users of cookies, we may request the return of information from your computer when your browser requests a web page from our server. Cookies enable our web server to identify you to us and to track your actions and the pages you visit while you use our platform.</p>
    <p>The cookies we use may last for a single visit to our platform (they are deleted from your computer when you close your browser) or may remain on your computer until you delete them or until a defined period has passed.</p>
    <p>Although your browser software enables you to disable cookies, we recommend that you allow the use of cookies to take advantage of the features of our platform that rely on their use. If you prevent their use, you will not be able to use all the functionality of our platform.</p>
    
    <h5>9. Calling Our Help Line</h5>
    <p>When you call our help line, we collect Calling Line Identification (CLI) information. We use this information to help improve the efficiency and effectiveness of our help line.</p>
    
    <h5>10. Sending a Message to Our Support System</h5>
    <p>When you send a message, we collect the data you have given to us in that message to obtain confirmation that you are entitled to receive the information and to provide you with the information you need. We record your request and our reply to increase the efficiency of our business. We do not keep any personally identifiable information associated with your message such as your name or email address.</p>
    
    <h5>11. Complaining</h5>
    <p>When we receive a complaint, we record all the information you have given to us. We use that information to resolve your complaint. If your complaint reasonably requires us to contact some other person, we may decide to give that other person some of the information contained in your complaint. We do this as infrequently as possible, but it is a matter for our sole discretion as to whether we do give information and if we do, what that information is.</p>
    <p>We may also compile statistics showing information obtained from this source to assess the level of service we provide but not in a way that could identify you or any other person.</p>
    
    <h5>12. Job Application and Employment</h5>
    <p>If you send us information in connection with a job application, we may keep it for up to three years in case we decide to contact you at a later date. If we employ you, we collect information about you and your work from time to time throughout the period of your employment. This information will be used only for purposes directly relevant to your employment. After your employment has ended, we will keep your file for six years before destroying or deleting it.</p>
    
    <h5>13. Re-marketing</h5>
    <p>We may use re-marketing from time to time. This involves Google or some other supplier placing a tag or marker on your website in order to serve you an advert for our products/services when you visit some other website.</p>
    
    <h4>14. Affiliate Information</h4>
    <p>This is information given to us by you in your capacity as an affiliate of us or a customer or client of ours. Such information is retained for business use only. We undertake to preserve the confidentiality of the information and the terms of our relationship. It is not used for any other purpose.</p>
    
    <h5>15. Use of Site by Children</h5>
    <p>We do not market to children nor do we sell products or services for purchase by children. We do sell products and services for end use by children but for purchase by adults. If you are under 18, you may use our site only with consent from a parent or guardian.</p>
    
    <h5>16. Disclosure to Government and Their Agencies</h5>
    <p>We are subject to the law like everyone else. We may be required to give information to legal authorities if they so request or if they have the proper authorization such as a search warrant or court order.</p>
    
    <h5>17. Compliance with the Law</h5>
    <p>This confidentiality policy has been compiled to comply with the law of every jurisdiction in which we aim to do business. If you think it fails to satisfy the law of your country, we should like to hear from you but ultimately it is your choice as to whether you wish to use our platform.</p>
    
    <h5>18. Review or Update Personally Identifiable Information</h5>
    <p>At any time, you may review or update the personally identifiable information that we hold about you by contacting us at the address below. To better safeguard your information, we will also take reasonable steps to verify your identity before granting access or making corrections to your information.</p>
    
    <h5>19. Removal of Your Information</h5>
    <p>If you wish us to remove personally identifiable information from our platform, you may contact us at <a href="mailto:support@getrostered.app">support@getrostered.app</a>. To better safeguard your information, we will also take reasonable steps to verify your identity before granting access or making corrections to your information.</p>
    
    <h5>20. Data May Be “Processed” Outside Australia</h5>
    <p>Our platforms are hosted in Australia. We also use outsourced services in countries outside Australia from time to time in other aspects of our business. Accordingly, data obtained within Australia may be “processed” outside Australia, and data obtained in any other country may be processed within or outside that country.</p>
    
    <h5>21. Complaints Procedure</h5>
    <p>If you have a complaint about how we have collected or handled your personal information, please contact us. We will endeavour to deal with your complaint and take action to resolve the matter. If your complaint cannot be resolved, we will ask you to lodge a formal complaint in writing explaining the circumstances of the matter that you are complaining about, how you believe your privacy has been interfered with, and how you believe your complaint should be resolved. We will acknowledge receipt of your formal complaint and indicate the timeframe you can expect a response. We will endeavour to resolve the complaint as quickly as possible but if the matter is complex, we will let you know when we expect to provide our response. If you are unhappy with our response, you may refer your complaint to the Office of the Australian Information Commissioner.</p>
    
    <h6>How You Can Contact Us</h6>
    <p>Email: <a href="mailto:support@getrostered.app">support@getrostered.app</a></p>
    
    <h6>Office of the Australian Information Commissioner (OAIC) Contact Details</h6>
    <p>Email: <a href="mailto:enquiries@oaic.gov.au">enquiries@oaic.gov.au</a></p>
    
    <h6>Change in Privacy Policy</h6>
    <p>As we plan to ensure our privacy policy remains current, this policy is subject to change. Please return periodically to review our privacy policy.</p>
    
    <p>If you have any questions regarding this privacy policy, please contact us through the contact page.</p>

    </div>
    </CardBody>
    </Card>
    </Container>
  );
};

export default PrivacyPolicy;
