import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  FormFeedback,
  Input,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import GoogleAutocomplete from "react-google-autocomplete";
import AuthSlider from "../AuthenticationInner/authCarousel";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import logoPurple from "../../assets/images/logopurple.png";
import "react-toastify/dist/ReactToastify.css";
//formik
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";

const Register = () => {
  document.title = "Register | Get Rostered Business";
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [businessAddress, setBusinessAddress] = useState("");
  const [email, setEmail] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [businessNameError, setBusinessNameError] = useState("");
  const [businessAddressError, setBusinessAddressError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [checkboxError, setCheckboxError] = useState("");
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [address, setAddress] = useState("");
  const [addressError, setAddressError] = useState("");
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedSiteSuburb, setselectedSiteSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [selectedStateCode, setselectedStateCode] = useState();
  const [country, setCountry] = useState([]);
  // const [authUserId, setAuthUserId] = useState();
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    const { lat, lng } = place.geometry.location;
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    // state_id updated
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedSiteSuburb(selectedSuburb);
    setselectedStateCode(selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value.trim());
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value.trim());
  };

  const handleBusinessNameChange = (e) => {
    setBusinessName(e.target.value);
    setBusinessNameError("");
  };

  const handleBusinessAddressChange = (e) => {
    setBusinessAddress(e.target.value.trim());
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value.trim());
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);

    // Validate password
  };
  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);

    // Validate password
  };

  const handleSubmit = async (e) => {
    // setIsLoading(true);
    e.preventDefault();

    // Check if the checkbox is checked

    // Perform your validation here
    if (
      !validateFirstName(firstName) ||
      !validateLastName(lastName) ||
      !validateEmail(email) ||
      !validateBusinessName(businessName) ||
      !validateBusinessAddress(address) ||
      !validatePassword(password) ||
      !validateConfirmPassword(confirmPassword)
    ) {
      // Validation failed, don't proceed with submission
      setIsLoading(false);
      return;
    }

    if (!isCheckboxChecked) {
      // Display an error message
      setCheckboxError(
        "Please agree to the Terms of Service and Privacy Policy"
      );
      return;
    }

    let formData = {
      first_name: firstName,
      last_name: lastName,
      email: email,
      password: password,
      country_name: country,
      post_code: selectedPostcode,
      state_code: selectedStateCode,
      suburb: selectedSuburb,
      site_address: selectedCity,
      site_name: selectedSiteSuburb,
      latitude: latitude,
      longitude: longitude,
      business_name: businessName
    };
    console.log(formData,'formData1321');
    // Validation passed, proceed with form submission
    console.log("Validation passed. Register calling here");
    localStorage.setItem('registrationFormData', JSON.stringify(formData));
    // Axios request
    try {
      const response = await axios.post("/register", formData);
      console.log(response.status);

      if (
        response.status == "205" ||
        response.status == "200"
      ) {
        navigate("/twoStepVerify", { state: { formData: formData  } });
        setIsLoading(false);
      } else if (response.status == "209") {
        toast.warning("User already signed up. Redirecting to login page", { autoClose: 2000 });
        setTimeout(() => {
          navigate("/login");
        }, 2000);
        setIsLoading(false);
      } else {
        setConfirmPasswordError("Password does not meet policy requirements: It must contain at least one special symbol character", {
          autoClose: 2000,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsLoading(false);
    }
  };
  const validateFirstName = (value) => {
    // Validate non-empty and character set, allowing apostrophe
    if (!value || !/^[a-zA-Z']+$/u.test(value)) {
      // Display an error message
      setFirstNameError("Please enter a valid first name");
      return false;
    }
    setFirstNameError("");
    return true;
  };
  const validateLastName = (value) => {
    // Validate non-empty and character set
    if (!value || !/^[a-zA-Z']+$/u.test(value)) {
      // Display an error message
      setLastNameError("Please enter a valid last name");
      return false;
    }
    setLastNameError("");
    return true;
  };
  const validateBusinessName = (value) => {
    // Validate non-empty and character set
    if (!value) {
      // Display an error message
      setBusinessNameError("Please enter a valid business name");
      return false;
    }
    setBusinessNameError("");
    return true;
  };
  const validateBusinessAddress = (value) => {
    // Validate non-empty and character set
    if (!value) {
      // Display an error message
      setBusinessAddressError("Please enter a valid business address");
      return false;
    }
    setBusinessAddressError("");
    return true;
  };
  
  const validateEmail = (value) => {
    // Simple email format validation
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      // Display an error message
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };
  const validatePassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value || !isValid) {
      setPasswordError(
        "Password must have 8 characters, contain at least one number, one uppercase letter, and one lowercase letter."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };
  const validateConfirmPassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value) {
      setConfirmPasswordError("Please Re-enter your password.");
      return false;
    } else if (value != password || !isValid) {
      setConfirmPasswordError("Passwords do not match!");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };
  

  return (
    <React.Fragment>
      <Row className="register-bg m-0">
        <AuthSlider />

        <Col lg={6} className="custom-col register-small-device ps-0 pe-0">
          <div className="p-5 pt-4 pb-4 d-flex justify-content-center align-items-center">
            <div className="mt-0 reg-content-width">
              <form
                className="needs-validation align-items-center"
                noValidate
                onSubmit={handleSubmit}
              >
                <div className="text-center logo-mobile">
                  <img
                    src={logoPurple}
                    alt=""
                    className="text-center"
                    height={50}
                  />
                </div>
                <div className="font-mobile text-center mt-3">
                  <Label>Create New Account</Label>
                </div>
                <div className=" text-center  fs-14 mt-md-1 mt-sm-0  mt-3 mb-0">
                  <Label>Enter Your information below and join us!</Label>
                </div>
                <div className="p-4 pb-0 pt-2 position-relative">
                  <i
                    className="ri-user-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <Input
                    type="text"
                    className={`form-control pe-5 ${
                      firstNameError ? "is-invalid" : ""
                    }`}
                    id="firstName"
                    placeholder="Enter first name"
                    value={firstName}
                    onChange={handleFirstNameChange}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  />
                  {firstNameError && (
                    <div className="invalid-feedback">{firstNameError}</div>
                  )}
                </div>
                <div className="p-4 pt-3 pb-0 position-relative">
                  <i
                    className="ri-user-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <Input
                    type="text"
                    className={`form-control pe-5 ${
                      lastNameError ? "is-invalid" : ""
                    }`}
                    id="lastName"
                    placeholder="Enter last name"
                    value={lastName}
                    onChange={handleLastNameChange}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  />
                  {lastNameError && (
                    <div className="invalid-feedback">{lastNameError}</div>
                  )}
                </div>
                <div className="p-4 pt-3  pb-0 position-relative">
                  <i
                    className="ri-building-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <Input
                    type="text"
                    className={`form-control pe-5 ${
                      businessNameError ? "is-invalid" : ""
                    }`}
                    id="businessName"
                    placeholder="Enter business name"
                    value={businessName}
                    onChange={handleBusinessNameChange}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  />
                  {businessNameError && (
                    <div className="invalid-feedback">{businessNameError}</div>
                  )}
                </div>
                <div className="p-4 pt-3 pb-0 position-relative">
                  <i
                    className="ri-map-pin-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <GoogleAutocomplete
                    className={`form-control ${
                      businessAddressError ? "is-invalid" : ""
                    }`}
                    apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                      setBusinessAddressError("");
                    }}
                    onPlaceSelected={(place) => residentialplace(place)}
                    options={{
                      types: ["geocode"],
                      componentRestrictions: {
                        country: "au",
                      },
                    }}
                    style={{
                      borderRadius: "10px",
                      paddingLeft: "2.5rem",
                    }}
                    placeholder="Enter business address"
                  />
                  {/* <Input
                    type="text"
                    className={`form-control pe-5 ${
                      businessAddressError ? "is-invalid" : ""
                    }`}
                    id="businessAddress"
                    placeholder="Enter business address"
                    value={businessAddress}
                    onChange={handleBusinessAddressChange}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  /> */}
                  {businessAddressError && (
                    <div className="invalid-feedback">
                      {businessAddressError}
                    </div>
                  )}
                </div>
                <div className="p-4 pt-3 pb-0 position-relative">
                  <i
                    className="ri-mail-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <Input
                    type="email"
                    id="useremail"
                    placeholder="Enter email address"
                    className={`form-control pe-5 ${
                      emailError ? "is-invalid" : ""
                    }`}
                    value={email}
                    onChange={handleEmailChange}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                </div>
                <div className="p-4 pt-3 pb-0 position-relative auth-pass-inputgroup">
                  <i
                    className="ri-lock-2-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <Input
                    type={passwordShow ? "text" : "password"}
                    className={`form-control pe-5 password-input ${
                      passwordError ? "is-invalid" : ""
                    }`}
                    placeholder="Enter password"
                    id="password-input"
                    name="password"
                    value={password}
                    onChange={handlePasswordChange}
                    onPaste={(e) => e.preventDefault()}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  />
                  <Button
                    color="link"
                    onClick={() => setPasswordShow(!passwordShow)}
                    className="btn btn-link position-absolute me-4 mt-3 end-0 top-0 text-decoration-none text-muted"
                    type="button"
                    id="password-addon"
                  >
                    <i className="ri-eye-fill align-middle"></i>
                  </Button>
                  {passwordError && (
                    <div className="invalid-feedback">{passwordError}</div>
                  )}
                </div>
                <div className="p-4 pt-3 pb-0 position-relative auth-pass-inputgroup">
                  <i
                    className="ri-lock-2-fill position-absolute mt-2 ms-2 icon-left"
                    style={{ color: "grey" }}
                  ></i>
                  <Input
                    type={confirmPasswordShow ? "text" : "password"}
                    className={`form-control pe-5 password-input ${
                      confirmPasswordError ? "is-invalid" : ""
                    }`}
                    placeholder="Confirm password"
                    id="password-input"
                    name="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    onPaste={(e) => e.preventDefault()}
                    required
                    style={{ borderRadius: "10px", paddingLeft: "2.5rem" }}
                  />
                  <Button
                    color="link"
                    onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                    className="btn btn-link position-absolute me-4 mt-3 end-0 top-0 text-decoration-none text-muted"
                    type="button"
                    id="password-addon"
                  >
                    <i className="ri-eye-fill align-middle"></i>
                  </Button>
                  {confirmPasswordError && (
                    <div className="invalid-feedback">
                      {confirmPasswordError}
                    </div>
                  )}
                </div>
                <div className="form-check mt-2 ms-4">
                  <Input
                    className="form-check-input"
                    type="checkbox"
                    id="auth-remember-check"
                    onChange={() => {
                      setIsCheckboxChecked(!isCheckboxChecked);
                      setCheckboxError(""); // Clear the error message when the checkbox state changes
                    }}
                  />
                  <Label
                    className="form-check-label"
                    htmlFor="auth-remember-check"
                    style={{ fontWeight: "200", fontSize: "12px" }}
                  >
                    I agree with the{" "}
                    <span
                      style={{
                        color: "#48008A",
                        fontWeight: "300",
                        fontSize: "13px",
                      }}
                    >
                      Terms of Services
                    </span>{" "}
                    and{" "}
                    <span
                      style={{
                        color: "#48008A",
                        fontWeight: "300",
                        fontSize: "13px",
                      }}
                    >
                      Privacy Policy
                    </span>
                  </Label>
                  {checkboxError && (
                    <div className="text-danger">{checkboxError}</div>
                  )}
                </div>
                <div className="p-3 pb-0">
                  <Button
                    disabled={isLoading}
                    className="btn w-100 text-white"
                    type="submit"
                    style={{
                      background: "#48008A",
                      padding: "0.5rem 1rem",
                      fontSize: "15px",
                      textAlign: "center",
                      fontWeight: "400",
                      borderRadius: "10px",
                    }}
                  >
                    {isLoading ? (
                      <Spinner size="sm" className="me-2">
                        Loading...
                      </Spinner>
                    ) : null}
                    Sign Up
                  </Button>
                </div>{" "}
                <div className="mt-3 p-2 text-center">
                  <p
                    className="mb-0"
                    style={{ textAlign: "center", fontSize: "15px" }}
                  >
                    Joining an existing account?{" "}
                    <Link
                      to="/login"
                      className="fw-semibold"
                      style={{ fontWeight: "300", color: "black" }}
                    >
                      Click here
                    </Link>
                  </p>
                </div>
                <div className="mt-4 text-center">
                  <p className="mb-0">
                    By signing up, you agree to our{" "}
                    <Link
                      to="/terms-of-use"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="terms-link"
                    >
                      Terms of Use
                    </Link>{" "}
                    and{" "}
                    <Link
                      to="/privacy-policy"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="terms-link"
                    >
                      Privacy Policy
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </Col>
      </Row>

      <ToastContainer />
    </React.Fragment>
  );
};

export default Register;
