import PrismCode from "../../../Components/Common/Prism";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  Alert,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  UncontrolledAlert,
  TabContent,
  TabPane,
  UncontrolledTooltip,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import img13 from "../../../assets/images/small/userimage.png";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { get_cookie } from "../../../helpers/get_cookie";
import { isEmpty } from "lodash";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import classnames from "classnames";
import "../../../assets/scss/pages/_settings.scss";
import TableContainer from "../../../Components/Common/TableContainer";
import DeleteModal from "../../../Components/Common/DeleteModal";
import Select from "react-select";
import {
  CardHeader,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  ModalFooter,
  Table,
  FormFeedback,
} from "reactstrap";
//Import actions
import {
  getContacts as onGetContacts,
  addNewContact as onAddNewContact,
  updateContact as onUpdateContact,
  deleteContact as onDeleteContact,
} from "../../../store/actions";
//redux
import { useSelector, useDispatch } from "react-redux";
import userDummy from "../../../assets/images/users/user-dummy-img.jpg";
import GetrosterTableContainer from "../../../Components/Common/GetrosterTableContainer";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import MyProfile from "../../SearchWorkforce/ProfileView";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../../config";
import { useParams } from "react-router-dom";
import LocationIcon from "../../../assets/images/location.svg";

//reloading the page
const override = jsx`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export const AssignedPeople = () => {
  document.title = "Assigned People | Get Rostered";
  const params = useParams();
  const site_id = params.id;
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  const [modal, setModal] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [archive, setArchive] = useState([]);
  const [data, setData] = useState([]);
  const [contact, setContact] = useState([]);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [uploadedImage, setUploadedImage] = useState("");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [authUserId, setAuthUserId] = useState();
  const [userId, setUserId] = useState();
  const [modal_assignModal, setmodal_assignModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [openProfileId, setOpenProfileId] = useState();
  const [modal_grid, setmodal_grid] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalMulti, setDeleteModalMulti] = useState(false);
  const [sitedetails, setsitedetails] = useState();
  const [memberlist, setmemberlist] = useState();
  const [location, setLocation] = useState();
  const [teamdetails, setteamdetails] = useState();

  const [modal_large, setModal_Company] = useState(false);
  //job Modal
  const [selectedmemberId, setselectedmemberId] = useState();
  const [member_id_error, setMemberIdError] = useState("");
  function tog_grid() {
    setmodal_grid(!modal_grid);
  }

  useEffect(() => {
    setUserId(JSON.parse(get_cookie("authUser")).user_id);
    console.log(userId);
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        setUserId(res.id);
        loadTable(res.id,"Potential");
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const toggleDefaultCanvas = () => {
    setOpen(!open);
  };

  const toggle = useCallback(() => {
    if (modal) {
      setModal(false);
      tog_Company();
      setContact(null);
    } else {
      setModal(true);
      setTag([]);
      setAssignTag([]);
    }
  }, [modal]);

  
  const [currentStatus, setCurrentStatus] = useState("1"); // Default to "Active"
  // Handle tab switching
  const addTableRows = () => {
    const rowsInput = {
      fullName: "",
      emailAddress: "",
      salary: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };

  const dispatch = useDispatch();

  //react
  const isContactSuccess = true;
  const error = null;
  const crmcontacts = data;

  const loadTable = (userId, status) => {
    const formData = {
      user_id: userId,
      business_id: business_id,
      site_id: site_id, // Add status to the form data
    };
    setisLoading(true);
    axios.post("/get-site-member", formData).then((res) => {
        console.log(res.data);
        setData(res.siteData);
        setteamdetails(res.site_name);
        setsitedetails(res.siteData);
        setmemberlist(res.memberlist);
        setLocation(res.site_location);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };
  const selectedMemberNames = (data || []).map((row) => row.full_name);

// Filter the memberlist to remove already selected members
const availableMembers = (memberlist || []).filter(
  (item) => !selectedMemberNames.includes(item.full_name)
);
  //react
  useEffect(() => {
    setContact(crmcontacts);
  }, [crmcontacts]);

  useEffect(() => {
    if (!isEmpty(crmcontacts)) {
      setContact(crmcontacts);
      setIsEdit(false);
    }
  }, [crmcontacts]);

  // Delete Data
  const handleDeleteContact = () => {
    if (contact) {
      dispatch(onDeleteContact(contact._id));
      setDeleteModal(false);
    }
  };
  const onClickDelete = (contact) => {
    setContact(contact);
    setDeleteModal(true);
  };
  //delete Conatct

  function tog_Company() {
    setModal_Company(!modal_large);
  }

  // Add Data
  const handleContactClicks = () => {
    setContact("");
    setIsEdit(false);
    toggle();
  };
  const dateFormat = () => {
    var d = new Date(),
      months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
    return d.getDate() + " " + months[d.getMonth()] + ", " + d.getFullYear();
  };
  // validation
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // img: (contact && contact.img) || '',
      name: (contact && contact.name) || "",
      company: "",
      designation: "",
      email: (contact && contact.email) || "",
      phone: (contact && contact.phone) || "",
      lead_score: (contact && contact.lead_score) || "",
      tags: (contact && contact.tags) || [],
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter Name"),
      company: Yup.string().required("Please Enter Company"),
      designation: Yup.string().required("Please Enter Designation"),
      email: Yup.string().required("Please Enter Email"),
      phone: Yup.string().required("Please Enter Phone"),
      lead_score: Yup.string().required("Please Enter lead_score"),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        const updateContact = {
          _id: contact ? contact._id : 0,
          // img: values.img,
          name: values.name,
          company: values.company,
          designation: values.designation,
          email: values.email,
          phone: values.phone,
          lead_score: values.lead_score,
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // update Contact
        dispatch(onUpdateContact(updateContact));
        validation.resetForm();
      } else {
        const newContact = {
          _id: (Math.floor(Math.random() * (30 - 20)) + 20).toString(),
          // img: values["img"],
          name: values["name"],
          company: values["company"],
          designation: values["designation"],
          email: values["email"],
          phone: values["phone"],
          lead_score: values["lead_score"],
          last_contacted: dateFormat(),
          // time: timeFormat(),
          tags: assignTag,
        };
        // save new Contact
        dispatch(onAddNewContact(newContact));
        validation.resetForm();
      }
      toggle();
    },
  });

  // Update Data
  const handleContactClick = useCallback(
    (arg) => {
      const contact = arg;

      setContact({
        _id: contact._id,
        // img: contact.img,
        name: contact.name,
        company: contact.company,
        email: contact.email,
        designation: contact.designation,
        phone: contact.phone,
        lead_score: contact.lead_score,
        last_contacted: contact.date,
        // time: contact.time,
        tags: contact.tags,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );

  const [order, setOrder] = useState([]);

  function tog_assignModal() {
    setmodal_assignModal(!modal_assignModal);
  }


  const handleOrderClick = useCallback(
    (arg) => {
      const order = arg;
      setOrder({
        _id: order._id,
        orderId: order.orderId,
        customer: order.customer,
        product: order.product,
        orderDate: order.orderDate,
        ordertime: order.ordertime,
        amount: order.amount,
        payment: order.payment,
        status: order.status,
      });

      setIsEdit(true);
      toggle();
    },
    [toggle]
  );
  
const [selectedMember, setSelectedMember] = useState({
  id: null,
  status: null,
  image: null,
});



const handleIconClick = (id, status, image_file_name) => {
  const imageUrl = image_file_name ? `${image_file_name}` : null;

  setSelectedMember({
    id,
    status,
    image: imageUrl,
  });
  tog_assignModal(); // Open the modal
};


const handleConfirmClick = () => {
  //updateTeamMemberStatus(selectedMember.id, selectedMember.status);
  tog_assignModal(); // Close the modal after the action
};

  // Checked All
  const checkedAll = useCallback(() => {
    const checkall = document.getElementById("checkBoxAll");
    const ele = document.querySelectorAll(".contactCheckBox");

    if (checkall.checked) {
      ele.forEach((ele) => {
        ele.checked = true;
      });
    } else {
      ele.forEach((ele) => {
        ele.checked = false;
      });
    }
    deleteCheckbox();
  }, []);

  // Delete Multiple
  const [selectedCheckBoxDelete, setSelectedCheckBoxDelete] = useState([]);
  const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);

  const deleteMultiple = () => {
    const checkall = document.getElementById("checkBoxAll");
    selectedCheckBoxDelete.forEach((element) => {
      dispatch(onDeleteContact(element.value));
      setTimeout(() => {
        toast.clearWaitingQueue();
      }, 3000);
    });
    setIsMultiDeleteButton(false);
    checkall.checked = false;
  };

  const deleteCheckbox = () => {
    const ele = document.querySelectorAll(".contactCheckBox:checked");
    ele.length > 0
      ? setIsMultiDeleteButton(true)
      : setIsMultiDeleteButton(false);
    setSelectedCheckBoxDelete(ele);
  };
  const handleDeleteOrder = () => {
    var deleteval = {
      user_id:authUserId,
      business_id: business_id,
      site_id: site_id,
      workforce_id : contact.id,
    };
      axios
      .post("/delete-site-member", deleteval)
      .then((res) => {
        if(res.success === true){
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
        setDeleteModal(false);
      }else{
        toast.error(res.message);
        setDeleteModal(true);
      }
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  //react
  const smscolumn = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "full_name",
        filterable: false,
      },
      {
        Header: "Action",
        Cell: (cellProps) => {
          console.log(cellProps.row.original.id);
          return (
            <div className="d-flex gap-3">
              <Link
                  to="#"
                  className="text-danger"
                  onClick={() => {
                    const orderData = cellProps.row.original;
                    onClickDelete(orderData);
                  }}
                >
                  <i className="ri-delete-bin-5-fill fs-12 pt-1 btn btn-sm btn-soft-danger" id="deletetooltip"></i>
                  <UncontrolledTooltip placement="top" target="deletetooltip">
                    Delete
                  </UncontrolledTooltip>
                </Link>
            </div>
          );
        }
      }
      
    
    ],
    [handleContactClick, checkedAll]
  );
  //react
  const [tag, setTag] = useState([]);
  const [assignTag, setAssignTag] = useState([]);

  // Export Modal
  const [isExportCSV, setIsExportCSV] = useState(false);

  //image selection
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [customHoverTab, setcustomHoverTab] = useState("1");

  const customHovertoggle = (tab) => {
    if (customHoverTab !== tab) {
      setcustomHoverTab(tab);
      const status = tab === "1" ? "Potential" : "Rejected"; // Determine status based on tab
      loadTable(userId, status); // Call loadTable with userId and status
    }
  };
  
  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const objectUrl = URL.createObjectURL(selectedFile);
      setUploadedImage(selectedFile);
      setSelectedImage(objectUrl);
      setIsImageSelected(true);
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  function handlememberChange(selectedOption){
    setselectedmemberId({ value: selectedOption.value, label: selectedOption.label });
  }
  const openModal = () => {
    setselectedmemberId("");
    setModal(true);
  };
  const saveRoster = (event) => {
    event.preventDefault();
    var datavalue = {
      user_id: authUserId,
      business_id: business_id,
      site_id:site_id,
      selectedmemberId: selectedmemberId.value,
    };
    if (selectedmemberId === "") {
      setMemberIdError("Please select name.");
    } else {
      setMemberIdError("");
    }
    if (selectedmemberId === "") {
      return;
    }
    axios
      .post("/save-site-memberdetails", datavalue)
      .then((res) => {
        if(res.error === 0){
        toast.success(res.message, { theme: "light" });
        loadTable(authUserId);
        setModal(false);
      }else{
         toast.error(res.message, { theme: "light" });
        setModal(true);
      }
      })
      .catch((error) => {
        console.error("Error fetching lead types:", error);
      });
  };
  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Row>
          <Col lg={9}>
          <BreadCrumb title={teamdetails} />
          {location && (
            <p className="location_set_area mb-0">
              <img
                src={LocationIcon}
                alt="Location icon"
                className="search-location-icon"
              />
              <span className="search-location-text searchprofiletext">
                &nbsp;
                {location}
              </span>
            </p>
          )}
          </Col>
          <Col lg={3}>
          <button
                      className="btn ms-2 mt-1"
                      style={{
                        backgroundColor: "#865ce2",
                        color: "white",
                        float: "right",
                        padding: "10px",
                      }}
                      onClick={openModal}
                    >
                      <i className="ri-add-circle-fill me-1 align-bottom"></i>{" "}
                      Add New
                    </button></Col>
          </Row>
          <DeleteModal
            show={deleteModal}
            orderId={selectedOrderId}
            onDeleteClick={handleDeleteOrder}
            onCloseClick={() => setDeleteModal(false)}
          />
        <Modal
            className="zoomIn"
            id="addSeller"
            size="lg"
            isOpen={modal}
            toggle={toggle}
            centered
          >
            <ModalHeader toggle={toggle}>Add New Member</ModalHeader>
            <ModalBody>
                  <Row>
                    <Col lg={12}>
                      <div className="mb-3">
                        <Label for="firstnameInput" className="form-label mb-3">
                          Name
                        </Label>
                        <Select
                          value={selectedmemberId}
                          className={`${member_id_error ? "is-invalid" : ""}`}
                          onChange={(selectedOption) => {
                            handlememberChange(selectedOption);
                            setMemberIdError("");
                          }}
                          options={
                            availableMembers && Array.isArray(availableMembers)
                              ? availableMembers.map((item) => ({
                                  value: item.id,
                                  label: item.full_name,
                                }))
                              : []
                          }
                        />
                        {member_id_error && (
                          <div className="invalid-feedback">
                            {member_id_error}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={12}>
                      <div className="hstack gap-2 justify-content-end">
                        <button
                          className="btn btn-light"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            setModal(false);
                          }}
                        >
                          Close
                        </button>
                        <button
                          type="submit"
                          className="btn btn-primary"
                          onClick={(event) => saveRoster(event)}
                        >
                          <i className="ri-save-3-line align-bottom me-1"></i>{" "}
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
            </ModalBody>
          </Modal>
          <Row className="mt-3">
            <Col lg={12}>
              <Card id="contactList">
                <CardBody className="pt-0">
                  <div>
                    {isContactSuccess && crmcontacts.length ? (
                      <GetrosterTableContainer
                        columns={smscolumn}
                        data={crmcontacts || []}
                        isGlobalFilter={true}
                        isAddUserList={false}
                        customPageSize={100}
                        className="custom-header-css"
                        divClass="table-responsive table-card mb-3"
                        tableClass="align-middle table-nowrap"
                        theadClass="table-light"
                        handleContactClick={handleContactClicks}
                        isContactsFilter={true}
                        SearchPlaceholder="Search by name, profile title, location..."
                      />
                    ) : (
                      crmcontacts.length === 0 && (
                        <p style={{ textAlign: "center", marginTop: "10px" }}>
                          No records found
                        </p>
                      )
                    )}
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                  {isLoading && (
                    <div
                      style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: 9999,
                      }}
                    >
                      <Loader />
                    </div>
                  )}
                </CardBody>
              </Card>
             {/* <Modal
                isOpen={modal_grid}
                centered
                size="lg"
                modalClassName="zoomIn"
                toggle={() => {
                  tog_grid();
                }}
              >
                {isOpenLoading ? (
                  <ModalHeader>
                    <h5 className=" placeholder-glow mt-2">
                      <span className="placeholder col-3"></span>
                    </h5>
                  </ModalHeader>
                ) : (
                  <ModalHeader>
                    <h5 className="modal-title text-info">
                      {profileTitle?.first_name} {profileTitle?.last_name}
                    </h5>
                    <h6 className="text-muted"> {profileLocation}</h6>
                  </ModalHeader>
                )}

                <hr className="mb-0" />
                {isOpenLoading ? (
                  <ModalBody>
                    <div className="row g-3">
                      <Col lg={6}>
                        <div>
                          <label className="form-label">Salary Offered:</label>{" "}
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-3"></span>
                          </h5>
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div>
                          <label className="form-label">
                            Offer valid until:
                          </label>{" "}
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-3"></span>
                          </h5>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label className="form-label">
                            Proposed Job Start Date:
                          </label>{" "}
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-3"></span>
                          </h5>
                        </div>
                      </Col>
                      <hr className="mt-3 " />

                      <div className="mt-0">
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-4"></span>
                        </h5>
                        <div className="hstack gap-2 align-items-center mt-2">
                          <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>
                          <h6 className="mt-0"></h6>
                        </div>
                        <div className="hstack gap-2 mt-2">
                          <i className="text-danger fs-18 me-1 bx bx-map-pin"></i>
                          <h5 className=" placeholder-glow mt-2">
                            <span className="placeholder col-5"></span>
                          </h5>
                        </div>
                        <h5 className=" placeholder-glow mt-2">
                          <span className="placeholder col-4"></span>
                          <span className="placeholder col-7"></span>
                          <span className="placeholder col-8"></span>
                          <span className="placeholder col-10"></span>
                        </h5>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            color="danger"
                            onClick={() => {
                              setmodal_grid(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                ) : (
                  <ModalBody>
                    <div className="row g-3">
                      <Col lg={6}>
                        <div className="">
                          <label
                            htmlFor="firstName"
                            className="form-label hstack fs-14"
                          >
                            <i className="text-success bx bx-dollar-circle fs-18 me-1"></i>{" "}
                            Salary Offered: $ {salary} {salaryType}
                          </label>{" "}
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div>
                          <label className="form-label hstack fs-14">
                            <i className="text-warning bx bx-calendar fs-18 me-1"></i>{" "}
                            Offer valid until: {validity ? validity : "--"}
                          </label>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div>
                          <label className="form-label hstack fs-14">
                            <i className="text-warning bx bx-calendar fs-18 me-1"></i>{" "}
                            Proposed Job Start Date:{" "}
                            {startDate ? startDate : "--"}
                          </label>
                        </div>
                      </Col>
                      <hr className="mt-3 " />

                      <div className="mt-0">
                        <h5 className="text-primary">{vacancy}</h5>
                        <div className="hstack gap-2 align-items-center mt-2">
                          <h6 className="mt-0"></h6>
                        </div>
                        <div className="hstack gap-2 mt-2">
                          <i className="text-danger fs-18 me-1 bx bx-map-pin"></i>
                          <h6>{jobLocation}</h6>
                        </div>
                        <div className="mt-3">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: jobDescription,
                            }}
                          ></span>
                        </div>
                      </div>

                      <div className="col-lg-12">
                        <div className="hstack gap-2 justify-content-end">
                          <Button
                            color="danger"
                            onClick={() => {
                              setmodal_grid(false);
                            }}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </ModalBody>
                )}
              </Modal>*/}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
