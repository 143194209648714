import {
  Card,
  CardBody,
  Col,
  Row,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Nav,
  Table,
  Spinner,
  UncontrolledDropdown,
  UncontrolledTooltip,
} from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { isEmpty } from "lodash";
import Flatpickr from "react-flatpickr";
import "../../assets/scss/pages/_settings.scss";
import Select from "react-select";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import DeleteModal from "../../Components/Common/DeleteModal";
import { CardHeader, Label, Input } from "reactstrap";
import MyProfile from "../SearchWorkforce/ProfileView";
import { Button } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import Loader from "../../Components/Common/Loader";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link } from "react-router-dom";
//reloading the page
import { jsx } from "@emotion/react";
import { ClipLoader } from "react-spinners";
import { api } from "../../config";
import { get_cookie } from "../../helpers/get_cookie";

const WorkOrder = () => {
  const [authUserId, setAuthUserId] = useState();
  const [modal, setModal] = useState(false);
  const [workorderdate, setworkorderdate] = useState();
  const [assignedto, setassignedto] = useState();
  const [workorder, setworkorder] = useState();
  const [close_date_error, setCloseDateError] = useState("");
  // const [isLoading, setisLoading] = useState(false);
  const [salaryRangeTypeError, setSalaryRangeTypeError] = useState("");
  const [workOrderList, setWorkOrderList] = useState([]);
  const [selectedSingle, setSelectedSingle] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [headers, setHeaders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadings, setIsLoadings] = useState(false);
  const business_id = JSON.parse(get_cookie("authUser")).business_id;
  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        setAuthUserId(res.id);
        loadTable(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const loadTable = (id) => {
    setIsLoading(true);
    var datavalue = {
      user_id: id,
      business_id: business_id,
    };
    axios.post("/list-work-order", datavalue).then((res) => {
      console.log(res.data);
      setWorkOrderList(res.data);
      setIsLoading(false);
      getHeaders(res.data);
    });
  };


  const getHeaders = (data) => {
    let headers = Array.from(
      new Set(data.flatMap((item) => Object.keys(item.details)))
    );
    setHeaders(headers);
    console.log(headers, "headers console");
  };

  const LoadData = () => {
    let formData = {
      user_id: authUserId,
      business_id: business_id,
    };

    axios
      .post("/get-work-order-groups-with-templates", formData)
      .then((response) => {
        console.log(response, "heyyylowww");
        setworkorder(response.data);
        setassignedto(response.Assignedto);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [fieldValues, setFieldValues] = useState({});
  const [selectedvalueid, setselectedvalueid] = useState(null);

  const handleInputChange = (id, value, files = null) => {
    setFieldValues((prevValues) => ({
      ...prevValues,
      [id]: files ? files[0].name : value,
    }));
  };


  const handleSelectSingle = (selectedOption) => {
    setSelectedSingle(selectedOption);
    setselectedvalueid(selectedOption.value);
    setSalaryRangeTypeError("");
  };

  const [selectedRow, setSelectedRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const handleRowClick = (rowData) => {
    setSelectedRow(rowData);
    setModalOpen(true);
  };

  const toggle = () => {
    setModal(!modal);
    setSalaryRangeTypeError("");
    setCloseDateError("");
    // setworkorderdate("");
    // setselectedvalueid("");
    setselectedvalueid(null);
    setworkorderdate("");
    setFieldValues("");
  };

  const [details, setDetails] = useState({});

  const prepareDetails = () => {
    const newDetails = {};

    workorder.forEach((group) => {
      if (!group || !group.templates) {
        console.error("Group or templates are undefined");
        return;
      }

      group.templates.forEach((template) => {
        const value = fieldValues[template.id];
        if (value !== undefined) {
          newDetails[template.field_name] = value;
        }
      });
    });

    return newDetails;
  };

  const submit = () => {
    // Validate workorder and necessary fields before proceeding
    // if (!workorder || !workorderdate || !selectedvalueid) {
    //   toast.error("Please fill in Work date and Assignedto");
    //   return;
    // }
    setIsLoadings(true);
    if (!workorder || !workorderdate || !selectedvalueid) {
      setIsLoadings(false);
      toast.error("Please fill in Work order date and Assigned to");
      return;
    }

    // if(!workorderdate){
    //   setCloseDateError("Please select date for this work order");
    //   return;
    // }
    // else if(!selectedvalueid){
    //   setSalaryRangeTypeError("Assign a user to this work order.");
    // }

    // const formattedWorkorderDate = moment(workorderdate, 'DD MMM, YYYY').format('YYYY-MM-DD');

    const newDetails = prepareDetails();
    setDetails(newDetails); // Optionally set the state if you need it elsewhere

    const formData = {
      user_id: authUserId,
      business_id: business_id,
      work_order_date: workorderdate,
      assigned_workforce_profile_id: selectedvalueid,
      details: newDetails,
    };

    axios
      .post("/create-work-order", formData)
      .then((response) => {
        toast.success("Work order created successfully", { theme: "light" });
        loadTable(authUserId);
        LoadData();
        toggle();
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsLoadings(false);
      });
  };

  
  const renderField = (template) => {
    const commonProps = {
      value:
        fieldValues[template.id] !== undefined ? fieldValues[template.id] : "",
      onChange: (e) => handleInputChange(template.id, e.target.value),
    };
  console.log(template,"TEMPLATE");
    // Parse default_value for Select type
    const parseSelectOptions = (defaultValue) => {
      return defaultValue.split(',').map((value) => ({
        value,
        label: value, // Assuming the label is the same as the value; adjust if needed
      }));
    };
  
    switch (template.field_type) {
      case "Text":
        return (
          <div>
            <Input
              type="text"
              value = {template.default_value}
              className="form-control"
              placeholder="Enter Text"
              {...commonProps}
            />
          </div>
        );
      case "Date":
        return (
          <Col lg={12}>
            <div className="mb-3">
              <Flatpickr
                className="form-control"
                options={{ dateFormat: "d M, Y" }}
                value={
                  fieldValues[template.id] !== undefined
                    ? fieldValues[template.id]
                    : ""
                }
                onChange={(selectedDates) =>
                  handleInputChange(
                    template.id,
                    moment(selectedDates[0]).format("DD MMM, YYYY")
                  )
                }
                placeholder="Select Date"
              />
            </div>
          </Col>
        );
      case "Time":
        return (
          <Col md={12}>
            <div>
              <Input type="time" className="form-control" {...commonProps} />
            </div>
          </Col>
        );
      case "Currency":
        return (
          <Col lg={12}>
            <div className="input-group">
              <span className="input-group-text">$</span>
              <Input type="text" className="form-control" {...commonProps} />
              <span className="input-group-text">.00</span>
            </div>
          </Col>
        );
      case "DateTime":
        return (
          <Col lg={12}>
            <div className="mb-3">
              <Flatpickr
                className="form-control"
                options={{ enableTime: true, dateFormat: "d M, Y H:i" }}
                value={
                  fieldValues[template.id] !== undefined
                    ? fieldValues[template.id]
                    : ""
                }
                onChange={(selectedDates) =>
                  handleInputChange(
                    template.id,
                    moment(selectedDates[0]).format("DD MMM, YYYY HH:mm")
                  )
                }
                placeholder="Select Date and Time"
              />
            </div>
          </Col>
        );
      case "Number":
      case "Percentage":
        return (
          <div>
            <Input
              type="number"
              placeholder="Enter Number"
              className="form-control"
              {...commonProps}
            />
          </div>
        );
      case "File":
        return (
          <Col lg={12}>
            <div>
              <Input
                className="form-control"
                type="file"
                id="formFile"
                onChange={(e) =>
                  handleInputChange(template.id, e.target.files[0].name)
                }
              />
            </div>
          </Col>
        );
      case "Textarea":
        return (
          <Col lg={12}>
            <div className="mb-3">
              <textarea
                className="form-control"
                rows="4"
                placeholder="Enter description"
                {...commonProps}
              ></textarea>
            </div>
          </Col>
        );
      case "Email":
        return (
          <div>
            <Input
              type="email"
              className="form-control"
              placeholder="Enter Email"
              {...commonProps}
            />
          </div>
        );
      case "Select": {
        const options = parseSelectOptions(template.default_value);
        return (
          <Col lg={12}>
            <div className="mb-3">
              <select
                className="form-control"
                {...commonProps}
                onChange={(e) => handleInputChange(template.id, e.target.value)}
              >
                {options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </Col>
        );
      }
      default:
        return null;
    }
  };
  
  const openCreateModal = () => {
    setModal(true);
    LoadData();
    setselectedvalueid(null);
    setworkorderdate("");
    setFieldValues("");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <BreadCrumb title="Work Order" />
            <button
              className="btn btn-success"
              style={{
                backgroundColor: "#865ce2",
                borderColor: "#f8f5ff",
              }}
              onClick={openCreateModal}
            >
              New work order
            </button>
          </div>

          <Row className="mt-3">
            <Col lg={12}>
              <Card>
                <CardBody className="pt-0">
                  <Table className="table table-bordered mt-3  align-middle mb-3">
                    <thead>
                      <tr>
                        <th>Work Order Number</th>
                        <th>Work Order Date</th>
                        <th>Assigned User</th>
                        <th>Status</th>
                        {headers?.map((header) => (
                          <th key={header}>{header}</th>
                        ))}
                        <th style={{ width: "105px" }}>Action</th>
                      </tr>
                    </thead>

                    <tbody className="mb-3">
                      {workOrderList?.length > 0 ? (
                        workOrderList.map((item, index) => (
                          <tr key={index}>
                            <td>{item.work_order_number}</td>
                            <td>{item.work_order_date}</td>
                            <td>{item.assigned_user_name}</td>
                            <td>{item.status || "-"}</td>
                            {headers.map((header) => (
                              <td key={header}>
                                {item.details[header] || "-"}
                              </td>
                            ))}
                            <td>
                              <div className="hstack gap-1 flex-wrap">
                                <button className="btn btn-sm btn-soft-info edit-list">
                                  <i className="bx bxs-pencil fs-12 pt-1"></i>
                                </button>
                                <button className="btn btn-sm btn-soft-danger">
                                  <i className="ri-delete-bin-5-fill fs-12 pt-1"></i>
                                </button>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={headers.length + 5}
                            className="text-center"
                            style={{ color: "#888888" }}
                          >
                            <i>No work orders added.</i>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>

              {/* <Modal size="xl" isOpen={modal} toggle={toggle} centered> */}
              <Modal size="lg" isOpen={modal} centered>
                {/* <ModalHeader toggle={toggle}> */}
                <ModalHeader>
                  <h5 className="text-primary mb-3">New Work Order</h5>
                </ModalHeader>
                <ModalBody>
                  <Row className="mb-3">
                    <Col md={5}>
                      <Row>
                        <div className="d-flex align-items-center mb-2">
                          <Label className="mb-0">
                            Work Order Date{" "}
                            <span className="text-danger">*</span>
                          </Label>
                        </div>

                        <div className="mb-3">
                          <Flatpickr
                            className={`form-control ${
                              close_date_error ? "is-invalid" : ""
                            }`}
                            options={{
                              dateFormat: "Y-m-d", // Change date format here
                            }}
                            onChange={(selectedDates) => {
                              setworkorderdate(
                                moment(selectedDates[0]).format("YYYY-MM-DD")
                              ); // Update format here
                              setCloseDateError("");
                            }}
                            value={workorderdate}
                            placeholder="Select Date"
                            // style={{ width: '103%' }}
                          />
                          {close_date_error && (
                            <div className="invalid-feedback">
                              {close_date_error}
                            </div>
                          )}
                        </div>
                      </Row>
                      {/* <Row>
                        <Col md={4}>
                          <Label
                            for="workOrderDateInput"
                            className="form-label mt-2"
                          >
                            Work Order Date <span className="text-danger">*</span>
                          </Label>
                        </Col>

                        <Col md={8}>
                          <div className="mb-3">
                            <Flatpickr
                              className={`form-control ${
                                close_date_error ? "is-invalid" : ""
                              }`}
                              options={{
                                dateFormat: "Y-m-d", // Change date format here
                              }}
                              onChange={(selectedDates) => {
                                setworkorderdate(
                                  moment(selectedDates[0]).format("YYYY-MM-DD")
                                ); // Update format here
                                setCloseDateError("");
                              }}
                              value={workorderdate}
                              placeholder="Select Date"
                            />
                            {close_date_error && (
                              <div className="invalid-feedback">
                                {close_date_error}
                              </div>
                            )}
                          </div>
                        </Col>
                      </Row> */}
                    </Col>
                    <Col></Col>
                    <Col md={5}>
                      <Row>
                        <div className="d-flex align-items-center mb-2">
                          <Label className="mb-0">
                            Assigned To <span className="text-danger">*</span>
                          </Label>
                        </div>

                        <div className="mb-3">
                          <Select
                            //  className={`form-control ${
                            //   salaryRangeTypeError ? "is-invalid" : ""
                            // }`}
                            value={selectedSingle}
                            onChange={handleSelectSingle}
                            options={assignedto?.map((item) => ({
                              value: item.id,
                              label: `${item.first_name} ${item.last_name}`,
                            }))}
                          />
                          {salaryRangeTypeError && (
                            <div className="invalid-feedback">
                              {salaryRangeTypeError}
                            </div>
                          )}
                        </div>
                      </Row>
                      {/* <Row>
                        <Col md={4}>
                          <Label
                            for="assignedToInput"
                            className="form-label mt-2"
                          >
                            Assigned To <span className="text-danger">*</span>
                          </Label>
                        </Col>
                        <Col md={8}>
                          <Select
                            value={selectedSingle}
                            onChange={handleSelectSingle}
                            options={assignedto?.map((item) => ({
                              value: item.id,
                              label: `${item.first_name} ${item.last_name}`,
                            }))}
                          />
                          {salaryRangeTypeError && (
                            <div className="invalid-feedback">
                              {salaryRangeTypeError}
                            </div>
                          )}
                        </Col>
                      </Row> */}
                    </Col>
                    <Col md={1}></Col>
                  </Row>
                  <hr />
                  <div className="mb-3 mt-3">
                    {workorder?.map((group) => (
                      <div key={group.group_id} className="mb-3 mt-3">
                        <h5>{group.group_title}</h5>
                        <Row className="mb-3 mt-3">
                          {group.templates.map((template) => (
                            <React.Fragment key={template.id}>
                              <Col md={2}>
                                <Label className="form-label mt-2">
                                  {template.field_name}
                                </Label>
                              </Col>
                              <Col className="mb-3" md={4}>
                                {renderField(template)}
                              </Col>
                            </React.Fragment>
                          ))}
                        </Row>
                        <hr />
                      </div>
                    ))}
                    {/* <Row className="justify-content-end mt-4">
                      <Col md={1}>
                        <Button color="light" onClick={toggle}>
                          Cancel
                        </Button>
                      </Col>
                      <Col lg={2}>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={submit}
                        >
                          Create Work Order
                        </button>
                      </Col>
                    </Row> */}
                    <Row className="justify-content-end mt-4">
                      <Col md="auto" className="d-flex align-items-center">
                        <Button color="light" onClick={toggle} className="me-2">
                          Cancel
                        </Button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={submit}
                        >
                          Create Work Order
                        </button>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                {isLoadings && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      background: "rgba(255, 255, 255, 0.8)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      zIndex: 9999,
                    }}
                  >
                    <Loader />
                  </div>
                )}
              </Modal>
              {isLoading && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: 9999,
                  }}
                >
                  <Loader />
                </div>
              )}
            </Col>
          </Row>
          <ToastContainer />
        </Container>
      </div>
    </React.Fragment>
  );
};
export default WorkOrder;
