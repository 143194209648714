import { Card, CardBody, Col, NavLink, Row } from "reactstrap";
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { Button } from "reactstrap";
import { isEmpty } from "lodash";
import "../../../assets/scss/pages/_settings.scss";
import { CardHeader, Label, Table, Input } from "reactstrap";
import Select from "react-select";
// Formik
import * as Yup from "yup";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GoogleAutocomplete from "react-google-autocomplete";
import { add_cookie, get_cookie } from "../../../helpers/get_cookie";
import ReactCrop, {
  centerCrop,
  convertToPixelCrop,
  makeAspectCrop,
} from "react-image-crop";
import setCanvasPreview from "../../../setCanvasPreview";

// image crop
const ASPECT_RATIO = 1;
const MIN_DIMENSION = 40;

export const Profile = () => {
  document.title = "Profile | Get Rostered";
const business_id = JSON.parse(get_cookie("authUser")).business_id;
// image cropper
const imgRef = useRef(null);
const previewCanvasRef = useRef(null);
const [imgSrc, setImgSrc] = useState("");
const [crop, setCrop] = useState();
const [croppedImgSrc, setCroppedImgSrc] = useState(null);
const [error, setError] = useState("");
const [uploadFileName, setUploadFileName] = useState("");
  // const onSelectFile = (e) => {
  //   const file = e.target.files?.[0];

  //   if (!file) return;

  //   if (file) {
  //     setUploadFileName(file.name);
  //   }

  //   // Validate file type
  //   const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
  //   if (!validFileTypes.includes(file.type)) {
  //     if (error) setError("");
  //     setError("Invalid file type. Please select a JPEG or PNG image.");
  //     return setImgSrc(null);
  //   }

  //   const reader = new FileReader();
  //   reader.addEventListener("load", () => {
  //     const imageElement = new Image();
  //     const imageUrl = reader.result?.toString() || "";
  //     imageElement.src = imageUrl;

  //     imageElement.addEventListener("load", (e) => {
  //       if (error) setError("");

  //       const { naturalWidth, naturalHeight } = e.currentTarget;
  //       if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
  //         setError(
  //           `Image must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels.`
  //         );
  //         return setImgSrc(null);
  //       }
  //     });
  //     // Set imgSrc state if validation passes
  //     setImgSrc(imageUrl);
  //   });
  //   reader.readAsDataURL(file);
  // };
  const onSelectFile = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      setUploadFileName(file.name);
    }
  
    if (!file) return;
  
    // Validate file type
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!validFileTypes.includes(file.type)) {
      setError("Invalid file type. Please select a JPEG or PNG image.");
      setImgSrc(null);
      setCroppedImgSrc(null);
      return;
    }
  
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;
  
      imageElement.addEventListener("load", (e) => {
        const { naturalWidth, naturalHeight } = e.currentTarget;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError(
            `Image must be at least ${MIN_DIMENSION} x ${MIN_DIMENSION} pixels.`
          );
          setImgSrc(null);
          setCroppedImgSrc(null);
          return;
        } else {
          setError("");
        }
        // Set imgSrc state if validation passes
        setImgSrc(imageUrl);
      });
    });
    reader.readAsDataURL(file);
  };
  // Image load handler
  const onImageLoad = (e) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const initialCrop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      ASPECT_RATIO,
      width,
      height
    );
    const centeredCrop = centerCrop(initialCrop, width, height);
    console.log(centeredCrop, "centeredCrop");
    setCrop(centeredCrop);
  };

  // Update canvas preview
  const updateCanvasPreview = (crop) => {
    if (
      imgRef.current &&
      previewCanvasRef.current &&
      crop &&
      crop.width &&
      crop.height
    ) {
      setCanvasPreview(
        imgRef.current, // HTMLImageElement
        previewCanvasRef.current, // HTMLCanvasElement
        convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
      );

      const canvas = previewCanvasRef.current;
      const dataUrl = canvas.toDataURL("image/jpeg");
      setCroppedImgSrc(dataUrl);
    }
  };

  // Effect to update canvas preview
  useEffect(() => {
    if (imgSrc && crop) {
      updateCanvasPreview(crop);
    }
  }, [imgSrc, crop]);
  
  // Vertical Nav Tabs
  const [isLoading, setisLoading] = useState(false);
  const [authUserId, setAuthUserId] = useState();
  const [gld, setGld] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [profileEmailError, setProfileEmailError] = useState("");
  const [profileMobileError, setProfileMobileError] = useState("");
  const [profileAddressError, setProfileAddressError] = useState("");
  const [logoError, setLogoError] = useState("");

  const [workId, setWorkId] = useState("");
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [profileMobile, setProfileMobile] = useState();
  const [profileEmail, setProfileEmail] = useState();
  const [profileAddress, setProfileAddress] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  //google address
  const [selectedCity, setSelectedcity] = useState();
  const [selectedSuburb, setselectedSuburb] = useState();
  const [selectedPostcode, setselectedPostcode] = useState();
  const [country, setCountry] = useState([]);

  useEffect(() => {
    axios
      .get("/get-user-details")
      .then((res) => {
        console.log(res);
        setAuthUserId(res.id);
        loadGeneral(res.id);
      })
      .then((err) => {
        console.log(err);
      });
  }, []);

  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const validatePassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value || !isValid) {
      setPasswordError(
        "Password must have 8 characters, contain at least one number, one uppercase letter, and one lowercase letter."
      );
      return false;
    }
    setPasswordError("");
    return true;
  };

  const validateConfirmPassword = (value) => {
    // Password must be at least 8 characters long and contain at least one number, one uppercase letter, and one lowercase letter.
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    const isValid = passwordRegex.test(value);
    if (!value) {
      setConfirmPasswordError("Please Re-enter your password.");
      return false;
    } else if (value != password || !isValid) {
      setConfirmPasswordError("Passwords do not match!");
      return false;
    }
    setConfirmPasswordError("");
    return true;
  };

  const residentialplace = (place) => {
    const addressComponents = place?.address_components;
    console.log(place);
    const { lat, lng } = place.geometry.location;
    console.log("Latitude:", lat());
    console.log("Longitude:", lng());
    const streetNumberComponent = addressComponents?.find((component) =>
      component.types.includes("street_number")
    );
    const streetRouteComponent = addressComponents?.find((component) =>
      component.types.includes("route")
    );
    const unitComponent = place?.addressComponents?.find((component) =>
      component.types.includes("subpremise")
    );
    const suburbComponent = addressComponents?.find((component) =>
      component.types.includes("locality")
    );
    const stateComponent = addressComponents?.find((component) =>
      component.types.includes("administrative_area_level_1")
    );
    const postcodeComponent = addressComponents?.find((component) =>
      component.types.includes("postal_code")
    );
    const countryComponent = addressComponents?.find((component) =>
      component.types.includes("country")
    );

    let selectedStreetNumber = "";
    if (streetNumberComponent) {
      selectedStreetNumber = streetNumberComponent.long_name;
    }
    let selectedUnitNumber = "";
    if (unitComponent) {
      selectedUnitNumber = unitComponent.long_name;
    }
    let selectedStreetRoute = "";
    if (streetRouteComponent) {
      selectedStreetRoute = streetRouteComponent.long_name;
    }
    let selectedSuburb = "";
    if (suburbComponent) {
      selectedSuburb = suburbComponent.long_name;
    }
    let selectedState = "";
    if (stateComponent) {
      selectedState = stateComponent.short_name;
    }
    let selectedPostcode = "";
    if (postcodeComponent) {
      selectedPostcode = postcodeComponent.long_name;
    }
    let selectedcountry = "";
    if (countryComponent) {
      selectedcountry = countryComponent.long_name;
    }

    setSelectedcity(
      `${selectedUnitNumber} ${selectedStreetNumber} ${selectedStreetRoute} `
    );
    setselectedSuburb(selectedSuburb + ", " + selectedState);
    setselectedPostcode(selectedPostcode);
    setCountry(selectedcountry);
    setProfileAddress(place?.formatted_address);
    setLatitude(lat());
    setLongitude(lng());
  };

  const loadGeneral = (userId) => {
    setisLoading(true);
    const formData = {
      user_id: userId,
      //business_id: business_id,
    };
    axios
      .post("/get-profile-data", formData)
      .then((response) => {
        console.log(response, "response12");
        setGld(response);
        setWorkId(response.id);
        setFirstName(response.first_name); // Assuming response contains data
        setProfileMobile(response.mobile);
        setLastName(response.last_name);
        setProfileEmail(response.email);
        setProfileAddress(response.location);
        setImage(response.profile_picture_url);
        setselectedSuburb(response.suburb);
        setSelectedcity(response.address);
        setselectedPostcode(response.postcode);
        setCountry(response.country_name);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setisLoading(false);
      });
  };

  //*backend response*
  // useEffect(() => {
  //   loadGeneral(authUserId);
  // }, []);

  useEffect(() => {
    if (gld) {
      setisLoading(true);
      setWorkId(gld.id);
      setFirstName(gld.first_name); // Assuming response contains data
      setProfileMobile(gld.mobile);
      setLastName(gld.last_name);
      setProfileEmail(gld.email);
      setProfileAddress(gld.location);
      setImage(gld.profile_picture_url);
      setselectedSuburb(gld.suburb);
      setSelectedcity(gld.address);
      setselectedPostcode(gld.postcode);
      setCountry(gld.country_name);
      setisLoading(false);
    }
  }, [gld]);

  // file upload content
  const [imagesel, setImage] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const fileInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(false);
  const [imageDimensionsValid, setImageDimensionsValid] = useState(true);

  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const handleFileInputChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      // Check file type
      const acceptedFormats = ["image/jpeg", "image/jpg", "image/png"];
      if (!acceptedFormats.includes(selectedFile.type)) {
        setLogoError("Please select a valid image format (jpeg, jpg, png).");
        setUploadedImage(null);
      } else {
        setLogoError("");
      }
      // Check image dimensions
      const image = new Image();
      image.src = URL.createObjectURL(selectedFile);
      image.onload = () => {
        // if (image.width > 250 || image.height > 100) {
        //   setLogoError('Image dimensions should not exceed 250px width and 100px height.');
        //   setImageDimensionsValid(false);
        // } else {
        const objectUrl = URL.createObjectURL(selectedFile);
        setUploadedImage(selectedFile);
        setSelectedImage(objectUrl);
        setIsImageSelected(true);
        setImageDimensionsValid(true);
        // }
      };
    }
  };

  const handleRemoveClick = () => {
    setSelectedImage(null);
    setUploadedImage(null);

    setUploadFileName("");
    setImgSrc("");
    setCroppedImgSrc("");
    setError("");
    setIsImageSelected(false);
    fileInputRef.current.value = null; // Clear the input field value
  };

  const handleChangeClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordShow(true); // Set to true temporarily to show password text
    setTimeout(() => {
      setPasswordShow(false); // Set back to false after a short delay
    }, 1000); // Adjust the delay as needed
  };

  const handleConfirmPasswordChange = (e) => {
    const value = e.target.value;
    setConfirmPassword(value);
    setConfirmPasswordShow(true);// Set to true temporarily to show password text
    setTimeout(() => {
      setConfirmPasswordShow(false); // Set back to false after a short delay
    }, 1000); // Adjust the delay as needed
  };

  const togglePasswordVisibility = () => {
    setPasswordShow(!passwordShow);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    /* validation */
    if (password || confirmPassword) {
      if (
        !validatePassword(password) ||
        !validateConfirmPassword(confirmPassword)
      ) {
        // Validation failed, don't proceed with submission
        // setIsLoading(false);
        return;
      }
    }
    if (firstName === "") {
      setFirstNameError("Please enter your first name.");
    } else {
      setFirstNameError("");
    }
    if (lastName === "") {
      setLastNameError("Please enter your last name.");
    } else {
      setLastNameError("");
    }
    if (profileAddress === "") {
      setProfileAddressError("Please enter your address.");
    } else {
      setProfileAddressError("");
    }

    if (firstName === "" || lastName === "" || profileAddress === "") {
      // If any of the required fields are empty, do not proceed with form submission.
      return;
    }

    let file;

    if (croppedImgSrc) {

      // Function to convert base64 to binary file
      const base64ToFile = (base64String, filename) => {
        // Remove the data URL prefix
        const byteString = atob(base64String.split(",")[1]);
        // Get the MIME type from the base64 string
        const mimeType = base64String.match(/:(.*?);/)[1];
        // Create an ArrayBuffer to hold the binary data
        const ab = new ArrayBuffer(byteString.length);
        // Create a typed array from the ArrayBuffer
        const ia = new Uint8Array(ab);
        // Convert the binary string to binary data
        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        // Create a Blob from the binary data
        const blob = new Blob([ia], { type: mimeType });
        // Create a File from the Blob
        return new File([blob], filename, { type: mimeType });
      };

      file = base64ToFile(croppedImgSrc, uploadFileName);
    }

    var formData = new FormData();
    formData.append("first_name", firstName);
    formData.append("last_name", lastName);
    formData.append("email", profileEmail);
    formData.append("mobile", profileMobile);
    formData.append("address", selectedCity);
    formData.append("suburb", selectedSuburb);
    formData.append("post_code", selectedPostcode);
    formData.append("country_name", country);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("password", password);
    formData.append("confirm_password", confirmPassword);
    if (file) {
      formData.append("profile_picture", file);
    }
    formData.append("user_id", authUserId);
    formData.append("business_id", business_id);

    if (workId) {
      formData.append("work_id", workId);
    }
    axios
      .post("/update-profile-data", formData)
      .then((response) => {
        console.log(response.password_status);
        // var newPassword = response.password_status[0].password; // Adjust this according to your actual response structure
        // var encryptedPassword = btoa(newPassword);
        // // After successfully updating the password
        // // sessionStorage.setItem('updatedPassword', newPassword);
        // var user = JSON.parse(get_cookie("authUser"));
        // user.profile_password = encryptedPassword;
        // add_cookie(user);
        window.location.reload();
        setImgSrc("");
        setError("");
        setPassword("");
        setConfirmPassword("");
      })
      .catch((error) => {
        console.error(error);
        toast.error("Something went wrong...");
      });
  };

  // useEffect(() => {
  //   const api_token = JSON.parse(get_cookie("authUser"));

  //   if (api_token?.profile_password) {
  //     var decryptedPassword = atob(api_token?.profile_password);
  //     setPassword(decryptedPassword);
  //     setConfirmPassword(decryptedPassword);
  //   }
  // }, []);

  return (
    <div>
      <Card>
        <CardHeader>
          <h5 className="mb-0 text-primary">Profile</h5>
        </CardHeader>
        {isLoading ? (
          <>
            <CardBody>
              <div className="mt-4">
                <div className="d-flex align-items-center mb-2"></div>
                <div>
                  <Row className="gy-3">
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-7 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-9 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-4 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-sm  col-2 bg-dark"></span>
                    </h3>
                    <h3 className="card-title placeholder-glow">
                      <span className="placeholder placeholder-xs  col-6 bg-dark"></span>
                    </h3>
                  </Row>
                </div>
              </div>
            </CardBody>
          </>
        ) : (
          <>
            <CardBody>
              <Col xxl={12}>
                <div className="live-preview">
                  <Row className="gy-4">
                    <Col xxl={12} md={12}>
                      <div>
                        <Label htmlFor="basiInput" className="form-label">
                          First Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          value={firstName}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                            setFirstNameError("");
                          }}
                          className={`${firstNameError ? "is-invalid" : ""}`}
                          id="readonlyInput"
                        />
                        {firstNameError && (
                          <div className="invalid-feedback">
                            {firstNameError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div>
                        <Label htmlFor="basiInput" className="form-label">
                          Last Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          value={lastName}
                          onChange={(e) => {
                            setLastName(e.target.value);
                            setLastNameError("");
                          }}
                          className={`${lastNameError ? "is-invalid" : ""}`}
                          id="readonlyInput"
                        />
                        {lastNameError && (
                          <div className="invalid-feedback">
                            {lastNameError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div>
                        <Label htmlFor="readonlyInput" className="form-label">
                          Business Email
                        </Label>
                        <Input
                          type="text"
                          value={profileEmail}
                          onChange={(e) => {
                            setProfileEmail(e.target.value);
                            setProfileEmailError("");
                          }}
                          readOnly
                          style={{ backgroundColor: "#f3f3f3", color: "#666" }} // Adding style attribute for light color
                          className={`${profileEmailError ? "is-invalid" : ""}`}
                          id="readonlyInput"
                        />
                        {profileEmailError && (
                          <div className="invalid-feedback">
                            {profileEmailError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <div>
                        <Label
                          htmlFor="choices-single-default"
                          className="form-label"
                        >
                          Location <span className="text-danger">*</span>
                        </Label>
                        <div>
                          <GoogleAutocomplete
                            className={`${
                              profileAddressError ? "is-invalid" : ""
                            }`}
                            apiKey="AIzaSyB2SMtaVBlqC5v72gqS716BX8R5oXklaFc"
                            value={profileAddress}
                            onChange={(e) => setProfileAddress(e.target.value)}
                            onPlaceSelected={(place) => residentialplace(place)}
                            options={{
                              types: ["geocode"],
                              componentRestrictions: {
                                country: "au",
                              },
                            }}
                            style={{
                              width: "100%",
                              border: "1px solid #ccc",
                              padding: "10px",
                              borderRadius: "5px",
                              position: "relative",
                            }}
                          />
                          {profileAddressError && (
                            <div className="invalid-feedback">
                              {profileAddressError}
                            </div>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      <label className="form-label" htmlFor="password-input">
                        Password
                      </label>
                      <div className="position-relative auth-pass-inputgroup">
                        <Input
                          type={passwordShow ? "text" : "password"}
                          className={`form-control pe-5 password-input ${
                            passwordError ? "is-invalid" : ""
                          }`}
                          placeholder="Enter password"
                          id="password-input"
                          name="password"
                          value={password}
                          onChange={handlePasswordChange}
                          onPaste={(e) => e.preventDefault()}
                        />
                        <Button
                          color="link"
                          onClick={togglePasswordVisibility}
                          className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          id="password-addon"
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </Button>
                        {passwordError && (
                          <div className="invalid-feedback">
                            {passwordError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col xxl={12} md={12}>
                      {/* <div className="mb-3"> */}
                      <label className="form-label" htmlFor="password-input">
                        Confirm Password
                      </label>
                      <div className="position-relative auth-pass-inputgroup">
                        <Input
                          type={confirmPasswordShow ? "text" : "password"}
                          className={`form-control pe-5 password-input ${
                            confirmPasswordError ? "is-invalid" : ""
                          }`}
                          placeholder="Re-enter password"
                          id="confirm-password-input"
                          name="confirmPassword"
                          value={confirmPassword}
                          onChange={handleConfirmPasswordChange}
                          onPaste={(e) => e.preventDefault()}
                        />
                        <Button
                          color="link"
                          className="position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          onClick={toggleConfirmPasswordVisibility}
                          id="password-addon"
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </Button>
                        {confirmPasswordError && (
                          <div className="invalid-feedback">
                            {confirmPasswordError}
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col lg={8} md={8}>
                      <div className="mb-3">
                        <Label
                          htmlFor="choices-single-default"
                          className="form-label"
                        >
                          Profile Picture
                        </Label>
                        <Row className="mb-2">
                          <Col md={6}>
                            <div className="img-container">
                            <>
                                {console.log(imagesel, "imagesel123")}
                                {console.log(croppedImgSrc, "imagesel123")}

                                {error && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "90%",
                                      textAlign: "center",
                                    }}
                                  >
                                    {error}
                                  </p>
                                )}
                                {imgSrc && (
                                  <div
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                    <ReactCrop
                                      crop={crop}
                                      onChange={(newCrop) => setCrop(newCrop)}
                                      onComplete={(newCrop) =>
                                        updateCanvasPreview(newCrop)
                                      }
                                      keepSelection
                                      aspect={ASPECT_RATIO}
                                      minWidth={MIN_DIMENSION}
                                    >
                                      <img
                                        ref={imgRef}
                                        src={imgSrc}
                                        alt="Upload"
                                        style={{
                                          maxHeight: "60%",
                                        }}
                                        onLoad={onImageLoad}
                                      />
                                    </ReactCrop>
                                  </div>
                                )}
                                {crop && (
                                  <canvas
                                    ref={previewCanvasRef}
                                    className="mt-4"
                                    style={{
                                      display: "none",
                                      border:
                                        "1px solid black",
                                      objectFit: "contain",
                                      width: 150,
                                      height: 150,
                                    }}
                                    // style={{ display: "none" }}
                                  />
                                )}
                               
                                {/* showing stored image */}
                                {imagesel && imgSrc == "" && (
                                  <img
                                    className="img-thumbnail avatar-lg"
                                    alt=""
                                    src={`${imagesel}?${new Date().getTime()}`}
                                  />
                                )}
                              </>
                            </div>
                          </Col>
                        </Row>
                        {imgSrc ? (
                          <div>
                            <button
                              className="btn btn-primary change-button"
                              onClick={handleChangeClick}
                            >
                              Change
                            </button>
                            <button
                              className="btn btn-danger remove-button"
                              onClick={handleRemoveClick}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <button
                            className="btn btn-primary"
                            onClick={handleButtonClick}
                          >
                            Select image
                          </button>
                        )}
                        {/* <input
                          className={`${logoError ? "is-invalid" : ""}`}
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={handleFileInputChange}
                          accept=".jpeg, .jpg, .png"
                        /> */}
                         <input
                          className={`${logoError ? "is-invalid" : ""}`}
                          type="file"
                          style={{ display: "none" }}
                          ref={fileInputRef}
                          onChange={onSelectFile}
                          accept=".jpeg, .jpg, .png"
                        />
                        {/* {!imageDimensionsValid && (
                          <div className="invalid-feedback">{logoError}</div>
                        )} */}
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
              <div className="d-flex justify-content-end">
                <Button className="btn btn-success" onClick={handleSubmit}>
                  Update
                </Button>{" "}
                &nbsp;
                {/* <Button className="btn btn-dark" onClick={handleReset}>Cancel</Button> */}
              </div>
              <ToastContainer closeButton={false} limit={1} theme="light" />
            </CardBody>
          </>
        )}
      </Card>
    </div>
  );
};
